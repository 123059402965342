<template>
    <div>
        <CustomTitle class='mt-12 mb-6'>
            {{ $t('ttl-rights-and-accesses') }}
        </CustomTitle>
        <ContentBox>
            <CustomTitle class='mt-0' level='2'>
                {{ $t('ttl-workspace-rights-accesses-admins') }}
            </CustomTitle>
            <List :loading='loading'>
                <template #columns>
                    <ListColumn>{{ $t('col-workspace-rights-accesses-email') }}</ListColumn>
                    <ListColumn>{{ $t('col-workspace-rights-accesses-name') }}</ListColumn>
                    <ListColumn>{{ $t('col-workspace-rights-accesses-role') }}</ListColumn>
                </template>
                <template #rows>
                    <ListRow v-for='(member, i) in adminMembers' :key='i'>
                        <ListItem>
                            <strong>{{ member.email }}</strong>
                        </ListItem>
                        <ListItem v-if='member.name'>
                            {{ member.name }}
                        </ListItem>
                        <ListItem v-else>
                            -
                        </ListItem>
                        <ListItem>
                            <span class='inline-flex justify-center items-center h-12 px-8 bg-grey-50'>
                                Organization {{ $t('lbl-team-members-role-' + member.organizationRole) }}
                            </span>
                        </ListItem>
                    </ListRow>
                </template>
            </List>
        </ContentBox>
        <ContentBox class='mt-6'>
            <CustomTitle class='mt-0' level='2'>
                {{ $t('ttl-workspace-rights-accesses-members') }}
            </CustomTitle>
            <List :loading='loading'>
                <template #columns>
                    <ListColumn>{{ $t('col-workspace-rights-accesses-email') }}</ListColumn>
                    <ListColumn>{{ $t('col-workspace-rights-accesses-name') }}</ListColumn>
                    <ListColumn>{{ $t('col-workspace-rights-accesses-role') }}</ListColumn>
                </template>
                <template #rows>
                    <ListRow v-for='(member, i) in nonAdminMembers' :key='i'>
                        <ListItem>
                            <strong>{{ member.email }}</strong>
                        </ListItem>
                        <ListItem v-if='member.name'>
                            {{ member.name }}
                        </ListItem>
                        <ListItem v-else>
                            -
                        </ListItem>
                        <ListItem>
                            <DropdownV2
                                v-model='member.workspaceRole'
                                icon='sort'
                                class='flex'
                                @changed='updateRole'
                            >
                                <DropdownItem id='no-access'>
                                    {{ $t('lbl-workspace-rights-accesses-role-no-access') }}
                                </DropdownItem>
                                <DropdownItem id='manager'>
                                    {{ $t('lbl-workspace-rights-accesses-role-manager') }}
                                </DropdownItem>
                            </DropdownV2>
                        </ListItem>
                    </ListRow>
                </template>
            </List>
        </ContentBox>
    </div>
</template>

<script>
import List from '@/components/List';
import ListColumn from '@/components/ListColumn';
import ListRow from '@/components/ListRow';
import ListItem from '@/components/ListItem';
import DropdownV2 from '@/components/DropdownV2.vue';
import DropdownItem from '@/components/DropdownItem.vue';
import ContentBox from '@/components/ContentBox';
import CustomTitle from '@/components/Title';

export default {
    name: 'workspace-rights-and-accesses-view',
    components: {
        List,
        ListColumn,
        ListRow,
        ListItem,
        DropdownV2,
        DropdownItem,
        ContentBox,
        CustomTitle,
    },
    data () {
        return {
            loading: false,
            teamMembers: [],
        };
    },
    computed: {
        adminMembers () {
            return this.teamMembers.filter((member) => ['admin', 'owner'].includes(member.organizationRole));
        },
        nonAdminMembers () {
            return this.teamMembers.filter((member) => !['admin', 'owner'].includes(member.organizationRole));
        },
    },
    mounted () {
        this.fetchList();
    },
    methods: {
        async fetchList () {
            this.loading = true;
            this.teamMembers = await this.getTeamMembers();
            this.loading = false;
        },
        async getTeamMembers () {
            this.loading = true;
            // Replace this with an actual API call to fetch team members.
            this.loading = false;
            return [
                {
                    id: '802eaad4-e381-11ed-b5ea-0242ac120002',
                    name: 'John Doe',
                    email: 'john.doe@example.com',
                    organizationRole: 'owner',
                    workspaceRole: 'admin',
                },
                {
                    id: '802eaad4-e381-11ed-b5ea-0242ac120002',
                    name: 'Steve Brown',
                    email: 'steve.brown@example.com',
                    organizationRole: 'admin',
                    workspaceRole: 'admin',
                },
                {
                    id: 'ae13ef58-d029-4751-b43d-6a71615dbb69',
                    email: 'jane.smith@example.com',
                    organizationRole: 'member',
                    workspaceRole: 'no-access',
                },
                {
                    id: 'd7ba5f73-80be-4dd2-a69c-0f05b81d8c36',
                    name: 'Steve Brown',
                    email: 'steve.brown@example.com',
                    organizationRole: 'member',
                    workspaceRole: 'manager',
                },
            ];
        },
        updateRole () {
            // Implement role update functionality here.
            console.log('Role has changed');
        },
    },
};
</script>
