<script>
    import notify from '@/notify';

    export default {
        name: 'digital-signature-button',
        props: {
            url: String,
        },
        computed: {
            unsupported () {
                return !this.url || this.url.indexOf('supported') >= 0;
            },
        },
        methods: {
            async copyToClipboard () {
                try {
                    await navigator.clipboard.writeText(this.url);
                    notify.success(this.$t('p-link-copied-clipboard-success'));
                } catch (err) {
                    notify.error(this.$t('p-link-copied-clipboard-error'));
                }
            },
        },
    };
</script>

<template>
    <div class='sign-button'>
        <a :href='url' class='underline decoration-solid' target='_blank'>
            {{ $t('lbl-start-digital-signing') }}
            <i class='fa fa-external-link ml-1'></i>
        </a>
        <button
            class='btn btn-sm btn-default px-2 ml-3'
            @click.prevent='copyToClipboard'
        >
            {{ $t('lbl-copy-digital-signing-link') }}
            <i class='fa fa-clipboard ml-1'></i>
        </button>
    </div>
</template>

<style lang='postcss' scoped>
.sign-button {
    @apply rounded-md text-blue-500 text-sm;
    display: flex;
    height: 30px;
    align-items: center;
    text-decoration: none;
}

.sign-button img {
    display: inline-block;
    max-height: 1.2em;
    margin-left: 6px;
    position: relative;
    top: -1px;
}

.sign-button-url {
    @apply rounded-md text-blue-500 text-sm border border-solid border-blue-200;
    display: flex;
    height: 30px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 0 8px;
    margin: 0 8px;
    text-overflow: ellipsis;
}
</style>
