import { render, staticRenderFns } from "./OrderVoila.vue?vue&type=template&id=29a768cf&scoped=true&"
import script from "./OrderVoila.vue?vue&type=script&lang=js&"
export * from "./OrderVoila.vue?vue&type=script&lang=js&"
import style0 from "./OrderVoila.vue?vue&type=style&index=0&id=29a768cf&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29a768cf",
  null
  
)

export default component.exports