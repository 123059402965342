<script>
import Vue from 'vue';
import {gql} from '@apollo/client/core';
import _ from 'lodash';
import DeactivateVoila from './components/DeactivateVoila';
import ResendVoilaInviteButton from './components/ResendVoilaInviteButton';
import OrderVoila from './components/OrderVoila';
import SupplierList from './components/SupplierList';
import Loader from '@/loader';
import {VoilaMandateStates, VoilaMandateReasonCodes} from '@/constants/voila2';
import notify from '@/notify.js';
import FormField from '@/components/FormField';
import VoilaStatePill from '@/fiduciary/VoilaStatePill';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import ContentBox from '@/components/ContentBox.vue';
import EmailPill from '@/components/EmailPill.vue';
import SettingsToggle from '@/components/SettingsToggle.vue';
import Translated from '@/components/Translated';
import VoilaPeppolPill from '@/fiduciary/VoilaPeppolPill';

export default {
    props: {
        clientV2: { type: Object, required: true },
        accountant: { type: Object, required: true },
    },
    components: {
        DeactivateVoila,
        ResendVoilaInviteButton,
        OrderVoila,
        SupplierList,
        FormField,
        VoilaStatePill,
        FriendlyButton,
        ContentBox,
        EmailPill,
        SettingsToggle,
        Translated,
        VoilaPeppolPill,
    },
    data () {
        return {
            voila: {
                mandate: null,
                contactEmail: null,
                forbidden: false,
            },
            edit: false,
            useContactEmailForDelivery: false,
            onlyDeliverViaAPI: false,
            configCopy: {},
            localBus: new Vue(),
            availableBankAccounts: [],
        };
    },
    apollo: {
        voila: {
            query: gql`query VoilaMandate($clientId: String, $accountantId: String!) {
                    currentVoilaMandate(clientId:$clientId) {
                         id,
                         clientId,
                         deliveryEmail,
                         status,
                         reasonCode,
                         accessPointNames,
                         statusHistory {
                            code,
                            createdAt
                         },
                         ibans {
                            id,
                            value
                         }
                         pushDelivery,
                         signedVia
                    }
                    fiduciaryClient(clientId:$clientId) {
                        contactEmail,
                    }
                    invoiceSuppliers(clientId:$clientId) {
                        name
                        latestDocumentReceivedAt
                    }
                    voilaBoostActivationByAccountant(accountantId:$accountantId) {
                        id
                    }
                }`,
            variables () {
                return {
                    clientId: this.clientV2.id,
                    accountantId: this.accountant.id,
                };
            },
            update: data => {
                return {
                    contactEmail: data.fiduciaryClient.contactEmail,
                    mandate: data.currentVoilaMandate,
                    suppliers: data.invoiceSuppliers,
                    voilaBoostActivationByAccountant: data.voilaBoostActivationByAccountant,
                };
            },
            error (error, vm) {
                if (error.gqlError.extensions.code === 'FORBIDDEN') {
                    vm.voila.forbidden = true;
                } else {
                    throw error;
                }
            },
        },
    },
    computed: {
        canResendInvitation () {
            return this.voila.mandate && (this.voila.mandate.status === VoilaMandateStates.InvitationExpired || this.voila.mandate.status === VoilaMandateStates.Invited);
        },
        voilaMandateCancellable () {
            return this.voila.mandate && [
                VoilaMandateStates.Invited,
                VoilaMandateStates.InvitationExpired,
                VoilaMandateStates.InValidation,
                VoilaMandateStates.InRegistration,
                VoilaMandateStates.Problem,
                VoilaMandateStates.Active,
            ].includes(this.voila.mandate.status);
        },
        canEdit () {
            return this.voila.mandate.status !== VoilaMandateStates.Cancelled;
        },
        voilaMandateOrderable () {
            return !this.voila.forbidden &&
                this.voila.contactEmail &&
                (!this.voila.mandate || [
                    VoilaMandateStates.Cancelled,
                ].includes(this.voila.mandate.status));
        },
        missingContactEmail () {
            return !this.voila.forbidden &&
                (!this.voila.mandate || this.voila.mandate.status === VoilaMandateStates.Cancelled) &&
                !this.voila.contactEmail;
        },
        hasReceivedInvoices () {
            return this.voila.suppliers ? this.voila.suppliers.length > 0 : false;
        },
        voilaMandateStatusColor () {
            if (this.voila.mandate) {
                if (!this.voila.mandate.status || this.voila.mandate.status === VoilaMandateStates.Cancelled) {
                    return '';
                } else if ([
                    VoilaMandateStates.InvitationExpired,
                    VoilaMandateStates.Problem,
                ].includes(this.voila.mandate.status)) {
                    return 'bg-red-300 bg-opacity-20';
                } else {
                    return '';
                }
            } else {
                return '';
            }
        },
        voilaMandateInitialAlreadyRegisteredOnPeppolErrorDate () {
            if (this.voila.mandate &&
                this.voila.mandate.status === VoilaMandateStates.Problem &&
                this.voila.mandate.reasonCode === VoilaMandateReasonCodes.AlreadyRegisteredOnPeppol) {
                let mandateStatusesNewestFirst = this.voila.mandate.statusHistory
                    .slice(0) // avoid side effect
                    .sort((status1, status2) => -status1.createdAt.localeCompare(status2.createdAt));
                let initialAlreadyRegisteredOnPeppolErrorDate;
                for (let i = 0; i < mandateStatusesNewestFirst.length; i++) {
                    if (mandateStatusesNewestFirst[i].code === VoilaMandateStates.Problem) {
                        initialAlreadyRegisteredOnPeppolErrorDate = mandateStatusesNewestFirst[i].createdAt;
                    } else if (mandateStatusesNewestFirst[i].code !== VoilaMandateStates.InRegistration) {
                        break;
                    }
                }
                const options = {year: 'numeric', month: 'long', day: 'numeric'};
                return new Date(initialAlreadyRegisteredOnPeppolErrorDate).toLocaleDateString(this.$i18n.locale().split('_')[0], options);
            } else return '';
        },
    },
    watch: {
        'voila.mandate.deliveryEmail': function (email) {
            this.useContactEmailForDelivery = !email;
        },
        'voila.mandate.pushDelivery': function (pushDelivery) {
            this.onlyDeliverViaAPI = !pushDelivery;
        },
        '$apolloData.queries.voila.loading': function (loading) {
            if (loading) {
                Loader.start();
            } else {
                Loader.stop();
            }
        },
        clientV2: function () {
            this.localBus.$emit('updated');
        },
        'voila.mandate.ibans': function () {
            this.fetchCodaIbans();
        },
    },
    methods: {
        async fetchIbans () {
            const { data } = await this.$apollo.query({
                query: gql`query VoilaMandate($clientId: String) {
                    currentVoilaMandate(clientId:$clientId) {
                         ibans {
                            id,
                            value
                         }
                    }
                }`,
                variables: {
                    clientId: this.voila.mandate.clientId,
                },
            });

            this.voila.mandate.ibans = data.currentVoilaMandate.ibans;
        },
        editDeliveries () {
            this.edit = true;
            this.configCopy = {
                voilaMandate: _.clone(this.voila.mandate),
                useContactEmailForDelivery: this.useContactEmailForDelivery,
                onlyDeliverViaAPI: this.onlyDeliverViaAPI,
            };
        },
        cancelDeliveriesEdit () {
            this.voila.mandate = _.clone(this.configCopy.voilaMandate);
            this.useContactEmailForDelivery = this.configCopy.useContactEmailForDelivery;
            this.onlyDeliverViaAPI = this.configCopy.onlyDeliverViaAPI;
            this.edit = false;
        },
        async saveDeliveries () {
            if (this.saving) { return; }

            const valid = await this.$refs.form.validate();
            if (!valid) { return; }

            const { data } = await this.$apollo.mutate({
                mutation: gql`mutation SaveVoilaDeliveries($input: VoilaDeliveriesEditionInput!) {
                    saveVoilaDeliveries(input: $input) {
                        errors {
                            code,
                            detail,
                            source {
                                pointer
                            }
                        }
                    }
                }`,
                variables: {
                    input: {
                        clientId: this.voila.mandate.clientId,
                        id: this.voila.mandate.id,
                        deliveryEmail: this.useContactEmailForDelivery ? null : this.voila.mandate.deliveryEmail,
                        pushDelivery: !this.onlyDeliverViaAPI,
                    },
                },
            });
            const response = data.saveVoilaDeliveries;

            if (response.errors) {
                let observerErrors = {};
                response.errors.forEach(error => {
                    const field = error.source.pointer.replace('/data/', '');
                    if (this.$refs.form.fields[field]) {
                        observerErrors[field] = this.$t(error.detail);
                    } else {
                        notify.error(this.$t(error.detail));
                    }
                });
                this.$refs.form.setErrors(observerErrors);
                return;
            }

            this.refreshVoilaMandates();
            notify.success(this.$t('suc-voila-deliveries-modified'));
            this.cancelDeliveriesEdit();
        },
        refreshVoilaMandates () {
            this.$apollo.queries.voila.refresh();
        },
        async saveIban (iban) {
            try {
                await this.$apollo.mutate({
                    mutation: gql`mutation AddVoilaIban($input: VoilaIbanAdditionInput!) {
                        addVoilaIban(input: $input) {
                            errors {
                                code,
                                detail,
                                source {
                                    pointer
                                }
                            }
                        }
                    }`,
                    variables: {
                        input: {
                            clientId: this.voila.mandate.clientId,
                            mandateId: this.voila.mandate.id,
                            ibanValue: iban,
                        },
                    },
                });
                this.fetchIbans();
                notify.success(this.$t('p-voila-ibans-order-success'));
            } catch {
                notify.error(this.$t('p-voila-ibans-order-error'));
            }
        },
        async deleteIban (ibanId) {
            try {
                await this.$apollo.mutate({
                    mutation: gql`mutation DeleteVoilaIban($input: VoilaIbanDeletionInput!) {
                        deleteVoilaIban(input: $input) {
                            errors {
                                code,
                                detail,
                                source {
                                    pointer
                                }
                            }
                        }
                    }`,
                    variables: {
                        input: {
                            clientId: this.voila.mandate.clientId,
                            mandateId: this.voila.mandate.id,
                            ibanId: ibanId,
                        },
                    },
                });
                notify.success(this.$t('p-voila-ibans-delete-success'));
                this.fetchIbans();
            } catch {
                notify.error(this.$t('p-voila-ibans-delete-error'));
            }
        },
        async fetchCodaIbans () {
            Loader.start();
            const { data } = await this.$apollo.query({
                query: gql`query BankAccounts($clientId: String, $states: [String]) {
                    bankAccounts(clientId:$clientId, states:$states) {
                         iban
                    }
                }`,
                variables: {
                    clientId: this.voila.mandate.clientId,
                    states: ['active'],
                },
            });
            // take coda bankaccount that aren't in voila (client can possibly activate it)
            this.availableBankAccounts = data.bankAccounts.filter(ba => {
                const isOnVoila = this.voila.mandate.ibans.find(voilaIban => {
                    return voilaIban.value === ba.iban;
                });
                return !isOnVoila;
            });
            // take bank accounts that are already activated on voila, and align the format
            this.voila.mandate.ibans.forEach(ba => {
                this.availableBankAccounts.push({
                    iban: ba.value,
                    id: ba.id,
                    isVoila: true,
                });
            });
            this.availableBankAccounts = _.orderBy(this.availableBankAccounts, ['iban'], ['asc']);
            Loader.stop();
        },
        availableBankAccountsChange (bankaccount, shouldBeVoila) {
            Loader.start();
            if (shouldBeVoila) {
                this.saveIban(bankaccount.iban);
            } else {
                this.deleteIban(bankaccount.id);
            }
        },
    },
};
</script>

<template>
    <main v-if='!$apollo.loading'>
        <content-box
            class='mt-0 mb-6 relative overflow-hidden z-30'
        >
            <span class='content-[" "] absolute inset-0 z-0' :class='voilaMandateStatusColor'></span>
            <div class='z-10 flex items-center relative'>
                <div class='mr-6'>
                    <VoilaStatePill
                        :status='voila.mandate ? voila.mandate.status : null'
                        :reason-code='voila.mandate ? voila.mandate.reasonCode : null'
                        :initial-already-registered-on-peppol-error-date='voilaMandateInitialAlreadyRegisteredOnPeppolErrorDate'
                        :access-point-names='voila.mandate && voila.mandate.accessPointNames ? voila.mandate.accessPointNames.join(", ") : "unknown"'
                        inline
                    />
                </div>
                <div class='client-subppage-header__actions'>
                    <div v-if='voila.mandate && canEdit' class='flex'>
                        <ResendVoilaInviteButton
                            :mandate-id='voila.mandate.id'
                            :contact-email='voila.contactEmail'
                            v-if='canResendInvitation'
                            class='mr-3'
                            @done='refreshVoilaMandates'
                            extra-small
                            id='voila-reinvite-button'
                        />
                        <DeactivateVoila :id='voila.mandate.id'
                                         v-if='voilaMandateCancellable'
                                         @done='refreshVoilaMandates'
                                         extra-small
                        />
                    </div>
                    <div v-if='voilaMandateOrderable' class='flex'>
                        <OrderVoila
                            :id='clientV2.id'
                            @done='refreshVoilaMandates'
                            extra-small
                            :has-boost='!!voila.voilaBoostActivationByAccountant'
                            :accountant='accountant'
                        />
                    </div>
                </div>
            </div>
            <div
                class='inline-block border-0 border-t border-solid border-grey-100 w-full pt-6 mt-6'
                v-if='voila.mandate && voila.mandate.signedVia'
            >
                <span>
                    <i class='fa fa-info-circle mr-2'></i>
                    <span v-if='voila.mandate.signedVia === "boost"' id='boostPill'>{{ $t('lbl-voila-ordering-method-boost') }}</span>
                    <span v-else-if='voila.mandate.signedVia === "invitationEmail"' id='emailInvitationPill'>{{ $t('lbl-voila-ordering-method-email') }}</span>
                </span>
            </div>
        </content-box>
        <div class='panel panel-superwarning' v-if='missingContactEmail'>
            <div class='panel-heading'>
                <h3 class='panel-title'>
                    <i class='fa fa-info-circle'></i>
                </h3>
            </div>
            <div class='panel-body'>
                <p>
                    {{ $t('info-voila-missing-contact-email') }}
                </p>
            </div>
        </div>
        <div class='panel panel-superwarning' v-if='voila.forbidden'>
            <div class='panel-heading'>
                <h3 class='panel-title'>
                    <i class='fa fa-info-circle'></i>
                </h3>
            </div>
            <div class='panel-body'>
                <p>
                    Access Forbidden
                </p>
            </div>
        </div>
        <content-box :title='$t("ttl-settings")' v-if='voila.mandate'>
            <template #actions>
                <div v-if='canEdit' class='flex'>
                    <template v-if='!edit'>
                        <FriendlyButton
                            id='voila_delivery_edit'
                            label='btn-edit'
                            :action='editDeliveries'
                            extra-small
                            no-margin
                            square
                        />
                    </template>
                    <template v-else>
                        <FriendlyButton
                            id='voila_delivery_cancel_edit'
                            label='btn-cancel'
                            :action='cancelDeliveriesEdit'
                            extra-small
                            no-margin
                            square
                            class='mr-3'
                        />
                        <FriendlyButton
                            id='voila_delivery_save'
                            label='btn-save'
                            :action='saveDeliveries'
                            extra-small
                            square
                            no-margin
                        />
                    </template>
                </div>
            </template>
            <ValidationObserver ref='form' tag='div'>
                <h4 class='mb-6 mt-0'>
                    {{ $t('ttl-voila-email-settings') }}
                </h4>
                <div class='flex items-center mb-3'>
                    <p-radio name='delivery'
                             v-model='useContactEmailForDelivery'
                             :value='true'
                             :disabled='!edit'
                             id='voila_delivery_usecontactemail'
                    >
                        <span class='ml-2'>
                            {{ $t('ttl-voila-email-settings-toggle-contact') }}
                        </span>
                    </p-radio>
                    <span class='flex items-center'>
                        <span class='mr-3' v-if='voila.contactEmail'>
                            <i class='fa fa-arrow-right text-sm'></i>
                        </span>
                        <span class='font-bold truncate max-w-xs inline-block' id='voila_delivery_usecontactemail_input' v-if='edit'>{{ voila.contactEmail }}</span>
                        <EmailPill
                            :email='voila.contactEmail'
                            :client-id='clientV2.id'
                            inline
                            id='voila_delivery_usecontactemail_input'
                            v-if='!edit'
                            ref='contactEmailPill'
                            :bus='localBus'
                            :disable-actions='voila.mandate && voila.mandate.status === "cancelled"'
                        />
                    </span>
                </div>
                <div class='flex items-center'>
                    <p-radio name='delivery'
                             v-model='useContactEmailForDelivery'
                             :value='false'
                             :disabled='!edit'
                             id='voila_delivery_usemandateemail'
                    >
                        <span class='ml-2'>
                            {{ $t('ttl-voila-email-settings-toggle-dedicated') }}
                        </span>
                    </p-radio>
                    <span v-if='(voila.mandate && voila.mandate.deliveryEmail) || !useContactEmailForDelivery' class='flex items-center'>
                        <span class='mr-3'>
                            <i class='fa fa-arrow-right text-sm'></i>
                        </span>
                        <EmailPill
                            :email='voila.mandate.deliveryEmail'
                            :client-id='clientV2.id'
                            v-if='!edit'
                            inline
                            used-copys='voila'
                            ref='voilaDeliveryEmailPill'
                            :bus='localBus'
                            :disable-actions='voila.mandate && voila.mandate.status === "cancelled"'
                        />
                        <FormField
                            v-model='voila.mandate.deliveryEmail'
                            type='email'
                            :max='254'
                            id='voila_delivery_usemandateemail_input'
                            vid='deliveryEmaild'
                            :placeholder='$t("lbl-pi-email")'
                            :edit='edit && !useContactEmailForDelivery'
                            :required='!useContactEmailForDelivery'
                            class='inline-block font-bold'
                            no-margin
                            v-if='edit'
                        />
                    </span>
                </div>

                <h4 class='mb-6 mt-8'>
                    {{ $t('ttl-voila-2-delivery-settings') }}
                </h4>
                <div>
                    <SettingsToggle
                        id='voila_delivery_onlyapi'
                        tag='div'
                        v-model='onlyDeliverViaAPI'
                        :disabled='!edit'
                        class='mb-3'
                        :info='$t("stngs-voila-2-client-block-push-delivery-info")'
                    >
                        {{ $t("stngs-voila-2-client-block-push-delivery") }}
                    </SettingsToggle>
                </div>
            </ValidationObserver>
        </content-box>
        <content-box :title='$t("h-voila-peppol")' class='mt-6' v-if='voila.mandate'>
            <template #info>
                <Translated>
                    <template #en>
                        Peppol stands for Pan European Public Procurement Online and is a network widely used for sending and receiving invoices.
                        By activating VOILA, by default the client is registered on Peppol by CodaBox to receive invoices. More info on our <a target='_blank' href='https://faq.codabox.com/en/support/solutions/articles/75000057494-the-channels-peppol-and-zoomit'>FAQ</a>.
                    </template>
                    <template #nl>
                        Peppol staat voor Pan European Public Procurement Online en is een netwerk dat veel gebruikt wordt voor het verzenden en ontvangen van facturen.
                        Door VOILA te activeren, wordt de klant standaard door CodaBox geregistreerd op Peppol om facturen te ontvangen. Meer info op onze <a target='_blank' href='https://faq.codabox.com/nl/support/solutions/articles/75000057494-de-kanalen-peppol-en-zoomit'>FAQ</a>.
                    </template>
                    <template #fr>
                        Peppol (Pan European Public Procurement Online) est un réseau largement utilisé pour l'envoi et la réception de factures.
                        En activant VOILA, le client est par défaut inscrit par CodaBox sur Peppol pour recevoir des factures. Plus d'informations sur notre <a target='_blank' href='https://faq.codabox.com/fr/support/solutions/articles/75000057494-les-canaux-peppol-et-zoomit'>FAQ</a>.
                    </template>
                </Translated>
            </template>
            <div class='mr-6'>
                <VoilaPeppolPill :status='voila.mandate.status' inline />
            </div>
        </content-box>
        <content-box :title='$t("ttl-voila-ibans")' class='mt-6' v-if='voila.mandate'>
            <template #info>
                <Translated>
                    <template #en>
                        Zoomit is a service which allows senders to deliver invoices in the banking app of your client.
                        By activating a bank account, invoices available on this bank account on Zoomit will be directly retrieved by CodaBox.
                        Please note: only bank accounts for which we have an active CODA-mandate can be activated on Zoomit through VOILA.
                        More info on our <a target='_blank' href='https://faq.codabox.com/en/support/solutions/articles/75000057494-the-channels-peppol-and-zoomit'>FAQ</a>.
                    </template>
                    <template #nl>
                        Zoomit is een dienst die verzenders toelaat facturen te leveren in de bank-app van je klant.
                        Door een bankrekening te activeren worden de facturen die beschikbaar zijn op deze bankrekening, rechtstreeks opgehaald op Zoomit door CodaBox.
                        Opgelet: enkel bankrekeningen waarvoor wij een actief CODA-mandaat hebben, kunnen geactiveerd worden op Zoomit door VOILA.
                        Meer info op onze <a target='_blank' href='https://faq.codabox.com/nl/support/solutions/articles/75000057494-de-kanalen-peppol-en-zoomit'>FAQ</a>.
                    </template>
                    <template #fr>
                        Zoomit est un service qui permet aux émetteurs de livrer des factures dans l'app bancaire de votre client.
                        En activant un compte bancaire, les factures disponibles sur ce compte via Zoomit seront directement récupérées par CodaBox.
                        Attention: seuls les comptes bancaires pour lesquels nous avons un mandat CODA actif peuvent être activés sur Zoomit via VOILA.
                        Plus d'infos dans notre <a target='_blank' href='https://faq.codabox.com/fr/support/solutions/articles/75000057494-les-canaux-peppol-et-zoomit'>FAQ</a>.
                    </template>
                </Translated>
            </template>
            <div v-if='availableBankAccounts.length'>
                <p class='mb-6'>
                    {{ $t('p-voila-ibans-instructions') }}
                </p>
                <div v-for='(bankAccount, index) in availableBankAccounts' :key='index'>
                    <label>
                        <SettingsToggle
                            :id='"available_ba_" + bankAccount.iban'
                            tag='div'
                            v-model='bankAccount.isVoila'
                            class='mb-3 bank-account'
                            :class='{"active": bankAccount.isVoila}'
                            @change='shouldBeVoila => { availableBankAccountsChange(bankAccount, shouldBeVoila) }'
                            :disabled='voila.mandate && voila.mandate.status === "cancelled"'
                        >
                            {{ bankAccount.iban }}
                        </SettingsToggle>
                    </label>
                </div>
            </div>
            <div v-else>
                <p class='mb-6'>
                    {{ $t('p-voila-ibans-no-ibans') }}
                </p>
            </div>
        </content-box>
        <content-box :title='$t("h-voila-suppliers")' class='mt-6' v-if='voila.mandate'>
            <div v-if='hasReceivedInvoices'>
                <SupplierList :suppliers='voila.suppliers' />
            </div>
            <div v-else>
                <p>{{ $t('p-no-invoice-received-yet') }}</p>
            </div>
        </content-box>
    </main>
</template>

<style scoped lang='postcss'>
    #voila_delivery_usemandateemail_input {
        min-width: 225px;
    }
</style>
