<script>
import Modal from '@/components/Modal';
import notify from '../notify';
import { gql } from '@apollo/client/core';

export default {
    name: 'send-codamandate-by-mail-button',
    props: {
        mandate: {
            type: Object,
        },
    },
    components: {
        Modal,
    },
    data: function () {
        return {
            sendByMailCurrentRecordId: '',
            showSendByMailPopup: false,
            isSendByMailConfirmed: false,
        };
    },
    computed: {
        enabled () {
            return this.mandate.state === 'sent-client';
        },
    },
    methods: {
        closeSendByMail () {
            this.showSendByMailPopup = false;
            this.isSendByMailConfirmed = false;
        },
        async confirmSendByMail () {
            if (!this.enabled) {
                return;
            }
            try {
                const {data} = await this.$apollo.mutate({
                    mutation: gql`mutation ($codaMandateId: String!) {
                        sendCodaMandateByMail(codaMandateId: $codaMandateId) {
                            errors {
                                code
                                detail
                                source {
                                    pointer
                                }
                            }
                        }
                    }`,
                    variables: {
                        codaMandateId: this.mandate.id,
                    },
                });
                if (!data.sendCodaMandateByMail.errors) {
                    notify.success(this.$t('send-by-mail-coda-success'));
                    this.mandate.state = 'prepared';
                } else {
                    notify.error(this.$t('err-unknown'));
                }
            } catch (err) {
                notify.error(this.$t('err-unknown'));
            }
            this.closeSendByMail();
        },
        sendByMail () {
            if (this.enabled) {
                this.showSendByMailPopup = true;
            }
        },
    },
};
</script>
<template>
    <div>
        <div class='btn btn-warning btn-xs'
             :class='{"btn-warning": enabled, "disabled": !enabled}'
             v-if='mandate.state === "sent-client"'
             @click.prevent='sendByMail'
        >
            {{ $t('btn-send-by-mail') }}
        </div>
        <Modal :show='showSendByMailPopup' v-if='enabled'>
            <div class='modal-header'>
                <button type='button' class='close' @click.prevent='closeSendByMail'>
                    <span>&times;</span>
                </button>
                <h4 class='modal-title'>
                    {{ $t('h-send-by-mail-confirm') }}
                </h4>
            </div>
            <div class='modal-body'>
                <div class='checkbox'>
                    <label>
                        <input v-model='isSendByMailConfirmed' type='checkbox'>
                        {{ $t('label-send-by-mail-confirm') }}
                    </label>
                </div>
            </div>
            <div class='modal-footer'>
                <button type='button' class='btn btn-default' @click.prevent='closeSendByMail'>
                    {{ $t('btn-cancel') }}
                </button>
                <button
                    type='button'
                    class='btn btn-primary'
                    @click.prevent='confirmSendByMail'
                    :disabled='!isSendByMailConfirmed'
                >
                    {{ $t('btn-confirm') }}
                </button>
            </div>
        </Modal>
    </div>
</template>
