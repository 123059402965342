<script>
    import vt from 'vue-types';
    import clickOutside from '@/directives/click-outside';
    // import utils from '../utils.js';

    export default {
        props: {
            show: vt.bool.isRequired,
            close: vt.func.isRequired,
            small: Boolean,
            large: Boolean,
            fixedHeight: Boolean,
            vid: String,
        },
        directives: {
            clickOutside: clickOutside,
        },
        methods: {
            clickOutside () {
                if (this.show) {
                    this.close();
                }
            },
        },
    };
</script>

<template>
    <div :class='{"fixed-height": fixedHeight}'>
        <transition name='showbg'>
            <div class='popup-bg' @click='close' v-if='show'></div>
        </transition>
        <transition name='showpopup'>
            <div
                class='popup-dialog-container container'
                :class='{ "md:w-1/2": small, "md:w-2/3": large }'
                v-if='show'
                :id='vid'
            >
                <div class='popup-main' :class='{ "w-full": large }' v-click-outside='clickOutside'>
                    <h1 class='popup-title'>
                        <slot name='title'></slot>
                        <span class='popup-close' @click='close'>
                            ✕
                        </span>
                    </h1>
                    <div class='popup-content'>
                        <slot></slot>
                    </div>
                    <div class='popup-alert' v-if='$slots.alert'>
                        <slot name='alert'></slot>
                    </div>
                    <div class='popup-buttons'>
                        <slot name='buttons'></slot>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<style scoped lang='postcss'>
.popup-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffffed;
    z-index: 3000;
    opacity: 1;
    transition: opacity linear 100ms;
}
.showbg-enter, .showbg-leave-to {
    opacity: 0;
}
.showbg-enter-active, .showbg-leave-active {
    transition: opacity linear 100ms;
}

.popup-dialog-container {
    position: fixed;
    top: -20px;
    bottom: 20px;
    left: 0;
    right: 0;
    background: transparent;
    z-index: 3010;
    display: flex;
    justify-content: center;
    align-items: center;
}
.showpopup-enter, .showpopup-leave-to {
    top: 0;
    bottom: 0;
    opacity: 0;
}
.showpopup-enter-active, .showpopup-leave-active {
    transition: all linear 300ms;
}
.popup-main {
    background: white;
    padding: 40px 50px;
    border-radius: 5px;
    box-shadow: 0px 10px 40px #ebeff1;
}
.popup-title {
    position: relative;
    margin: 0;
    padding-right: 50px;
    margin-bottom: 20px;
}
.popup-title .popup-close {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    text-align: center;
    cursor: pointer;
    color: #5f86ff;
    transition: top ease 100ms;
    user-select: none;
}
.popup-title .popup-close:active{
    top: 2px;
}
.popup-content {
    font-size: 14px;
    max-height: 60vh;
    overflow-y: auto;
}
.popup-buttons {
    display: flex;
    justify-content: flex-end;
}
.popup-alert {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
}
@media (max-width: 767px) {
    .popup-title {
        font-size: 18px;
    }
    .popup-main {
        max-width: 100%;
        padding: 20px;
    }
    .popup-content {
        padding-right: 10px;
        max-height: 80vh;
    }
    .popup-dialog-container {
        top: 0;
        bottom: 0;
    }

    .fixed-height .popup-content {
            height: 80vh;
    }
}

.fixed-height .popup-content {
    height: 60vh;
}
</style>
