<template>
    <div>
        <div class='flex items-end mt-12 mb-6'>
            <h1 class='m-0'>
                {{ $t('ttl-team-members') }}
            </h1>
            <div class='ml-auto flex'>
                <FriendlyButton
                    class='ml-2'
                    label='lbl-invite-team-member'
                    extra-small
                    no-margin
                    square
                    id='invite-member-button'
                    :action='openInviteMemberPopup'
                    symbol='plus'
                />
            </div>
        </div>
        <ContentBox>
            <List :loading='loading'>
                <template #columns>
                    <ListColumn>{{ $t('col-team-members-email') }}</ListColumn>
                    <ListColumn>{{ $t('col-team-members-name') }}</ListColumn>
                    <ListColumn>{{ $t('col-team-members-role') }}</ListColumn>
                    <ListColumn>{{ $t('col-team-members-status') }}</ListColumn>
                    <ListColumn />
                </template>
                <template #rows>
                    <ListRow v-for='(member, i) in teamMembers' :key='i'>
                        <ListItem>
                            <strong>{{ member.email }}</strong>
                        </ListItem>
                        <ListItem v-if='member.name'>
                            {{ member.name }}
                        </ListItem>
                        <ListItem v-else>
                            -
                        </ListItem>
                        <ListItem>
                            <span v-if='member.role === "owner"' class='inline-flex justify-center items-center h-12 px-8 bg-grey-50'>
                                {{ $t('lbl-team-members-role-owner') }}
                            </span>
                            <DropdownV2
                                v-model='member.role'
                                icon='sort'
                                class='flex'
                                @changed='updateRole'
                                v-else
                            >
                                <DropdownItem id='admin'>
                                    {{ $t('lbl-team-members-role-admin') }}
                                </DropdownItem>
                                <DropdownItem id='member'>
                                    {{ $t('lbl-team-members-role-member') }}
                                </DropdownItem>
                            </DropdownV2>
                        </ListItem>
                        <ListItem>
                            <template v-if='member.status === "invitation-sent" && member.role !== "owner"'>
                                <span>{{ $t('lbl-team-members-status-invitation-sent') }}</span>
                                <i class='fa fa-paper-plane ml-3'></i>
                            </template>
                            <template v-else>
                                {{ $t('lbl-team-members-status-' + member.status) }}
                            </template>
                        </ListItem>
                        <ListItem>
                            <div class='flex'>
                                <FriendlyButton
                                    v-if='member.role === "owner"'
                                    symbol='exchange'
                                    extra-small
                                    no-margin
                                    square
                                    label='btn-team-member-transfer-ownership'
                                    :action='openOwnershipTransferPopup'
                                />
                                <FriendlyButton
                                    v-else
                                    symbol='trash'
                                    extra-small
                                    no-margin
                                    square
                                    label='btn-team-member-remove-member'
                                    :action='() => openMemberRemovalPopup(member.id)'
                                />
                            </div>
                        </ListItem>
                    </ListRow>
                </template>
            </List>
        </ContentBox>

        <Popup :show='memberInvitationPopup' :close='closeInviteMemberPopup'>
            <template slot='title'>
                {{ $t('ttl-popup-invite-member') }}
            </template>

            <ValidationObserver ref='memberInvitationForm'>
                <div>
                    <FormField
                        v-model='memberInvitationEmail'
                        vid='email'
                        :name='$t("lbl-popup-invite-member-email")'
                        type='email'
                        :placeholder='$t("lbl-email")'
                        edit
                        required
                        :max='254'
                    />
                </div>
            </ValidationObserver>
            <template slot='buttons'>
                <FriendlyButton
                    label='lbl-cancel'
                    :action='closeInviteMemberPopup'
                    symbol='times'
                    small
                    square
                    secondary
                />
                <FriendlyButton
                    label='lbl-popup-invite-member'
                    :action='submitMemberInvitation'
                    small
                    symbol='check'
                    square
                />
            </template>
        </Popup>

        <Popup :show='ownershipTransferPopup' :close='closeOwnershipTransferPopup'>
            <template slot='title'>
                {{ $t('ttl-popup-transfer-ownsership') }}
            </template>

            <ValidationObserver ref='ownershipTransferForm'>
                <div>
                    <FormField
                        v-model='ownershipTransferEmail'
                        vid='email'
                        :name='$t("lbl-popup-transfer-ownership-email")'
                        type='email'
                        :placeholder='$t("lbl-email")'
                        edit
                        required
                        :max='254'
                    />
                </div>
                <p></p>
            </ValidationObserver>
            <template slot='buttons'>
                <FriendlyButton
                    label='lbl-cancel'
                    :action='closeOwnershipTransferPopup'
                    symbol='times'
                    small
                    square
                    secondary
                />
                <FriendlyButton
                    label='lbl-popup-tranfer-ownership'
                    :action='submitOwnershipTransfer'
                    small
                    symbol='check'
                    square
                />
            </template>
        </Popup>

        <Popup :show='memberRemovalPopup' :close='closeMemberRemovalPopup'>
            <template slot='title'>
                {{ $t('ttl-popup-remove-team-member') }}
            </template>

            <div>
                <p>
                    {{ $t('p-popup-remove-team-member') }}
                </p>
            </div>
            <template slot='buttons'>
                <FriendlyButton
                    label='lbl-no'
                    :action='openMemberRemovalPopup'
                    symbol='times'
                    small
                    square
                    secondary
                />
                <FriendlyButton
                    label='lbl-popup-remove-team-member'
                    :action='submitMemberRemovalPopup'
                    small
                    symbol='check'
                    square
                />
            </template>
        </Popup>
    </div>
</template>

<script>
    import List from '@/components/List';
    import ListColumn from '@/components/ListColumn';
    import ListRow from '@/components/ListRow';
    import ListItem from '@/components/ListItem';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import DropdownV2 from '@/components/DropdownV2.vue';
    import DropdownItem from '@/components/DropdownItem.vue';
    import ContentBox from '@/components/ContentBox';
    import Popup from '@/clientcomponents/Popup.vue';
    import FormField from '@/components/FormField';
    import notify from '@/notify.js';

    export default {
        name: 'team-members-view',
        components: {
            List,
            ListColumn,
            ListRow,
            ListItem,
            FriendlyButton,
            DropdownV2,
            DropdownItem,
            ContentBox,
            Popup,
            FormField,
        },
        data () {
            return {
                loading: false,
                teamMembers: [],
                memberInvitationPopup: false,
                memberInvitationEmail: '',
                ownershipTransferPopup: false,
                ownershipTransferEmail: '',
                memberRemovalPopup: false,
                memberRemovalId: '',
            };
        },
        mounted () {
            this.fetchList();
        },
        methods: {
            async fetchList () {
                this.loading = true;
                // Fetch team members.
                // You'll need to replace this with an actual API call.
                this.teamMembers = await this.getTeamMembers();
                this.loading = false;
            },
            async getTeamMembers () {
                this.loading = true;
                // Replace this with an actual API call to fetch team members.
                this.loading = false;
                return [
                    {
                        id: '802eaad4-e381-11ed-b5ea-0242ac120002',
                        name: 'John Doe',
                        email: 'john.doe@example.com',
                        role: 'owner',
                        status: 'active',
                    },
                    {
                        id: 'ae13ef58-d029-4751-b43d-6a71615dbb69',
                        email: 'jane.smith@example.com',
                        role: 'member',
                        status: 'invitation-sent',
                    },
                    {
                        id: 'd7ba5f73-80be-4dd2-a69c-0f05b81d8c36',
                        name: 'Steve Brown',
                        email: 'steve.brown@example.com',
                        role: 'admin',
                        status: 'active',
                    },
                ];
            },
            transferOwnership (member) {
                // Implement transfer ownership functionality here.
                console.log('Transfer ownership:', member);
            },
            removeMember (member) {
                // Implement remove functionality here.
                console.log('Remove member:', member);
            },
            async submitMemberInvitation () {
                // Implement member invitation functionality here.
                const valid = await this.$refs.memberInvitationForm.validate();
                if (!valid) { return; }
                console.log('Invite member with email memberInvitationEmail');
                this.getTeamMembers();
            },
            openInviteMemberPopup (email) {
                this.memberInvitationEmail = email;
                this.memberInvitationPopup = true;
            },
            closeInviteMemberPopup () {
                this.memberInvitationPopup = false;
            },
            async submitOwnershipTransfer () {
                // Implement transfer ownership here
                const valid = await this.$refs.ownershipTransferForm.validate();
                if (!valid) { return; }
                console.log('Change ownership to this.ownershipTransferEmail');
                this.getTeamMembers();
            },
            openOwnershipTransferPopup (email) {
                this.ownershipTransferEmail = email;
                this.ownershipTransferPopup = true;
            },
            closeOwnershipTransferPopup () {
                this.ownershipTransferPopup = false;
            },
            submitMemberRemovalPopup () {
                // Implement member removal here
                console.log('Remove member with id this.memberRemovalId');
                this.getTeamMembers();
            },
            openMemberRemovalPopup (id) {
                this.memberRemovalId = id;
                this.memberRemovalPopup = true;
            },
            closeMemberRemovalPopup () {
                this.memberRemovalPopup = false;
            },
            updateRole () {
                notify.success('Role has changed');
            },
        },
    };
</script>
