import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from './common/Login.vue';
import Signup from './common/Signup.vue';
import SignupDone from './common/SignupDone.vue';
import SignupConfirm from './common/SignupConfirm.vue';
import ChangePassword from './common/ChangePassword.vue';
import ConfirmResetPassword from './common/ConfirmResetPassword.vue';
import MaintenancePage from './common/MaintenancePage.vue';

import EmailVerificationWizard from './client/EmailVerificationWizard.vue';
import RestartEmailVerification from './client/RestartEmailVerification.vue';

import Fiduciary from './fiduciary/Fiduciary.vue';
import Dashboard from './fiduciary/Dashboard.vue';
import CodaSearch from './fiduciary/CodaSearch.vue';
import SodaSearch from './fiduciary/SodaSearch.vue';
import ClientSearch from './fiduciary/ClientSearch.vue';
import ClientTransfer from './fiduciary/ClientTransfer.vue';
import ClientRegularTransferList from './fiduciary/ClientRegularTransferList.vue';
import ClientPlatformTransferList from './fiduciary/ClientPlatformTransferList.vue';
import Voila2List from './fiduciary/Voila2List.vue';
import FiduClient from './fiduciary/Client.vue';
import NewClient from './fiduciary/NewClient';
import ClientInfo from './fiduciary/ClientInfo';
import ClientCoda from './fiduciary/ClientCoda';
import ClientSoda from './fiduciary/ClientSoda';
import ClientVoila2 from './fiduciary/ClientVoila2.vue';
import ClientCaro from './fiduciary/ClientCaro';
import Services from './fiduciary/Services.vue';
import FiduciaryInfo from './fiduciary/FiduciaryInfo.vue';
import Archived from './fiduciary/Archived.vue';
import FiduciarySettings from './fiduciary/FiduciarySettings.vue';
import RightsAndAccessesView from './fiduciary/RightsAndAccessesView.vue';
import CreditCardStatementClientsList from './fiduciary/CreditCardStatementClientsList.vue';
import ConnectApiConsentPage from './fiduciary/ConnectApiConsentPage.vue';

import Reseller from './reseller/Reseller.vue';
import RslrFiduSearch from './reseller/FiduSearch.vue';
import RslrCodaSearch from './reseller/CodaSearch.vue';
import RslrSodaSearch from './reseller/SodaSearch.vue';

import Unsubscribe from './components/Unsubscribe.vue';

import OrganizationSettingsView from './organization/settings/OrganizationSettingsView';
import TeamMembersView from './organization/settings/TeamMembersView';

import store from './store';
import notify from './notify.js';
import i18n from './i18n.js';
import utils from './utils.js';
import bus from '@/bus.js';

/* catch all navigation failures to prevent stacktrace being emitted
 * when we redirect inside a route, which we use to handle logouts
 * See: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            // resolve err
            return err;
        }
        // rethrow error
        return Promise.reject(err);
    });
};

Vue.use(VueRouter);

const routes = [
    /* eslint indent:off, indent-legacy:off, object-property-newline:off */
    {
        path: '/',
        name: 'index',
        component: Login,
        meta: { auth: 'guest', title: 'Login' },
    },
    {
        path: '/default',
        name: 'default',
        component: null,
        meta: { auth: 'guest', title: 'Default' },
    },
    {
        path: '/logout',
        name: 'logout',
        component: null,
        meta: { auth: 'guest', title: 'Logout' },
    },
    {
        path: '/login/:username/',
        name: 'login-user',
        component: Login,
        meta: { auth: 'guest', title: 'Login' },
    },
    {
        path: '/verify/:token',
        name: 'email-verification',
        component: EmailVerificationWizard,
        meta: {
            auth: 'guest',
            title: 'Email Verification',
            entry: 'accept',
        },
    },
    {
        path: '/refuse-verification/:token',
        name: 'email-verification-refuse',
        component: EmailVerificationWizard,
        meta: {
            auth: 'guest',
            title: 'Email Verification',
            entry: 'refuse',
        },
    },
    {
        path: '/verify/restart/:token',
        name: 'restart-email-verification',
        component: RestartEmailVerification,
        meta: {
            auth: 'guest',
            title: 'Email Verification refresh',
        },
    },
    {
        path: '/signup/',
        name: 'signup',
        component: Signup,
        meta: { auth: 'guest', title: 'Signup' },
    },
    {
        path: '/signup/:token/',
        name: 'signupToken',
        component: Signup,
        meta: { auth: 'guest', title: 'Signup' },
    },
    {
        path: '/signup-done',
        name: 'signupDone',
        component: SignupDone,
        meta: { auth: 'guest', title: 'Signup Done' },
    },
    {
        path: '/signup-confirm/:invitation/:confirmation/',
        name: 'signupConfirm',
        component: SignupConfirm,
        meta: { auth: 'guest', title: 'Signup Confirm' },
    },
    {
        path: '/change-password',
        name: 'changePW',
        component: ChangePassword,
        meta: { auth: 'auth', title: 'Change Password' },
    },
    {
        path: '/password/reset/confirm/:uuid/:token/',
        name: 'confirmResetPW',
        component: ConfirmResetPassword,
        meta: { auth: 'guest', title: 'Confirm Reset Password' },
    },
    {
        path: '/fiduciary',
        name: 'fiduciary',
        component: Fiduciary,
        meta: { auth: 'fiduciary', title: 'Fiduciary' },
        children: [
            {
                path: 'coda',
                name: 'fidu-coda-search',
                component: CodaSearch,
                meta: { auth: 'fiduciary', title: 'Coda Search' },
            },
            {
                path: 'soda',
                name: 'fidu-soda-search',
                component: SodaSearch,
                meta: { auth: 'fiduciary', title: 'Soda Search' },
            },
            {
                path: 'purchases',
                name: 'fidu-purchase-search',
                component: Voila2List,
                meta: { auth: 'fiduciary', title: 'Purchase Search' },
            },
            {
                path: 'caro',
                name: 'fidu-ccs-clients-list',
                component: CreditCardStatementClientsList,
                meta: { auth: 'fiduciary', title: 'Credit card statements' },
            },
            {
                path: 'contracts',
                name: 'fidu-services-root',
                component: Services,
                meta: { auth: 'fiduciary', title: 'Contracts & Services' },
            },
            {
                path: 'archived/:type/:uid/',
                name: 'archived',
                component: Archived,
                meta: { auth: 'fiduciary', title: 'Contracts & Services' },
            },
            {
                path: 'clients',
                name: 'fidu-client-search',
                component: ClientSearch,
                meta: { auth: 'fiduciary', title: 'Client Search' },
            },
            {
                path: 'clients/nopurchases',
                name: 'fidu-client-search-no-purchases',
                component: ClientSearch,
                meta: { auth: 'fiduciary', title: 'Client Search' },
            },
            {
                path: 'clients/transfers',
                component: ClientTransfer,
                meta: { auth: 'fiduciary', title: 'Client Transfer' },
                children: [
                    {
                        path: '',
                        name: 'fidu-client-regular-transfer-list',
                        component: ClientRegularTransferList,
                        meta: {
                            auth: 'fiduciary',
                            title: 'Client Regular Transfer List',
                        },
                    },
                    {
                        path: 'platform',
                        name: 'fidu-client-platform-transfer-list',
                        component: ClientPlatformTransferList,
                        meta: {
                            auth: 'fiduciary',
                            title: 'Client Platform Transfer List',
                        },
                    },
                ],
            },
            {
                path: 'info',
                name: 'fidu-info',
                component: FiduciaryInfo,
                meta: { auth: 'fiduciary', title: 'Fiduciary Info' },
            },
            {
                path: 'client',
                name: 'fidu-client-root',
                component: FiduClient,
                meta: { auth: 'fiduciary', title: 'Fiduciary Client' },
            },
            {
                path: 'client/new',
                name: 'fidu-client-new',
                component: NewClient,
                meta: { auth: 'fiduciary', title: 'New Fiduciary Client' },
            },
            {
                path: 'client/:uid/',
                component: FiduClient,
                meta: {
                    auth: 'fiduciary',
                    title: 'Fiduciary Client',
                    redirectOnPlatformChange: true,
                },
                props: (route) => ({
                    routeName: route.name,
                    clientId: route.params.uid,
                }),
                children: [
                    {
                        path: '',
                        name: 'fidu-client-uid-info',
                        component: ClientInfo,
                        meta: {
                            auth: 'fiduciary',
                            title: 'Fiduciary Client Infos',
                            redirectOnPlatformChange: true,
                        },
                    },
                    {
                        path: 'coda',
                        name: 'fidu-client-uid-coda',
                        component: ClientCoda,
                        meta: {
                            auth: 'fiduciary',
                            title: 'Fiduciary Client Coda',
                            redirectOnPlatformChange: true,
                        },
                    },
                    {
                        path: 'soda',
                        name: 'fidu-client-uid-soda',
                        component: ClientSoda,
                        meta: {
                            auth: 'fiduciary',
                            title: 'Fiduciary Client Soda',
                            redirectOnPlatformChange: true,
                        },
                    },
                    {
                        path: 'voila',
                        name: 'fidu-client-uid-voila',
                        component: ClientVoila2,
                        meta: {
                            auth: 'fiduciary',
                            title: 'Fiduciary Client VOILA',
                            redirectOnPlatformChange: true,
                        },
                    },
                    {
                        path: 'caro',
                        name: 'fidu-client-uid-credit-card-statement',
                        component: ClientCaro,
                        meta: {
                            auth: 'fiduciary',
                            title: 'Fiduciary Client Credit Card Statement',
                            redirectOnPlatformChange: true,
                        },
                    },
                ],
            },
            {
                path: 'settings',
                name: 'fidu-settings',
                component: FiduciarySettings,
                meta: { auth: 'fiduciary', title: 'Fiduciary settings' },
            },
            {
                path: 'settings/rights-accesses',
                name: 'workspace-settings-right-accesses',
                component: RightsAndAccessesView,
                meta: { auth: 'fiduciary', title: 'Workpspace rights and accesses' },
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                component: Dashboard,
                meta: { auth: 'fiduciary', title: 'Fiduciary Dashboard' },
            },
        ],
    },
    {
        path: '/fiduciary/connect-api-consent',
        name: 'connect-api-consent',
        component: ConnectApiConsentPage,
        meta: { auth: 'fiduciary', title: 'Connect Api consent' },
    },
    {
        path: '/organization',
        name: 'org-settings',
        component: OrganizationSettingsView,
        children: [
            {
                path: 'settings',
                name: 'organization-settings',
                component: TeamMembersView,
                children: [
                    {
                        path: '',
                        name: 'organization-settings-team-members',
                        component: TeamMembersView,
                        meta: {
                            auth: 'fiduciary',
                            title: 'Team members',
                            category: 'organization-settings',
                        },
                    },
                ],
                meta: {
                    auth: 'fiduciary',
                    title: 'Organization settings',
                    category: 'organization-settings',
                },
            },
        ],
        meta: { auth: 'fiduciary', title: 'Organization settings' },
    },
    {
        path: '/reseller',
        name: 'reseller',
        component: Reseller,
        meta: { auth: 'reseller', title: 'Reseller' },
        children: [
            {
                path: 'fiduciaries',
                name: 'rslr-fidu-search',
                component: RslrFiduSearch,
                meta: { auth: 'reseller', title: 'Coda Search' },
            },
            {
                path: 'coda',
                name: 'rslr-coda-search',
                component: RslrCodaSearch,
                meta: { auth: 'reseller', title: 'Coda Search' },
            },
            {
                path: 'coda/:uid/',
                name: 'rslr-coda-search-fidu',
                component: RslrCodaSearch,
                meta: { auth: 'reseller', title: 'Coda Search' },
            },
            {
                path: 'soda',
                name: 'rslr-soda-search',
                component: RslrSodaSearch,
                meta: { auth: 'reseller', title: 'Soda Search' },
            },
            {
                path: 'soda/:uid/',
                name: 'rslr-soda-search-fidu',
                component: RslrSodaSearch,
                meta: { auth: 'reseller', title: 'Soda Search' },
            },
        ],
    },
    {
        path: '/unsubscribe',
        name: 'unsubscribe',
        component: Unsubscribe,
        meta: { auth: 'guest' },
    },
    { path: '*', name: '404', component: Login, meta: { auth: 'guest' } },
    { path: '/maintenance', component: MaintenancePage, meta: { auth: 'guest' } },
];

const router = new VueRouter({
    routes,
});

router.routes = routes; // for easier access to routes definition

/* detect query params to enable flags */
router.beforeEach((to, from, next) => {
    if (to.query.flag) {
        for (let flag of to.query.flag.split(',')) {
            store.commit('setFlag', flag);
        }
    }
    next();
});

router.beforeEach((to, from, next) => {
    if (store.state.maintenance && to.fullPath !== '/maintenance') {
        next('/maintenance');
    } else {
        next();
    }
});

/* -- Redirections -- */

router.beforeEach(function (to, from, next) {
    if (to.name === '404') {
        notify.error('404: Page Not Found');
        next('/');
    } else if (to.name === 'default') {
        // /default routes goes to default page for role
        if (!store.state.authenticated) {
            next('/');
        } else {
            var role = store.state.user.role;
            if (from.query.redirect) {
                next(from.query.redirect);
            } else {
                next('/' + role);
            }
        }
    } else if (to.name === 'index') {
        // / routes automatically goes to default page if logged in
        if (store.state.authenticated) {
            next('/' + store.state.user.role);
        } else {
            next();
        }
    } else if (to.name === 'logout') {
        store.dispatch('logout').finally(() => {
            next('/');
        });
    } else if (to.meta && to.meta.auth === 'auth') {
        if (!store.state.authenticated) {
            notify.info(i18n.t('err-access-auth'));
            next({
                path: '/',
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else if (to.meta && to.meta.auth && to.meta.auth !== 'guest') {
        // Prevent access when wrong user role
        if (!store.state.authenticated || store.state.user.role !== to.meta.auth) {
            notify.info(i18n.t('err-access-' + to.meta.auth));
            next({
                path: '/',
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

/* -- Fiduciary Routing -- */

router.beforeEach(function (to, from, next) {
    if (to.name === 'fiduciary') {
        next('/fiduciary/dashboard');
    } else if (
        to.name === 'rslr-help' ||
        (to.name === 'rslr-help-article' && !store.state.help.loaded)
    ) {
        store
            .dispatch('loadHelpArticles')
            .then((res) => {
                next();
            })
            .catch((err) => {
                console.error('could not load articles', err);
                next('/reseller/fiduciaries');
            });
    } else {
        next();
    }
});

/* -- Reseller Routing -- */

router.beforeEach(function (to, from, next) {
    if (to.name === 'reseller') {
        next('/reseller/fiduciaries');
    } else {
        next();
    }
});

/* -- Scrolling -- */

router.afterEach((to, from) => {
    if (!(to.name === 'fidu-client-uid-info')) {
        utils.scrollTop();
    }
});

export default router;

// handle logout from the api via global event bus
bus.$on('session-expired', () => {
    notify.info(i18n.t('err-session-expired'));
    router.push('/logout');
});
