<script>
import { cleanEnterpriseNumber } from '@/utils';
import CustomTitle from '@/components/Title';
import ContentBox from '@/components/ContentBox';

export default {
    name: 'fiduciary-info',
    props: {
        accountant: {
            type: Object,
        },
        currentFiduciary: {
            type: Object,
        },
    },
    components: {
        CustomTitle,
        ContentBox,
    },
    computed: {
        cleanEnterpriseNumber () {
            return this.accountant.enterpriseNumber ? cleanEnterpriseNumber(this.accountant.enterpriseNumber) : '-';
        },
    },
};
</script>

<template>
    <div>
        <CustomTitle class='mt-12 mb-6'>
            {{ $t('h-fidu-info') }}
        </CustomTitle>
        <content-box class='md:flex'>
            <div class='w-full'>
                <form>
                    <div class='form-group'>
                        <label>{{ $t('lbl-name') }}</label>
                        <p class='cb-form-value'>
                            {{ currentFiduciary.name }}
                        </p>
                    </div>
                    <div class='form-group'>
                        <label>{{ $t('lbl-enterprise-name') }}</label>
                        <p class='cb-form-value'>
                            {{ accountant.enterpriseName }}
                        </p>
                    </div>
                    <div class='form-group'>
                        <label>{{ $t('lbl-enterpriseNumber') }}</label>
                        <p class='cb-form-value'>
                            {{ cleanEnterpriseNumber }}
                        </p>
                    </div>
                    <div class='form-group'>
                        <label>{{ $t('lbl-software') }}</label>
                        <p class='cb-form-value'>
                            {{ currentFiduciary.softwareName }}
                        </p>
                    </div>
                </form>
            </div>
            <div class='w-full'>
                <form>
                    <div class='form-group'>
                        <label>{{ $t('lbl-representative-name') }}</label>
                        <p class='cb-form-value'>
                            {{ accountant.representativeName }}
                        </p>
                    </div>
                    <div class='form-group'>
                        <label>{{ $t('lbl-representative-function') }}</label>
                        <p class='cb-form-value'>
                            {{ accountant.representativeFunction }}
                        </p>
                    </div>
                    <div class='form-group'>
                        <label>{{ $t('lbl-contact-email-2') }}</label>
                        <p class='cb-form-value'>
                            {{ currentFiduciary.contactEmail }}
                        </p>
                    </div>
                    <div class='form-group'>
                        <label>{{ $t('lbl-language') }}</label>
                        <p class='cb-form-value'>
                            {{ currentFiduciary.language }}
                        </p>
                    </div>
                </form>
            </div>
            <div class='w-full'>
                <form>
                    <div class='form-group'>
                        <label>{{ $t('lbl-address') }}</label>
                        <p class='cb-form-value'>
                            {{ accountant.address }}
                        </p>
                        <p class='cb-form-value' v-if='accountant.address2'>
                            {{ accountant.address2 }}
                        </p>
                        <p class='cb-form-value'>
                            {{ accountant.zip }}
                        </p>
                        <p class='cb-form-value'>
                            {{ accountant.city }}
                        </p>
                    </div>
                </form>
            </div>
        </content-box>
    </div>
</template>
