<template>
    <div
        class='flex flex-col'
    >
        <label v-if='name'>
            <span class='truncate name'>
                {{ name }}<span v-if='required && edit' class='required'>*</span>
            </span>
            <span v-if='$slots.info' class='ml-2 font-normal'>
                <tooltip>
                    <template #trigger>
                        <i class='fa fa-info-circle'></i>
                    </template>
                    <template #content>
                        <slot name='info'></slot>
                    </template>
                </tooltip>
            </span>
        </label>

        <date-picker
            :value='value'
            :placeholder='placeholder'
            value-type='YYYY-MM-DD'
            @input='handleInput'
            class=''
            :disabled-date='disabledDate'
            format='DD-MM-YYYY'
        />
    </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import Tooltip from '@/components/Tooltip';

    export default {
        name: 'datepicker',
        props: {
            value: {
                type: String,
                default: '',
            },
            name: String,
            placeholder: String,
            edit: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            disabledDate: Function,

        },
        components: {
            DatePicker,
            Tooltip,
        },
        data () {
            return {
            };
        },
        computed: {
            computedContent () {
                return this.nullable && this.content.length <= 0 ? null : this.content;
            },
        },
        methods: {
            handleInput (e) {
                this.$emit('input', e);
            },
        },
    };
</script>

<style lang="postcss" scoped>

</style>
