<script>
    import Modal from '../components/Modal.vue';
    import vt from 'vue-types';

    export default {
        name: 'archive-warning-modal',
        props: {
            show:    vt.bool.isRequired,
            proceed: vt.func.isRequired,
            confirm: vt.object.isRequired,
            close:   vt.func.isRequired,
            lblcancel: vt.string,
            lblarchive: vt.string,
            lblaccept: vt.string,
        },
        components: {
            'modal': Modal,
        },
        computed: {
            labelCancel: function () {
                return this.$t(this.lblcancel || 'btn-cancel');
            },
            labelArchive: function () {
                return this.$t(this.lblarchive || 'btn-archive');
            },
            labelAccept: function () {
                return this.$t(this.lblaccept || 'lbl-confirm-archive');
            },
        },
    };
</script>
<template>
    <modal :show='show'>
        <div class='modal-header alert-danger'>
            <button type='button' class='close' @click.prevent='close'>
                <span>&times;</span>
            </button>
            <h4 class='modal-title'>
                {{ $t('h-confirm-archive') }}
            </h4>
        </div>
        <div class='modal-body'>
            <slot></slot>

            <div class='form-inline'>
                <div class='form-group'>
                    <label>
                        <input v-model='confirm.confirmed' type='checkbox' id='confirmArchiveCheckbox'>
                        <span class='ml-2'>{{ labelAccept }}</span>
                    </label>
                </div>
            </div>
        </div>
        <div class='modal-footer'>
            <button type='button' class='btn btn-default' @click.prevent='close'>
                {{ labelCancel }}
            </button>
            <button type='button'
                    class='btn btn-primary'
                    :class='{"disabled": !confirm.confirmed }'
                    @click.prevent='proceed'
                    id='confirmArchiveButton'
            >
                {{ labelArchive }}
            </button>
        </div>
    </modal>
</template>
