import Vue from 'vue';
import Vuex from 'vuex';
import Prismic from 'prismic-javascript';
import Api from '@/api';
import loader from '@/loader.js';
import utils  from '@/utils.js';
import { getUser } from '@/api/user';

Vue.use(Vuex);

const baseUser = {
    username: '',
    email: '',
    role: 'guest',
    accountantIds: [],
    fiduciaryIds: [],
    resellerIds: [],
};

const baseHelp = {
    loaded: false,
    navigation: [], // list of articles in display order for each locale
    articles: {},   // article content for each id.
    locales: {},    // mapping from article id to locales ids.
    invertLink: {}, // map localized id to document id
};

const basePopups = {
    loaded: false,
    navigation: [], // list of popups in display order for each locale,
    articles: {},   // popup content for each id,
    locales: {},    // mapping from popup id to locales ids
    visible: true,
};

const store = new Vuex.Store({
    state: {
        authenticated: false,
        gui: 'desktop',
        user: Object.assign({}, baseUser),
        allBanks: [],
        currentClientProblems: [],
        help: Object.assign({}, baseHelp),
        popups: Object.assign({}, basePopups),
        api: {
            url: '',
            label: '',
            list: [],
        },
        signupInfo: {
            email: '',
            state: '',
            account_type: '',
            account_name: '',
            username: '',
            loaded: false,
        },
        maintenance: false,
        sidebar: true,
        flags: {},
        token: null,
    },
    mutations: {
        resetLoginInfo (state) {
            state.authenticated  = false;
            state.user = Object.assign({}, baseUser);
        },
        reset (state) {
            state.authenticated  = false;
            state.user  = Object.assign({}, baseUser);
            state.help = Object.assign({}, baseHelp);
            state.popups = Object.assign({}, basePopups);
            state.currentClientProblems = [];
        },

        setUser (state, {username, email, role, accountantIds, fiduciaryIds, resellerIds}) {
            state.authenticated  = true;
            state.user.username  = username;
            state.user.email     = email;
            state.user.role      = role;
            state.user.accountantIds = accountantIds;
            state.user.fiduciaryIds = fiduciaryIds;
            state.user.resellerIds = resellerIds;
            state.help = Object.assign({}, baseHelp);
            state.popups = Object.assign({}, basePopups);
            state.currentClientProblems = [];
        },

        setGui (state, gui) {
            state.gui = gui || 'desktop';
        },

        setAllBanks (state, banks) {
            state.allBanks = Object.freeze(banks);
        },

        setCurrentClientProblems (state, problems) {
            state.currentClientProblems = problems;
        },

        setHelpArticles: function (state, help) {
            state.help.navigation = help.navigation;
            state.help.articles   = help.articles;
            var locales = {};
            var invertLink = {};
            for (var nav of help.navigation) {
                locales[nav.id] = nav;
                invertLink[nav.en_US] = nav.id;
                invertLink[nav.fr_FR] = nav.id;
                invertLink[nav.nl_BE] = nav.id;
            }
            state.help.locales = locales;
            state.help.invertLink = invertLink;
            state.help.loaded  = true;
        },

        setPrismicPopups: function (state, popups) {
            state.popups.navigation = popups.navigation;
            state.popups.articles = popups.articles;
            let locales = {};
            for (var nav of popups.navigation) {
                locales[nav.id] = nav;
            }
            state.popups.locales = locales;
            state.popups.loaded = true;
        },

        setSignupInfo: function (state, signupInfo) {
            state.signupInfo.email = signupInfo.email || '';
            state.signupInfo.username = signupInfo.username || '';
            state.signupInfo.account_name = signupInfo.account_names.join(', ') || '';
            state.signupInfo.account_type = signupInfo.account_type || '';
            state.signupInfo.state = signupInfo.state || '';
            state.signupInfo.loaded = true;
        },
        setFlag (state, flag) {
            switch (flag) {
                case 'maintenance':
                    state.maintenance = true;
                    break;

                default:
                    state.flags[flag] = true;
                    break;
            }
        },
        removeFlag (state, flag) {
            if (state.flags[flag]) {
                delete state.flags[flag];
            }
        },
        hidePrismicPopups (state) {
            state.popups.visible = false;
        },
        setToken (state, token) {
            state.token = token;
        },
    },
    actions: {
        init (ctx) {
            if (!utils.strcontains(window.location.hash, 'connexion') &&
                Api.loadAuthToken()) {     // Autologin if existing token found
                return ctx.dispatch('loadUser');
            } else {
                ctx.commit('reset');
            }
        },
        setCurrentClientProblems (ctx, problems) {
            return ctx.commit('setCurrentClientProblems', problems);
        },
        setAllBanks (ctx, banks) {
            return ctx.commit('setAllBanks', banks);
        },
        async loadUser (ctx) {
            var loadId = loader.start();
            try {
                const { userInfo } = (await (await getUser()).json()).data;
                ctx.commit('setUser', userInfo);
            } catch {
                Api.setAuthToken(null);
            }
            loader.stop(loadId);
        },
        login (ctx, cred) {
            Api.setAuthToken(null);
            ctx.commit('reset');
            return Api.login(cred.username, cred.password)
                .then((res) => {
                    Api.setAuthToken(res.data.token);
                    return ctx.dispatch('loadUser');
                });
        },
        logout (ctx) {
            Api.setAuthToken(null);
            ctx.commit('resetLoginInfo');
        },
        loadHelpArticles (ctx) {
            if (window.Cypress) {
                console.info('Prismic docs are disabled in unit tests');
                return;
            }
            var loadId = loader.start();

            return Api.getPrismicApi().then((api) => {
                var navigation = [];
                return api.getSingle('faq-homepage').then((res) => {
                    navigation.push(utils.prismicDocLocales(res));

                    return api.query(Prismic.Predicates.at('document.type', 'faq-article'));
                }).then((res) => {
                    for (var article of res.results) {
                        navigation.push(utils.prismicDocLocales(article));
                    }

                    var ids = [];
                    for (var entry of navigation) {
                        ids.push(entry.fr_FR);
                        ids.push(entry.nl_BE);
                        ids.push(entry.en_US);
                    }
                    ids = utils.uniques(ids);

                    return utils.loadMultiplePrismicArticles(api, ids);
                }).then((articles) => {
                    ctx.commit('setHelpArticles', {
                        navigation: navigation,
                        articles: articles,
                    });
                    loader.stop(loadId);
                }).catch((err) => {
                    console.error('Error Loading Help Articles', err);
                    loader.stop(loadId);
                });
            });
        },
        async loadPrismicPopups (ctx) {
            if (window.Cypress) {
                console.info('Prismic popups are disabled in unit tests');
                return;
            }
            let loadId = loader.start();
            try {
                let navigation = [];
                let api = await Api.getPrismicApi();
                let res = await api.query(Prismic.Predicates.at('document.type', 'popup'));
                navigation = res.results.map((article) => utils.prismicDocLocales(article));
                let ids = [];
                for (let entry of navigation) {
                    ids.push(entry.fr_FR);
                    ids.push(entry.nl_BE);
                    ids.push(entry.en_US);
                }
                ids = utils.uniques(ids);
                let articles = await utils.loadMultiplePrismicArticles(api, ids);
                ctx.commit('setPrismicPopups', {navigation, articles});
                loader.stop(loadId);
            } catch (err) {
                loader.stop(loadId);
                console.error('Error Loading Prismic Popup');
            }
        },

        loadSignupInfo (ctx, token) {
            if (token) {
                return Api.getSignupInfo(token).then((signupInfo) => {
                    ctx.commit('setSignupInfo', signupInfo);
                });
            }
        },
        setToken (ctx, token) {
            ctx.commit('setToken', token);
        },
    },
});

export default store;
