<script>
    import { gql } from '@apollo/client/core';
    import vt from 'vue-types';

    export default {
        name: 'client-add-soda',
        props: {
            needsone: vt.bool.def(false),
        },
        data () {
            return {
                welfareOffices: [],
                id: 0,
                supportedSocialOffices: [],
                loaded: false,
            };
        },
        computed: {
            showDeleteButton () {
                return !(this.needsone && this.welfareOffices.length <= 1);
            },
        },
        watch: {
            welfareOffices: {
                deep: true,
                handler () {
                    let value = [];
                    for (const office of this.welfareOffices) {
                        if (office.selected) {
                            value.push({
                                social_welfare_id: office.selected,
                            });
                        }
                    }
                    this.$emit('input', value);
                },
            },
        },
        created: async function () {
            await this.getSupportedSocialOffices();
            this.loaded = true;
        },
        methods: {
            addWelfareOffice () {
                var officeopts = [{ value: '', label: ' ' }];

                for (var office of this.supportedSocialOffices) {
                    officeopts.push({ value: office.socialWelfareId, label: office.name });
                }

                this.id = this.id + 1;
                this.welfareOffices.push({
                    id: this.id,
                    options: officeopts,
                    selected: officeopts[0].value,
                });
            },
            removeWelfareOffice (id) {
                var noffices = [];
                for (var i = 0; i < this.welfareOffices.length; i++) {
                    var office = this.welfareOffices[i];
                    if (office.id !== id) {
                        noffices.push(office);
                    }
                }
                this.$set(this, 'welfareOffices', noffices);
            },
            validateWelfareOffices () {
                let offices = {};
                for (let i = 0; i <  this.welfareOffices.length; i++) {
                    let office = this.welfareOffices[i].selected;
                    if (this.$refs.input[i].invalid) {
                        return false;
                    }
                    if (offices[office]) {
                        this.$refs.input[i].setErrors([this.$t('val-unique-office')]);
                        return false;
                    }
                    offices[office] = true;
                }
                return true;
            },
            async getSupportedSocialOffices () {
                const { data } = await this.$apollo.query({
                    query: gql`
                      query SupportedSocialOffices {
                        getSupportedSocialOffices {
                            socialWelfareId,
                            name,
                        }
                    }`,
                });

                this.supportedSocialOffices = data.getSupportedSocialOffices;
            },
        },
    };
</script>
<template>
    <div v-if='loaded' class='row'>
        <div class='col-md-12'>
            <div class='form-inline cb-form-line' v-for='(office,i) in welfareOffices' :key='`soda${i}`'>
                <ValidationProvider
                    v-slot='{ errors }'
                    rules='required'
                    ref='input'
                    tag='div'
                    class='office'
                >
                    <label>{{ $t('lbl-office') }}</label>
                    <select class='form-control'
                            v-model='office.selected'
                            :name='$t("lbl-office")'
                            :vid='"office_"+i'
                    >
                        <option v-for='option in office.options' :value='option.value'>
                            {{ option.label }}
                        </option>
                    </select>
                    <div v-show='errors.length' class='error-message'>
                        {{ errors[0] }}
                    </div>
                </ValidationProvider>

                <template v-if='showDeleteButton'>
                    <button class='btn btn-alt' @click.prevent='removeWelfareOffice(office.id)' :id='"removeOfficeButton-" + i'>
                        <i class='fa fa-times'></i>
                    </button>
                </template>
            </div>
            <button class='btn btn-new btn-sm' @click.prevent='addWelfareOffice()' id='addSocialOfficeButton'>
                <i class='fa fa-plus-square-o'></i>
                <span>
                    {{ $t('btn-add-office') }}
                </span>
            </button>
        </div>
    </div>
</template>

<style lang='scss' scoped>
    .office {
        display: inline-block;
    }
</style>
