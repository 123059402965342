<script>
    export default {
        name: 'ccs-terms-fr',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Description du service
        </h3>

        <p>Le service CARO est un service supplémentaire de la gamme CodaBox. Les conseillers financiers qui inscrivent leurs clients au service CARO recevront directement les relevés de cartes de crédit professionnelles de leurs clients pour autant que le titulaire du compte et le titulaire de la carte aient donné leur autorisation. Les fichiers CARO seront livrés mensuellement, conformément à la période comptable de l’émetteur de carte de crédit, pour autant qu’il y ait au moins un paiement effectué avec la carte de crédit au cours de la période comptable de l’émetteur de carte de crédit. Si aucun paiement n’a été effectué au cours de la période comptable, l’émetteur de la carte de crédit n’émettra pas de relevé de dépenses et, par conséquent, CodaBox ne recevra pas ou ne sera pas en mesure de livrer les relevés de dépenses.</p>

        <p>Les fichiers CARO seront transmis par les canaux de livraison existants de CODA.</p>

        Les fichiers CARO de CodaBox:

        <ul>
            <li>
                Concernent les cartes de crédit MasterCard et VISA émis par les banques répertoriées sur : <a href='https://www.codabox.com/fr/caro/' target='_blank'>https://www.codabox.com/fr/caro/</a>
            </li>
            <li>Reçoivent un classement logique</li>
            <li>Sont livrés dans un fichier PDF et dans un format structuré</li>
        </ul>

        <p>L'utilisation du service CARO offre vous et vos clients de nombreux avantages : plus d'efficacité, moins d'erreurs, moins de documents manquants. Vous avez l'assurance de recevoir tous les fichiers CARO pertinents (de votre client) et vous n'avez plus besoin d'effectuer d’encodage manuel.</p>

        <p>CodaBox lance le service CARO avec une sélection limitée des banques Belges. CodaBox est confiante que le nombre de banques compatible augmentera rapidement. La liste des banques pour lesquelles le produit CARO est disponible pour les cartes de crédit est disponible sur <a href='https://www.codabox.com/fr/caro/' target='_blank'>https://www.codabox.com/fr/caro/</a></p>

        <p>Le service CARO de CodaBox n'est pas disponible séparément. Les fichiers CARO ne peuvent être fournis que pour les clients professionnels de l'Utilisateur pour lesquels CodaBox fournit également CODA sur la base d'un mandat délivré à cet effet.</p>

        <h3>Tarifs</h3>

        <h4 class='cb-service-title'>
            Tarifs administratifs
        </h4>

        <p>24 € par référence client (*)</p>

        <h4 class='cb-service-title'>
            Tarifs récurrents
        </h4>

        <table class='table'>
            <tbody>
                <tr>
                    <td>+ 500 références client</td>
                    <td>3,45 €/mois/référence client</td>
                </tr>
                <tr>
                    <td>Entre 241 et 500 références client</td>
                    <td>4,32 €/mois/référence client</td>
                </tr>
                <tr>
                    <td>Entre 121 et 240 références client</td>
                    <td>4,78 €/mois/référence client</td>
                </tr>
                <tr>
                    <td>Entre 61 et 120 références client</td>
                    <td>5,99 €/mois/référence client</td>
                </tr>
                <tr>
                    <td>Entre 3 et 60 références client</td>
                    <td>7,18 €/mois/référence client</td>
                </tr>
                <tr>
                    <td>Entre 1 et 2 références client</td>
                    <td>8,94 €/mois/référence client</td>
                </tr>
            </tbody>
        </table>

        <p>Pour l’établissement du tarif correct, il est également tenu compte du nombre de services actifs pour CODA et VOILA. Pour plus de détails veuillez consulter les Conditions Générales, via le lien ci-dessous.</p>

        <p>(*) identification des relevés de cartes de crédit, certains produits bancaires aient l’appui de plusieurs cartes sur 1 relevé de cartes de crédit (c.-à-d. référence client).</p>

        <h3>Conditions Générales</h3>

        <p>
            <a href='/static/pdfs/MyCodaBox.com 202301 CB-Fidu FR-Annex CARO (B4-C4).pdf' target='_blank'>Conditions générales du service</a>
        </p>
    </div>
</template>

<style>
    h3.cb-service-main-title {
        margin-top: 10px;
    }
    h4.cb-service-title {
        text-decoration: underline;
    }
    .cb-service-info-highlight {
        font-style: italic;
        font-weight: bold;
    }
</style>
