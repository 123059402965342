<script>
    import vt from 'vue-types';

    export default {
        name: 'twikey-nolink',
        props: {
            url: vt.string,
        },
        computed: {
            unsupported () {
                return !this.url || this.url.indexOf('supported') >= 0;
            },
        },
    };
</script>

<template>
    <span>
        <span v-if='unsupported' class='btn btn-alt disabled btn-xs'>
            {{ $t('lbl-unsupported') }}
        </span>
        <a v-else
           class='btn btn-alt btn-xs'
           href='https://www.twikey.com'
           target='_blank'
        >
            <span>{{ $t('lbl-provided-by') }}</span>
            <img class='cb-btn-twikey' src='/static/images/twikey-logo-small.png'>
        </a>
    </span>
</template>
