
<script>
    import Api from '../api';
    import NavBar from '@/components/NavBar.vue';

    export default {
        name: 'signup-done',
        components: {
            NavBar,
        },
        data () {
            return {
                email: this.$store.state.signupInfo.email,
            };
        },
        mounted () {
            Api.setAuthToken(null);
            this.$store.commit('reset');
        },
    };
</script>

<template>
    <div class='container'>
        <NavBar no-nav />
        <section style='margin-top:20px;'>
            <div class='panel panel-default panel-narrow panel-vertical-center panel-shadow'>
                <div class='panel-heading'>
                    <h3 class='panel-title'>
                        {{ $t('h-signup-done-email', { email: email }) }}
                    </h3>
                </div>
                <div class='panel-body'>
                    <p>
                        {{ $t('p-signup-done') }}
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>
