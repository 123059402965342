<script>
    import vt from 'vue-types';
    import bus from '../bus.js';

    export default {
        props: {
            value: vt.any.isRequired,
            field: vt.string,
            placeholder: vt.string,
            format: vt.string,
            upperCase: Boolean,
            label: vt.string,
            validator: Function,
            fullwidth: Boolean,
            required: {
                type: Boolean,
                default: false,
            },
            autocomplete: {
                type: String,
                default: 'on',
            },
            type: {
                type: String,
                default: 'text',
            },
        },
        computed: {
            placeholderDisplay () {
                return this.getPlaceholder(this.value);
            },
            validation () {
                if (this.validator) {
                    return this.validator(this.value);
                } else {
                    return {
                        state: 'incomplete',
                        message: '',
                    };
                }
            },
            hasErrors () {
                return this.validation.state === 'error';
            },
            errorMessage () {
                if (this.hasErrors) {
                    if (this.validation.state === 'error') {
                        return this.$t(this.validation.message);
                    } else {
                        return this.$refs.field.setErrors([this.field]);
                    }
                }
                return '';
            },
            ruleType () {
                // some of the types are not valid veevalidated types
                if (['text', 'boolean', 'select', 'password', 'textarea'].includes(this.type)) {
                    return '';
                } else {
                    return `|${this.type}`;
                }
            },
            ruleRequired () {
                return this.required ? 'required' : '';
            },
        },
        mounted () {
            bus.$on('validate', this.validate);
        },
        beforeDestroy () {
            bus.$off('validate', this.validate);
        },
        methods: {
            validated () {
                return !this.hasErrors && (this.$refs.field && this.$refs.field.errors.length === 0);
            },
            formatValue (input) {
                if (!this.format) {
                    return input;
                }

                input = input.replace(/\s+/g, '');
                input = input.replace(/\./g, '');
                input = input.toUpperCase();
                input = input.split('');

                var res = '';
                for (var i = 0; i < this.format.length && input.length; i++) {
                    var F = this.format[i];
                    if (F === ' ') {
                        res += ' ';
                    } else if (F === 'D') {
                        if (input[0].match(/\d/)) {
                            res += input.shift();
                        } else {
                            return res;
                        }
                    } else {
                        if (input[0] === this.placeholder[i]) {
                            res += input.shift();
                        } else {
                            return res;
                        }
                    }
                }
                return res.toUpperCase();
            },
            getPlaceholder (input) {
                if (!this.placeholder) {
                    return '';
                }

                var res = '';
                for (var i = 0; i < this.placeholder.length; i++) {
                    if (i < input.length) {
                        res += ' ';
                    } else {
                        res += this.placeholder[i];
                    }
                }
                return res;
            },
            validate () {
                this.validateFormat(this.value);
            },
            validateFormat (value) {
                if (this.format) {
                    if (value.length && value.length !== this.format.length) {
                        this.$refs.field.validate().then(() => {
                            this.$refs.field.setErrors([this.$t('val-bad-format')]);
                        });
                    } else {
                        this.$refs.field.reset();
                    }
                }
            },
            updateValue (value) {
                var res = this.formatValue(value);
                this.$refs.input.value = res;
                this.$emit('input', res);
            },
            exitField () {
                var res = this.formatValue(this.value);
                this.$refs.input.value = res;
                this.validateFormat(res);
            },
            selectAll (event) {
                setTimeout(function () {
                    event.target.select();
                }, 0);
            },
        },
    };
</script>

<template>
    <ValidationProvider :rules='`${ruleRequired}${ruleType}`'
                        slim
                        :vid='field'
                        ref='field'
                        v-slot='{ errors, failedRules }'
                        :class='{fullwidth: $props.fullwidth}'
    >
        <div class='friendly-input-box' :class='{"has-error": hasErrors, validated: validated(), fullwidth: $props.fullwidth}'>
            <label v-if='label'>{{ $t(label) }}</label>
            <div class='friendly-input'>
                <input class='friendly-input-input'
                       :type='$props.type'
                       :name='field'
                       :autocomplete='$props.autocomplete'
                       ref='input'
                       :value='value'
                       @input='updateValue($event.target.value)'
                       @focus='selectAll'
                       @blur='exitField'
                >
                <div class='friendly-input-format'>
                    <span>{{ placeholderDisplay }}</span>
                </div>
            </div>
            <div class='friendly-input-errors' v-if='hasErrors'>
                {{ errorMessage }}
            </div>
            <div class='friendly-input-errors' v-if='errors.length > 0'>
                <div v-if='failedRules.required'>
                    {{ $t('err-required-constraint') }}
                </div>
                <div v-else-if='failedRules.email'>
                    {{ $t('err-email-not-valid') }}
                </div>
                <div v-else>
                    {{ errors[0] }}
                </div>
            </div>
        </div>
    </ValidationProvider>
</template>

<style lang='scss' scoped>

.friendly-input-box {
    display: inline-block;
    text-align: left;
    position: relative;
    min-width: 300px;

    &.fullwidth {
        width: 100%;
    }
}
label {
    margin-left: 8px;
    margin-bottom: 0;
    color: #5e85ff;
    font-size: 12px;
    font-weight: 600;
    transition: color linear 100ms;
}
.friendly-input-errors {
    position: absolute;
    margin: 0;
    margin-left: 8px;
    color: red;
    font-size: 12px;
    font-weight: 600;
    transition: color linear 100ms;
}
.friendly-input {
    position: relative;
    border: solid 3px #5e85ff;
    border-radius: 5px;
    height: 44px;
    transition: border-color linear 100ms;
}
.friendly-input-input,
.friendly-input-format {
    position: absolute;
    top: 7px;
    left: 13px;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    width: calc(100% - 26px);
    color: #5e85ff;
    font-family: Inconsolata;
    white-space: pre;
    font-size: 22px;
    line-height: 22px;
    height: 22px;
    background: transparent;
    transition: color linear 100ms;
}
.friendly-input-format {
    color: #CCC;
    z-index: -1;
    top: 8px;
}
.validated .friendly-input-input {
    color: #3dcc64;
}
.validated label {
    color: #3dcc64;
}
.validated .friendly-input {
    border-color: #3dcc64;
}
.has-error label {
    color: red;
}
.has-error .friendly-input {
    border-color: red;
}
.has-error .friendly-input-input {
    color: red;
}
</style>
