
<script>
    import Api from '../api';
    import validate from '../validate.js';
    import passwords from '../passwords.js';
    import notify from '../notify.js';
    import router from '../router.js';
    import Footer from '../components/Footer.vue';
    import FormField from '../components/FormField';
    import NavBar from '@/components/NavBar.vue';

    export default {
        name: 'change-password',
        components: {
            'cb-footer': Footer,
            FormField,
            NavBar,
        },
        data () {
            return {
                'password': '',
                'newPassword': '',
                'newPassword2': '',
            };
        },
        computed: {
            matchedPasswords () {
                return this.newPassword === this.newPassword2;
            },
            passwordValidation () {
                var error = passwords.getPasswordValidation(this.newPassword);
                return { error: error };
            },
        },
        methods: {
            changePassword () {
                this.$refs.form.validate()
                    .then(() => {
                        if (!this.matchedPasswords) {
                            this.$refs.form.setErrors({newPassword2: this.$t('val-password-mismatch')});
                            return Promise.reject();
                        } else if (this.passwordValidation.error !== '') {
                            this.$refs.form.setErrors({newPassword: this.$t(this.passwordValidation.error)});
                            return Promise.reject();
                        }
                    }).then(() => {
                        return Api.changePassword(this.password, this.newPassword, this.newPassword2);
                    }).then(() => {
                        notify.success(this.$t('suc-change-password'));
                        router.replace('/default');
                    }).catch((err) => {
                        return validate.catchFieldErrors(err, this.$refs.form, {}, {
                            'current_password': 'password',
                        });
                    }).catch((err) => {
                        return validate.notifyErrors(err);
                    });
            },
        },
    };
</script>

<template>
    <div class='container'>
        <NavBar :user='$store.state.user' />
        <section>
            <div class='panel panel-default panel-narrow panel-vertical-center panel-shadow'>
                <div class='panel-heading'>
                    <h3 class='panel-title'>
                        {{ $t('h-change-password') }}
                    </h3>
                </div>
                <ValidationObserver
                    class='panel-body'
                    tag='div'
                    ref='form'
                >
                    <form role='form' @submit.prevent='changePassword'>
                        <FormField
                            v-model='password'
                            type='password'
                            :name='$t("lbl-current-password")'
                            vid='password'
                            :placeholder='$t("lbl-current-password")'
                            required
                            edit
                        />
                        <FormField
                            v-model='newPassword'
                            type='password'
                            :name='$t("lbl-new-password")'
                            vid='newPassword'
                            :placeholder='$t("lbl-new-password")'
                            :info='$t("val-password-strength-info")'
                            required
                            edit
                        />
                        <FormField
                            v-model='newPassword2'
                            type='password'
                            :name='$t("lbl-new-password2")'
                            vid='newPassword2'
                            :placeholder='$t("lbl-new-password2")'
                            required
                            edit
                        />
                        <button type='submit' class='btn btn-primary'>
                            {{ $t('btn-change-password') }}
                        </button>
                        <router-link to='/default' class='btn btn-alt' style='float:right'>
                            {{ $t('btn-cancel') }}
                        </router-link>
                    </form>
                </ValidationObserver>
            </div>
        </section>
        <cb-footer class='login__footer' />
    </div>
</template>
