<script>
import Vue from 'vue';
import { ToggleButton } from 'vue-js-toggle-button';
import Tooltip from '@/components/Tooltip';

Vue.component('ToggleButton', ToggleButton);

export default {
    name: 'toggle-input',
    props: {
        active: Boolean,
        disabled: {
            type: Boolean,
            default: false,
        },
        inverted: {
            type: Boolean,
            default: false,
        },
        info: String,
        tag: {
            type: String,
            default: 'div',
        },
        labels: Object,
        width: {
            type: Number,
            default: 40,
        },
    },
    components: {
        Tooltip,
    },
    model: {
        prop: 'active',
        event: 'change',
    },
    computed: {
        isActive: {
            get: function () {
                return this.inverted ? !this.active : this.active;
            },
            set: function (value) {
                this.onChange(this.inverted ? !value : value);
            },
        },
    },
    methods: {
        onChange (value) {
            this.$emit('change', value);
        },
    },
};
</script>

<template>
    <component :is='tag' class='input-toggle'>
        <toggle-button
            class='input-toggle__button'
            color='#70c36b'
            :disabled='disabled'
            v-model='isActive'
            :width='width'
            :sync='true'
            :speed='200'
            :height='26'
            :labels='labels'
        />

        <p class='input-toggle__text'>
            <slot></slot>
        </p>
        <div class='input-toggle__info' v-if='info'>
            <tooltip faded>
                <template #trigger>
                    <i class='fa fa-info-circle'></i>
                </template>
                <template #content>
                    {{ info }}
                </template>
            </tooltip>
        </div>
    </component>
</template>

<style lang="scss" scoped>
.input-toggle {
    display: inline-flex;
    align-items: center;
}

.input-toggle__text {
    margin: 0 0 0 10px;
    font-weight: 700;
}

.input-toggle__button {
    margin: 0;
}

.input-toggle__info {
    margin-left: 10px;
}

.input-toggle__info__body {
    margin-top: 10px;
}
</style>
