<script>
    import vt from 'vue-types';

    export default {
        name: 'selector',
        props: {
            filter: vt.bool.def(false),
            model:  vt.object.isRequired,
        },
        computed: {
            options () {
                if (this.model) {
                    return this.model.options;
                } else {
                    return [];
                }
            },
            isActive () {
                return this.filter && this.model.selected !== this.model.options[0].value;
            },
        },
    };
</script>

<template>
    <select class='form-control' v-model='model.selected' :class='{ active:isActive}'>
        <template v-for='option in options'>
            <option :value='option.value' :key='option.value'>
                {{ $t(option.label) }}
            </option>
        </template>
    </select>
</template>
