
import PrismicDOM from 'prismic-dom';

/* -- MISC -- */

function randstr (len) {
    var str = '';
    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ0123456';
    for (var i = 0; i < len; i++) {
        str += chars[Math.floor(Math.random() * chars.length)];
    }
    return str;
}

function strcontains (str, stuff) {
    return str.indexOf(stuff) >= 0;
}

function contains (array, a) {
    return array.indexOf(a) >= 0;
}

function containsAny (array, items) {
    for (let item of items) {
        if (array.indexOf(item) >= 0) {
            return true;
        }
    }
    return false;
}

function clone (a) {
    var c = {};
    if (a) {
        for (var x in a) {
            c[x] = a[x];
        }
    }
    return c;
}

function deepClone (a) {
    if (a) {
        return JSON.parse(JSON.stringify([a]))[0];
    } else {
        return a;
    }
}

function extend (a, b) {
    var c = {};
    for (var x in a) {
        c[x] = a[x];
    }
    if (b) {
        for (var y in b) {
            c[y] = b[y];
        }
    }
    return c;
}

function count (a) {
    var c = 0;
    for (var k in a) {
        if (k) {
            c += 1;
        }
    }
    return c;
}

function flatten (obj) {
    var nobj = {};
    function _flat (path, obj) {
        if (obj instanceof Array) {
            nobj[path] = obj;
        } else if ((typeof obj) in {'string': '', 'number': '', 'boolean': ''}) {
            nobj[path] = obj;
        } else {
            for (var key in obj) {
                var _path = path ? path + '.' + key : key;
                _flat(_path, obj[key]);
            }
        }
    }

    _flat(null, obj);

    return nobj;
}

function dict (list, key) {
    var d = {};
    for (var el of list) {
        d[el[key]] = el;
    }
    return d;
}

function flags (list) {
    var d = {};
    for (var el of list) {
        d[el] = true;
    }
    return d;
}

function uniques (list) {
    var newlist = [];
    var seen = {};
    for (var el of list) {
        if (!(el in seen)) {
            newlist.push(el);
            seen[el] = true;
        }
    }
    return newlist;
}

function date2strDDMMYYYY (date) {
    return  lzpad(2, date.getDate()) + '-' + lzpad(2, date.getMonth() + 1) + '-' + date.getFullYear();
}

function date2strYYYYMMDD (date) {
    return date.getFullYear() + '-' + lzpad(2, date.getMonth() + 1) + '-' + lzpad(2, date.getDate());
}

function remove (array, item) {
    let index = array.indexOf(item);
    while (index >= 0) {
        array.splice(index, 1);
        index = array.indexOf(item);
    }
    return array;
}

function pushIfUnique (list, item) {
    if (!contains(list, item)) {
        list.push(item);
    }
    return list;
}

function multarray (array, times) {
    let n = [];
    for (let i = 0; i < times; i++) {
        for (let item of array) {
            n.push(deepClone(item));
        }
    }
    return n;
}

function asynclog (promise) {
    var name = randstr(4);
    var time = Date.now();
    console.info('START: ' + name);
    return promise
        .then(function (x) {
            console.info('DONE: ' + name + ' ' + (Date.now() - time) + ' ', x);
            return promise;
        })
        .catch(function (x) {
            console.warn('FAIL: ' + name + ' ' + (Date.now() - time) + ' ', x);
            return promise;
        });
}

function serializeQuery (params) {
    var tokens = [];
    for (var p in params) {
        tokens.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]));
    }
    if (tokens.length) {
        return '?' + tokens.join('&');
    } else {
        return '';
    }
}

function defer () {
    var resolve, reject;

    var promise = new Promise(function () {
        resolve = arguments[0];
        reject  = arguments[1];
    });

    return { resolve: resolve, reject: reject, promise: promise };
}

function scrollTop () {
    (window.document.querySelector('#main') || {}).scrollTop = 0;
    (window.document.querySelector('body') || {}).scrollTop = 0;
}

function lzpad (len, num) {
    num = '' + num;
    while (num.length < len) {
        num = '0' + num;
    }
    return  num;
}

function select (selector) {
    if (selector[0] === '#') {
        return document.getElementById(selector.slice(1)) || null;
    } else if (selector[0] === '.') {
        return document.getElementsByClassName(selector.slice(1))[0] || null;
    } else {
        console.error('Invalid Element Selector:', selector);
        return null;
    }
}

function redraw (selector) {
    var el = select(selector);
    if (el) {
        el.style.display = 'none';
        el.offsetHeight; // eslint-disable-line no-unused-expressions
        el.style.display = '';
    }
}

function append (array, array2) {
    for (var item of array2) {
        array.push(item);
    }
    return array;
}

function pick (array) {
    return array[Math.floor(Math.random() * array.length)];
}

function randint (min, max) {
    if (!max) {
        max = min;
        min = 0;
    }
    return min + Math.floor(Math.random() * (max - min));
}

function Set (args) {
    var set = {};
    var arg = Array.prototype.slice.call(arguments);
    for (var item of arg) {
        set[item] = true;
    }
    return set;
}

function validateEmail (email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function nukeAuthentications () {
    delete localStorage['authToken'];
    (function () {
        var cookies = document.cookie.split('; ');
        for (var c = 0; c < cookies.length; c++) {
            var d = window.location.hostname.split('.');
            while (d.length > 0) {
                var cookieBase = encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
                var p = location.pathname.split('/');
                document.cookie = cookieBase + '/';
                while (p.length > 0) {
                    document.cookie = cookieBase + p.join('/');
                    p.pop();
                };
                d.shift();
            }
        }
    })();
}

/* --- PRISMIC UTILS --- */

function prismicDocLocales (doc) {
    // return an array associating locales with the document id
    var locales = {
        'id':    doc.id,    // using default language as reference id.
        'slug':  doc.data.slug || doc.id,
        'fiduciary': true,  // can be displayed to fiduciary accounts
        'reseller': true,   // can be displayed to reseller accounts
        'prod': true,       // can be displayed in production
        'fr_FR': doc.id,
        'nl_BE': null,
        'en_US': null,
    };
    if (doc.data['display-fiduciary'] === 'no') {
        locales.fiduciary = false;
    }
    if (doc.data['display-reseller'] === 'no') {
        locales.reseller = false;
    }
    if (doc.data['display-prod'] === 'no') {
        locales.prod = false;
    }

    for (var lang of doc.alternate_languages) {
        if (lang.lang === 'fr-be') {
            locales['fr_FR'] = lang.id;
        } else if (lang.lang === 'nl-be') {
            locales['nl_BE'] = lang.id;
        } else if (lang.lang === 'en-us') {
            locales['en_US'] = lang.id;
        }
    }
    locales['nl_BE'] = locales['nl_BE'] || locales['fr_FR'];
    locales['en_US'] = locales['en_US'] || locales['fr_FR'];
    return locales;
}

function loadMultiplePrismicArticles (api, ids) {
    ids = ids.slice();
    var articles = {};

    function load () {
        var id = ids.shift();
        if (!id) {
            return articles;
        }
        return api.getByID(id)
            .then((article) => {
                articles[article.id] = article;
            })
            .catch((err) => {
                console.error('Error while loading article id:', id, err);
            })
            .then(() => {
                return load();
            });
    }
    return load();
}
function prismicDomToText (dom) {
    return PrismicDOM.RichText.asText(dom);
}

function prismicDomToHtml (store, space, dom) {
    var html = PrismicDOM.RichText.asHtml(dom, (doc) => {
        // Setup proper routing for internal links
        // Handler seems to be obsolete since a library upgrade ...
        // Since it's all in beta, better leave it there until it stabilises,
        // The lib version is pinned anyway.
        if (doc.type === 'faq-article') {
            var id = store.state.help.invertLink[doc.id];
            return '#/fiduciary/help/' + id;
        } else {
            return '';
        }
    }, (el, content) => {
        if (el.type === 'hyperlink') {
            var data = el.data || el.raw.data;
            if (!data) {
                return '<span>' + content + '</span>';
            } else if (data.link_type === 'Web' || data.link_type === 'Media') {
                // External links must have target="_blank"
                return '<a href="' + data.url + '" target="_blank">' + content + '</a>';
            } else if (data.link_type === 'Document') {
                // Internal links
                var id = store.state.help.invertLink[data.id];
                var helpLink = '/' + space + '/help/' + id;
                return '<a href="#' + helpLink + '">' + content + '</a>';
            }
        }
        return null;
    });

    // Replace {red}foo{/red} by <span style='color:red'>foo</span>

    var tagsRegExp = '\\{([a-zA-Z]+)\\}(.*?)\\{/([a-zA-Z]+)\\}';
    return html.replace(new RegExp(tagsRegExp, 'g'), "<span style='color:$1'>$2</span>");
}

export function cleanEnterpriseNumber (uen) {
    return uen.split('').filter(e => !isNaN(parseInt(e))).join('');
}

export default {
    asynclog,
    contains,
    containsAny,
    strcontains,
    pushIfUnique,
    clone,
    deepClone,
    extend,
    count,
    flatten,
    serializeQuery,
    defer,
    scrollTop,
    lzpad,
    dict,
    flags,
    uniques,
    remove,
    multarray,
    select,
    redraw,
    pick,
    append,
    Set,
    randint,
    validateEmail,
    nukeAuthentications,
    prismicDocLocales,
    prismicDomToHtml,
    prismicDomToText,
    loadMultiplePrismicArticles,
    cleanEnterpriseNumber,
    date2strDDMMYYYY,
    date2strYYYYMMDD,
};
