<script>
import loader from '../loader';

export default {
    name: 'loader',
    data () {
        return loader.getState();
    },
};
</script>
<template>
    <div class='cb-loader' :class='{visible:loading}'>
        <div class='cb-loading' :style='{right: cssprgrss }'>
            <div class='cb-loading-color color1'>
            </div><div class='cb-loading-color color2'>
            </div><div class='cb-loading-color color3'>
            </div><div class='cb-loading-color color1'>
            </div><div class='cb-loading-color color2'>
            </div><div class='cb-loading-color color3'>
            </div>
        </div>
    </div>
</template>

<style scoped>
/* ------------------ *\
  |*       LOADER       *|
  \*--------------------*/

.cb-loader {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  background: blue;
  z-index: 1000;
  overflow: hidden;
  opacity: 0;
  transition: opacity 250ms ease;
  height: 6px;
}

.cb-loader.visible {
  opacity: 1;
}

.cb-loading-color {
  display: inline-block;
  vertical-align: top;
  height: 6px;
  width: 16.66666%;
  background: black;
}

.cb-loading {
  position: absolute;
  width: 200%;
  height: 6px;
  top: 0;
  right: 0;
}

.cb-loading-color.color1 {
  background: #72C57C;
}
.cb-loading-color.color2 {
  background: #86d690;
}
.cb-loading-color.color3 {
  background: #b7f1be;
}
.cb-loading-color.color4 {
  background: #a1eeaa;
}
.cb-loading-color.color5 {
  background: #caf1ff;
}
.cb-loading-color.color6 {
  background: #ffdea1;
}

@media (max-width: 767px) {
  .cb-loader {
    top: 0px;
  }
}
</style>
