<script>
    import vt from 'vue-types';

    export default {
        name: 'codastate',
        props: {
            mandate: vt.object.isRequired,
            variant: {
                type: String,
                default: '',
            },
            onlyLabel: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            stateChecked () {
                return this.mandate.state === 'active' && !this.onlyLabel;
            },
            stateLabel () {
                return this.$t({
                    'draft':            'lbl-coda-pre-treatment',
                    'pre-treatment':    'lbl-coda-pre-treatment',
                    'bank-procedure':   'lbl-coda-bank-procedure',
                    'sent-hq':          'lbl-coda-bank-procedure',
                    'prepared':         'lbl-coda-prepared',
                    'sent-client':      'lbl-coda-sent-client',
                    'problem':          'lbl-coda-problem',
                    'available-online': 'lbl-coda-available-online',
                    'active':           'lbl-coda-active',
                    'archived':         'lbl-coda-archived',
                    'signed':           'lbl-coda-signed',
                }[this.mandate.state]);
            },
        },
    };
</script>

<template>
    <span :class='variant'>
        <span class='cb-state-label' :class='{"ok-color":stateChecked}'>{{ stateLabel }}</span>
    </span>
</template>

<style lang='scss' scoped>
.labelized {
    .cb-state-label {
        background-color:rgba(0, 0, 0, 0.08);
        color: $color-grey-dark;
        border-radius: 100px;
        padding: 2px 8px;

        &.ok-color {
            background: $validation-color;
            color: white;
        }
    }
}
</style>
