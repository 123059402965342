<script>
    import Dropdown from '@/components/DropdownV2.vue';
    import DropdownItem from '@/components/DropdownItem.vue';
    import { datadogRum } from '@datadog/browser-rum';

    export default {
        name: 'multi-fidu-selector',
        props: {
            accountant: Object,
            currentFiduciary: Object,
        },
        components: {
            Dropdown,
            DropdownItem,
        },
        data () {
            return {
                selectedFiduciary: this.currentFiduciary.id,
            };
        },
        computed: {
            allowedFiduciaries () {
                return this.accountant.fiduciaries.filter((fidu) => {
                    return this.$store.state.user.fiduciaryIds.includes(fidu.id);
                });
            },
            isMultiFidu () {
                return this.allowedFiduciaries.length > 1;
            },
        },
        watch: {
            currentFiduciary: {
                handler (newVal) {
                    this.selectedFiduciary = newVal.id;
                },
                deep: true,
            },
        },
        methods: {
            clickFiduName () {
                if (this.isMultiFidu) {
                    this.showList = !this.showList;
                }
            },
            selectFiduciary (fiduciaryId) {
                if (fiduciaryId !== this.currentFiduciary.id) {
                    this.$emit('selectCurrentFiduciary', fiduciaryId);
                    datadogRum.addUserAction('changePlatformFromNavbar');
                    this.showList = false;
                    if (this.$route.meta.redirectOnPlatformChange) {
                        this.$router.push({ name: 'fidu-client-search' });
                    }
                }
            },
        },
    };
</script>
<template>
    <div class='w-full sm:w-auto'>
        <template
            v-if='isMultiFidu'
        >
            <Dropdown v-model='selectedFiduciary'
                      :name='$t("lbl-select-fiduciary")'
                      @input='selectFiduciary'
                      small
                      semi-transparent
            >
                <Dropdown-item
                    v-for='fiduciary in accountant.fiduciaries'
                    :id='fiduciary.id'
                    :key='fiduciary.id'
                >
                    {{ fiduciary.name }}
                </Dropdown-item>
            </Dropdown>
        </template>
        <template v-else>
            <span class='text-white text-lg font-medium'>
                {{ currentFiduciary.name }}
            </span>
        </template>
    </div>
</template>
<style scoped>
.cb-fiduciary-selector {
    display: inline-block;
    position: relative;
}
.cb-fiduciary-list {
    position: absolute;
    background: white;
    color: #337ab7;
    right: -10px;
    box-shadow: 0px 5px 10px #35363630;
    border-radius: 5px;
    margin-top: 7px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.cb-fiduciary-list-item {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.cb-fiduciary-list-item.active {
    background: #dde4ef;
}
.cb-fiduciary-selector-dropdown {
    margin-left: 5px;
}

</style>
