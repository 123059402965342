<script>
import { gql } from '@apollo/client/core';
import ContentBox from '@/components/ContentBox';
import CustomTitle from '@/components/Title';
import List from '@/components/List';
import ListRow from '@/components/ListRow';
import ListItem from '@/components/ListItem';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import Tooltip from '@/components/Tooltip';
import { datadogRum } from '@datadog/browser-rum';
import Loader from '@/loader.js';
import Dropdown from '@/components/DropdownV2';
import DropdownItem from '@/components/DropdownItem';

export default {
    name: 'problems',
    props: {
        currentFiduciary: {
            type: Object,
        },
        problemsOnly: {
            type: Boolean,
            default: false,
        },
        clientId: {
            type: String,
        },
        noControls: {
            type: Boolean,
            default: false,
        },
        domain: {
            type: String,
            default: null,
        },
        hideIfEmpty: {
            type: Boolean,
            default: false,
        },
        loadingAmount: {
            type: Number,
            default: 1000,
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
        fullHeight: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ContentBox,
        CustomTitle,
        List,
        ListRow,
        ListItem,
        FriendlyButton,
        Tooltip,
        Dropdown,
        DropdownItem,
    },
    data () {
        return {
            listLoading: false,
            problems: [],
            paging: {
                limit: 10,
                offset: 0,
            },
            typeCriteria: 'all',
            filterTypes: [],
            domains: {
                'clientInfo': [
                    'exactOnlineDeliveryProblem',
                    'contactEmailUndeliverable',
                ],
                'coda': ['kbcCbcIncompleteBankProcedure'],
                'soda': [],
                'voila': [],
                'caro': [],
            },
            clientSubpagePerProblemType: {
                'exactOnlineDeliveryProblem': '',
                'contactEmailUndeliverable': '',
                'kbcCbcIncompleteBankProcedure': '/coda',
            },
            loadLabel: 'lbl-load-all-insights',
            totalInsights: 0,
        };
    },
    computed: {
        fiduciaryId () {
            return this.currentFiduciary.id;
        },
        domainFilteredProblems () {
            return this.domain ? this.problems.filter(p => this.domains[this.domain].includes(p.type)) : this.problems;
        },
        isFiduExactOnline () {
            return this.currentFiduciary.isExactOnline;
        },
    },
    watch: {
        typeCriteria () {
            if (this.typeCriteria === '' || this.typeCriteria === 'all') {
                this.filterTypes = [];
            } else {
                this.filterTypes = this.typeCriteria;
            }
            this.updateFilteredResults();
        },
        'currentFiduciary.id': async function () {
            await this.loadList();
        },
    },
    async beforeMount () {
        await this.loadList();
    },
    methods: {
        async loadList () {
            this.listLoading = true;
            try {
                this.filterTypes = [];
                const search = await this.searchProblems();
                this.problems = search.results;
                this.$emit('loaded', this.problems);
                this.paging = search.paging;
            } catch (e) {} finally {
                this.listLoading = false;
            }
        },
        async searchProblems (limit = this.paging.limit, offset = this.paging.offset) {
            if (this.clientId) {
                const { data } = await this.$apollo.query({
                    query: gql`
                      query ProblemSearches($fiduciaryId: String, $clientId: String, $types: [String], $paging: PaginationInput, $kbcDayOffset: Int) {
                      searchProblemsByClient(fiduciaryId: $fiduciaryId, clientId: $clientId, types: $types, paging: $paging, kbcDayOffset: $kbcDayOffset) {
                          results {
                               id,
                               type,
                               created,
                          }
                          paging {
                              limit,
                              offset,
                              count
                          }
                      }
                  }`,
                    variables: {
                        fiduciaryId: this.fiduciaryId,
                        clientId: this.clientId,
                        types: ['exactOnlineDeliveryProblem', 'contactEmailUndeliverable', 'kbcCbcIncompleteBankProcedure'],
                        paging: {
                            limit: limit,
                            offset: offset,
                        },
                        kbcDayOffset: this.$route.query.kbcDayOffset ? parseInt(this.$route.query.kbcDayOffset) : 7,
                    },
                });

                return data.searchProblemsByClient;
            } else {
                const { data } = await this.$apollo.query({
                    query: gql`
                    query ProblemSearches($fiduciaryId: String, $types: [String], $paging: PaginationInput, $kbcDayOffset: Int) {
                    searchProblemsByFiduciary(fiduciaryId: $fiduciaryId, types: $types, paging: $paging, kbcDayOffset: $kbcDayOffset) {
                        results {
                             id,
                             type,
                             created,
                             client {
                                 id,
                                 enterpriseName,
                             }
                        }
                        paging {
                            limit,
                            offset,
                            count
                        }
                    }
                }`,
                    variables: {
                        fiduciaryId: this.fiduciaryId,
                        types: this.filterTypes,
                        paging: {
                            limit: limit,
                            offset: offset,
                        },
                        kbcDayOffset: this.$route.query.kbcDayOffset ? parseInt(this.$route.query.kbcDayOffset) : 7,
                    },
                });

                this.totalInsights = data.searchProblemsByFiduciary.paging.count;
                if (this.totalInsights > 1000) {
                    this.loadLabel = 'btn-problems-loadmore';
                }
                return data.searchProblemsByFiduciary;
            }
        },
        problemType (problemType) {
            return this.$t({
                'exactOnlineDeliveryProblem': 'lbl-problem-exact-online-delivery-problem',
                'contactEmailUndeliverable': 'lbl-problem-contact-email-undeliverable',
                'kbcCbcIncompleteBankProcedure': 'lbl-problem-kbc-cbc-incomplete-bank-procedure',
            }[problemType]);
        },
        addLog (log) {
            datadogRum.addUserAction(log);
        },
        async loadMore () {
            Loader.start();
            const search = await this.searchProblems(
                this.loadingAmount,
                this.problems.length,
            );
            this.problems = [
                ...this.problems,
                ...search.results,
            ];
            this.$emit('loaded', this.problems);
            this.paging = search.paging;
            Loader.stop();
        },
        displayRowLandmark (i) {
            return Number.isInteger(((i + 1) / this.loadingAmount));
        },
        async updateFilteredResults () {
            this.paging.limit = 10;
            this.paging.offset = 0;
            const search = await this.searchProblems();
            this.problems = search.results;
            this.paging = search.paging;
        },
    },
};
</script>

<template>
    <div v-if='!(hideIfEmpty && domainFilteredProblems.length === 0)'>
        <content-box>
            <div class='flex mb-3'>
                <span class='w-3 h-7 mt-1 mr-2 bg-red-400 inline-block rounded-md'></span>
                <CustomTitle :level='2' no-default-margins class='mb-3' :style-of='3'>
                    {{ $t('ttl-problem-module') }}
                </CustomTitle>
                <Tooltip class='mt-2' left>
                    <template #trigger>
                        <i class='text-grey-300 fa fa-info-circle ml-1 m-center'></i>
                    </template>
                    <template #content>
                        <p>
                            {{ $t('p-problem-coverage-p-1') }}
                        </p>
                        <ul class='pl-0 list-inside'>
                            <li v-if='isFiduExactOnline'>
                                {{ $t('p-problem-coverage-p-2') }}
                            </li>
                            <li>
                                {{ $t('p-problem-coverage-p-4') }}
                            </li>
                            <li>
                                {{ $t('p-problem-coverage-p-5') }}
                            </li>
                        </ul>
                        <p>
                            {{ $t('p-problem-coverage-p-3') }}
                        </p>
                    </template>
                </Tooltip>
                <Dropdown v-model='typeCriteria'
                          :name='$t("lbl-problem-type")'
                          class='z-50 ml-auto'
                          nullable
                          icon='sort'
                          id='insights-type-filter'
                          default-value='all'
                          v-if='!clientId'
                          direction='right'
                >
                    <DropdownItem id='all' default>
                        {{ $t('lbl-all') }}
                    </DropdownItem>
                    <DropdownItem id='exactOnlineDeliveryProblem' v-if='isFiduExactOnline'>
                        {{ $t("lbl-problem-exact-online-delivery-type") }}
                    </DropdownItem>
                    <DropdownItem id='contactEmailUndeliverable'>
                        {{ $t("lbl-problem-contact-email-undeliverable-type") }}
                    </DropdownItem>
                    <DropdownItem id='kbcCbcIncompleteBankProcedure'>
                        {{ $t("lbl-problem-kbc-cbc-incomplete-bank-procedure-type") }}
                    </DropdownItem>
                </Dropdown>
            </div>
            <List
                id='delivery-problems'
                :class='{"max-h-96": !fullHeight}'
                :loading='listLoading'
                no-border
                :scrollable='scrollable'
            >
                <template #rows>
                    <ListRow
                        v-for='(problem, i) in domainFilteredProblems'
                        :key='i'
                        :background='Number.isInteger(((i+1)/2))'
                        :landmark='displayRowLandmark(i)'
                    >
                        <ListItem>
                            <div class='flex flex-col h-full py-3' v-if='!problemsOnly'>
                                <router-link
                                    v-if='problem.client'
                                    :to='`/fiduciary/client/${problem.client.id}${clientSubpagePerProblemType[problem.type]}`'
                                >
                                    <div @click='addLog(`problemModule${problem.type.charAt(0).toUpperCase()}${problem.type.slice(1)}`)'>
                                        {{ problem.client.enterpriseName }}
                                    </div>
                                </router-link>
                                <template v-else>
                                    N/A
                                </template>
                            </div>
                            <div v-else>
                                {{ problemType(problem.type) }}
                                <tooltip class='ml-2' large>
                                    <template #trigger>
                                        <span class='text-blue-400 underline underline-offset-2 cursor-pointer hover:text-blue-300'>
                                            {{ $t('lbl-problem-resolution-cta') }}
                                        </span>
                                    </template>
                                    <template #content>
                                        <div v-if='problem.type === "exactOnlineDeliveryProblem"'>
                                            <p>
                                                {{ $t('p-problem-resolution-exact-online-delivery-p-1') }}
                                            </p>
                                            <p>
                                                {{ $t('p-problem-resolution-exact-online-delivery-p-2') }}
                                            </p>
                                        </div>
                                        <div v-if='problem.type === "contactEmailUndeliverable"'>
                                            {{ $t('p-problem-resolution-contact-email-undeliverable-p-1') }}
                                        </div>
                                        <div v-if='problem.type === "kbcCbcIncompleteBankProcedure"'>
                                            {{ $t('p-problem-resolution-kbc-cbc-incomplete-bank-procedure-p-1') }} <br>
                                            {{ $t('p-problem-resolution-kbc-cbc-incomplete-bank-procedure-p-2') }} <br>
                                            {{ $t('p-problem-resolution-kbc-cbc-incomplete-bank-procedure-p-3') }} <a :href='$t("p-problem-resolution-kbc-cbc-incomplete-bank-procedure-faq-link")' target='_blank'>FAQ</a>.
                                        </div>
                                    </template>
                                </tooltip>
                            </div>
                        </ListItem>
                        <ListItem>
                            <div class='flex flex-col items-end h-full py-3'>
                                <div v-if='!problemsOnly' class='text-right'>
                                    {{ problemType(problem.type) }}
                                </div>
                                <div class='text-grey-300 mt-1'>
                                    {{ $t('lbl-problem-date') }} {{ problem.created | dateFormat }}
                                </div>
                            </div>
                        </ListItem>
                    </ListRow>
                    <div class='text-lg text-grey-300' v-if='domainFilteredProblems.length <= 0 && !listLoading'>
                        {{ $t('p-problem-emtpy') }} 🎉
                    </div>
                </template>
            </List>
            <div class='text-grey-300 mt-6 text-xl' v-if='!noControls'>
                {{ problems.length }} / {{ paging.count }}

                <FriendlyButton
                    :label='loadLabel'
                    :action='loadMore'
                    id='btn-loadmore'
                    extra-small
                    square
                    :disabled='(problems.length >= paging.count) || listLoading'
                />
            </div>
        </content-box>
    </div>
</template>
