<script>
    export default {
        name: 'archived',
        computed: {
            mandateType () { return this.$route.params.type; },
            mandateOrService () {
                return {
                    'coda': 'mandate',
                    'soda': 'mandate',
                }[this.mandateType] || 'service';
            },
        },
    };
</script>

<template>
    <div>
        <div class='cb-fidu-title'>
            <h1 class='page-header' v-if='mandateOrService === "mandate"'>
                {{ $t('h-archived') }}
            </h1>
            <h1 class='page-header' v-else>
                {{ $t('h-archived-service') }}
            </h1>
        </div>

        <div class='row box success'>
            <div class='col-md-8'>
                <p v-if='mandateType === "coda"'>
                    {{ $t('p-archived-coda-success') }}
                </p>
                <p v-if='mandateType === "soda"'>
                    {{ $t('p-archived-soda-success') }}
                </p>
                <p v-if='mandateType === "purchase-invoice-delivery"'>
                    {{ $t('p-archived-purchase-success') }}
                </p>
            </div>
        </div>

        <div style='margin-bottom:25px'></div>

        <div class='row'>
            <div class='col-md-12'>
                <form class='form-inline text-center'>
                    <div class='btn btn-primary' @click.prevent='$router.back()'>
                        <i class='fa fa-chevron-right'></i>
                        <span>{{ $t('btn-continue') }}</span>
                    </div>
                </form>
            </div>
        </div>

        <div style='margin-bottom:100px'></div>
    </div>
</template>
