<script>
    import { gql } from '@apollo/client/core';
    import Footer from '../components/FancyFooter.vue';
    import i18n   from '../i18n.js';
    import PrismicPopup from '../components/PrismicPopup.vue';
    import { mapState } from 'vuex';
    import notify from '@/notify.js';
    import NavBar from '../components/NavBar.vue';
    import FiduciarySelector from '../components/FiduciarySelector.vue';
    import Loader from '../loader.js';

    export default {
        name: 'fiduciary-root',
        components: {
            'cb-footer': Footer,
            PrismicPopup: PrismicPopup,
            NavBar,
            FiduciarySelector,
        },
        data () {
            return {
                loaded: false,
                accountant: null,
                currentFiduciary: null,
            };
        },
        computed: {
            accountantId () {
                // should be loaded as prop in the future
                return this.$store.state.user.accountantIds[0];
            },
            defaultFiduciaryId () {
                // should be loaded as prop in the future
                return this.$store.state.user.fiduciaryIds[0];
            },
            routeName () {
                return {
                    'fidu-client-new':      'client-new',
                    'fidu-client-regular-transfer-list': 'client-transfer',
                    'fidu-coda-search':     'coda',
                    'fidu-soda-search':     'soda',
                    'fidu-purchase-search': 'purchases',
                    'fidu-services-root':   'services',
                    'fidu-client-search':   'client',
                    'fidu-info':            'info',
                    'fidu-client-root':     'client',
                    'fidu-client-uid':      'client',
                    'fidu-help':            'help',
                    'fidu-help-article':    'help',
                    'fidu-purchase-service-onboard': 'services',
                    'fidu-ccs-clients-list': 'ccs-clients-list',
                }[this.$route.name];
            },
            eboxUrl () {
                var locale = i18n.simpleLocale(i18n.locale());
                return {
                    en: 'https://www.socialsecurity.be/site_fr/general/helpcentre/ebox/transit.htm',
                    nl: 'https://www.socialsecurity.be/site_nl/general/helpcentre/ebox/transit.htm',
                    fr: 'https://www.socialsecurity.be/site_fr/general/helpcentre/ebox/transit.htm',
                }[locale];
            },
            featuresUrl () {
                var locale = i18n.simpleLocale(i18n.locale());
                return {
                    en: 'https://faq.codabox.com/en/support/solutions/articles/75000031453-what-s-new-overview',
                    nl: 'https://faq.codabox.com/nl/support/solutions/articles/75000031453-wat-is-er-nieuw-overzicht',
                    fr: 'https://faq.codabox.com/fr/support/solutions/articles/75000031453-apercu-des-am%C3%A9liorations',
                }[locale];
            },
            showCaro () {
                return this.currentFiduciary && !this.currentFiduciary.isEnterprise;
            },
            showVoila () {
                // do not show voila 2.0 for fidu enterprise as it is currently not supported
                return this.currentFiduciary && !this.currentFiduciary.isEnterprise;
            },
            ...mapState(['sidebar']),
        },
        async created () {
            await this.loadData(this.accountantId);
        },
        methods: {
            async loadData (accountantId) {
                Loader.start();
                const {data} = await this.$apollo.query({
                    query: gql`query allFiduciaryData($accountantId: String!) {
                        banks {
                            id
                            name
                            slug
                            abbreviation
                            isSupported
                            isTwikeySupported
                            disableSendPdfMandate
                            isCaroSupported
                            isStopCodaSupported
                            regexSavings
                        }
                        accountant(accountantId:$accountantId) {
                            id
                            enterpriseName
                            enterpriseNumber
                            hasBelgianVatNumber
                            vatNumber
                            address
                            address2
                            zip
                            city
                            representativeName
                            representativeFunction
                            activeForDelivery
                            fiduciaries {
                                id
                                accountantId
                                softwareId
                                softwareName
                                name
                                isEnterprise
                                isExactOnline
                                language
                                contactEmail
                                contactName
                                sendCodaAndSodaByMail
                                delegatesMandateDelivery
                                state
                            }
                        }
                    }`,
                    variables: { accountantId },
                });
                this.$store.commit('setAllBanks', data.banks || []);
                this.accountant = data.accountant;
                if (this.accountant.fiduciaries.length === 0) {
                    notify.error(this.$t('err-invalid-credentials'));
                    this.$router.push('/logout');
                    return;
                }
                if (this.$store.state.user.fiduciaryIds.includes(localStorage['selectedFiduciaryId'])) {
                    this.selectCurrentFiduciary(localStorage['selectedFiduciaryId']);
                } else {
                    this.selectCurrentFiduciary(this.defaultFiduciaryId);
                }
                this.loaded = true;

                Loader.stop();
            },
            // this method is using an id to select a fiduciary in the accountant fiduciaries list
            // typically used from multifiduciary purposes
            selectCurrentFiduciary (fiduciaryId) {
                this.currentFiduciary = this.accountant.fiduciaries.filter((fidu) => {
                    return fidu.id === fiduciaryId;
                })[0];
                localStorage['selectedFiduciaryId'] = fiduciaryId;
                this.setCurrentFiduciary(this.accountant.fiduciaries.find((fidu) => fidu.id === fiduciaryId));
            },
            // this method is setting the current fiduciary using a complete object
            // typically used to bubble up fiduciary updates from child components
            setCurrentFiduciary (fiduciary) {
                this.currentFiduciary = {
                    ...this.currentFiduciary,
                    ...fiduciary,
                };
                // if the data of the fiduciary has changed we need to update it in
                // the accountant list as well
                for (const fidu of this.accountant.fiduciaries) {
                    if (fidu.id === this.currentFiduciary.id) {
                        Object.assign(fidu, { ...fidu, ...fiduciary });
                    }
                }
            },
        },
    };
</script>

<template>
    <div class='h-full'>
        <NavBar
            :user='$store.state.user'
            :help-url='$t("help-url")'
            :gdpr-url='$t("nav-gdpr-url")'
        >
            <template #platform v-if='loaded'>
                <FiduciarySelector
                    :accountant='accountant'
                    :current-fiduciary='currentFiduciary'
                    @selectCurrentFiduciary='selectCurrentFiduciary'
                />
            </template>
        </NavBar>
        <PrismicPopup space='fiduciary' />

        <div class='cb-sidebar' v-if='sidebar'>
            <ul class='nav-sidebar' v-if='loaded'>
                <li :class='{"active": $route.name === "dashboard"}'>
                    <router-link to='/fiduciary/dashboard' class='inline-block pl-6 mt-6 py-3 w-full active:no-underline font-bold'>
                        <i class='fa fa-home'></i>{{ $t('nav-dashboard') }}
                    </router-link>
                </li>
                <li class='tq pl-6 mt-6 mb-3 font-bold fixbootstrapglich'>
                    {{ $t('nav-clients') }}
                </li>
                <li :class='{"active": routeName === "client"}'>
                    <router-link to='/fiduciary/clients' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        {{ $t('nav-clients-list') }}
                    </router-link>
                </li>
                <li :class='{"active": routeName === "client-transfer"}'>
                    <router-link to='/fiduciary/clients/transfers' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        {{ $t('nav-clients-transfer-list') }}
                    </router-link>
                </li>
                <li :class='{"active": routeName === "client-new"}'>
                    <router-link :to='{ name: "fidu-client-new" }' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        <i class='fa fa-plus-square-o'></i>
                        <span>{{ $t('btn-new-client') }}</span>
                    </router-link>
                </li>

                <li class='tq pl-6 mt-6 mb-3 font-bold'>
                    {{ $t('nav-documents') }}
                </li>
                <li :class='{"active": routeName === "coda"}'>
                    <router-link to='/fiduciary/coda' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        {{ $t('nav-coda') }}
                    </router-link>
                </li>
                <li :class='{"active": routeName === "soda"}'>
                    <router-link to='/fiduciary/soda' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        {{ $t('nav-soda') }}
                    </router-link>
                </li>
                <li :class='{"active": routeName === "purchases"}' v-if='showVoila'>
                    <router-link to='/fiduciary/purchases' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        {{ $t('nav-purchases') }}
                    </router-link>
                </li>
                <li :class='{"active": routeName === "ccs-clients-list"}' v-if='showCaro'>
                    <router-link to='/fiduciary/caro' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        {{ $t('nav-ccs-clients-list') }}
                    </router-link>
                </li>
                <li class='tq pl-6 mt-6 mb-3 font-bold' v-if='$store && $store.state.flags.mvp'>
                    {{ $t('nav-workspace-settings') }}
                </li>
                <li class='tq pl-6 mt-6 mb-3 font-bold' v-else>
                    {{ $t('nav-account') }}
                </li>
                <li :class='{"active": $route.name === "fidu-settings"}'>
                    <router-link to='/fiduciary/settings' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        {{ $t('nav-settings') }}
                    </router-link>
                </li>
                <li :class='{"active": routeName === "services"}'>
                    <router-link to='/fiduciary/contracts' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        {{ $t('nav-contracts') }}
                    </router-link>
                </li>
                <li :class='{"active": routeName === "info"}'>
                    <router-link to='/fiduciary/info' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        {{ $t('nav-fidu-info') }}
                    </router-link>
                </li>
                <li class='tq pl-6 mt-6 mb-3 font-bold'>
                    {{ $t('nav-links') }}
                </li>
                <li>
                    <a target='_blank' :href='eboxUrl' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        {{ $t('nav-ebox') }}
                    </a>
                </li>
                <li>
                    <a target='_blank' :href='featuresUrl' id='nav-new-features' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        {{ $t('nav-whats-new') }}
                    </a>
                </li>
                <li>
                    <a target='_blank' href='https://status.codabox.com' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        {{ $t('nav-status-page') }}
                    </a>
                </li>
                <div class='cb-sidebar-footer'>
                    <a target='_blank' href='http://www.codabox.com' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        <img class='cb-sidebar-logo' src='@/assets/codabox-logo-full.svg'>
                    </a>
                </div>
            </ul>
        </div>

        <div :class='{"cb-main min-h-screen": sidebar}' v-if='loaded'>
            <div class='flex flex-col justify-between min-h-screen'>
                <router-view
                    :accountant='accountant'
                    :current-fiduciary='currentFiduciary'
                    @fiduciaryUpdated='setCurrentFiduciary'
                    @selectCurrentFiduciary='selectCurrentFiduciary'
                />
                <cb-footer />
            </div>
        </div>
    </div>
</template>

<style scoped>
    .fixbootstrapglich {
        display: inline-block !important;
    }
</style>
