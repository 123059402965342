import { authFetch as fetch } from './fetch';

export function getUser () {
    return fetch('/bff/graphql', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            query: `{
              userInfo {
                username
                role
                email
                fiduciaryIds
                accountantIds
                resellerIds
              }
            }`,
        }),
    });
}
