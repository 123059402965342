<script>
    import vt from 'vue-types';

    export default {
        name: 'modal',
        props: {
            show: vt.bool.isRequired,
            large: {
                type: Boolean,
                default: false,
            },
            larger: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            showing () { return this.show; },
        },
        methods: {
            close () {
                this.$emit('update:show', false);
            },
        },
    };
</script>

<template>
    <transition name='fade'>
        <div :class='`cb-modal${larger ? " extra-large" : large ? " large" : ""}`' v-if='showing' role='dialog'>
            <div class='modal-dialog' role='document'>
                <div class='modal-content'>
                    <slot>
                    </slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all .3s ease-in-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.cb-modal {
    background: rgba($primary-color, 0.36);
    z-index: 100;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    display: flex;
    align-items: center;
    justify-content: center;

    iframe {
        width: 100% !important;
    }
}

.modal-dialog {
    margin: auto;
}

.large {
    .modal-dialog {
        width: 70%;
    }
}
.extra-large {
    .modal-dialog {
        width: 90%;
    }
}
</style>
