<script>
    import vt from 'vue-types';

    export default {
        name: 'bankaccount',
        props: {
            account: vt.object.isRequired,
        },
        computed: {
            accountClass () { return 'cb-bank-state-' + this.account.state; },
            subaccounts () {
                function score (acc) {
                    if (acc.extension) {
                        return 30 + Number(acc.extension);
                    } else if (acc.currency === 'EUR') {
                        return 10;
                    } else {
                        return 20;
                    }
                }
                return (this.account.subaccounts || []).slice().sort((a, b) => score(a) - score(b));
            },
            type () {
                if (this.account.type === 'current') {
                    return this.$t('lbl-account-current');
                } else if (this.account.type === 'savings') {
                    return this.$t('lbl-account-savings');
                } else {
                    return '';
                }
            },
            stateLabel () {
                return this.$t({
                    'new': 'lbl-ba-state-new',
                    'pending': 'lbl-ba-state-pending',
                    'draft': 'lbl-ba-state-draft',
                    'testing': 'lbl-ba-state-testing',
                    'blocked': 'lbl-ba-state-blocked',
                    'archived': 'lbl-ba-state-archived',
                    'active': 'lbl-ba-state-active',
                    'registered': 'lbl-ba-state-registered',
                    'registration-failed': 'lbl-ba-state-registration-failed',
                    'problem': 'lbl-ba-state-problem',
                    'non-zoomit-bank': 'lbl-ba-state-non-zoomit-bank',
                }[this.account.state]);
            },
        },
        methods: {
            formatSubAccount (sub) {
                return this.account.iban +
                    (sub.currency ? '-' + sub.currency : '') +
                    (sub.extension ? '-' + sub.extension : '');
            },
        },
    };
</script>
<template>
    <span class='cb-bank-state' :class='accountClass'>
        {{ account.iban }}
    </span>
</template>

<style lang="scss" scoped>
.cb-bank-state {
    position: relative;
}

.cb-subaccount {
    margin-left: 5px;
}

.cb-bank-state-new,
.cb-bank-state-pending,
.cb-bank-state-draft,
.cb-bank-state-testing,
.cb-bank-state-blocked,
.cb-bank-state-archived,
.cb-tooltip,
.cb-bank-state-non-zoomit-bank {
    color: $primary-color-dark;
}

.cb-bank-state-active,
.cb-bank-state-registered {
    color: $validation-color;
}

.cb-bank-state-registration-failed {
    color: $warning-color;
}

.cb-bank-state-problem {
    color: $error-color;
}

.cb-bank-state-archived,
.cb-bank-state-non-zoomit-bank {
    text-decoration: line-through;
}

</style>
