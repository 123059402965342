<script>
export default {
    props: {
        value: String,
    },
    data () {
        return {
            localValue: '',
        };
    },
    watch: {
        value (val) {
            this.localValue = val;
        },
    },
    created () {
        this.localValue = this.value;
    },
    methods: {
        change (id) {
            this.localValue = id;
            this.$emit('input', this.localValue);
        },
    },
};
</script>

<template>
    <div class='switch'>
        <slot></slot>
    </div>
</template>

<style lang="postcss" scoped>
.switch {
    @apply border border-solid rounded-md border-blue-500 overflow-hidden inline-flex items-center;
    height: 34px;
}
</style>
