<script>
    import { gql } from '@apollo/client/core';
    import ContentBox from '@/components/ContentBox.vue';
    import FormField from '@/components/FormField';
    import notify from '@/notify';
    export default {
        name: 'send-soda-coda-by-mail',
        props: {
            clientV2: { type: Object, required: true },
        },
        components: {
            ContentBox,
            FormField,
        },
        data () {
            return {
                sendCodaAndSodaByMail: true,
            };
        },
        watch: {
            clientV2 () {
                this.resetProperty();
            },
        },
        mounted () {
            this.resetProperty();
        },
        methods: {
            resetProperty () {
                this.sendCodaAndSodaByMail = this.clientV2.sendCodaAndSodaByMail;
            },
            async saveProperty () {
                const { data } = await this.$apollo.mutate({
                    mutation: gql`mutation updateClient($clientId: String!, $input: FiduciaryClientUpdateInput!) {
                    updateClient(clientId: $clientId, input: $input) {
                        errors { code, detail, source { pointer } }
                    }
                }`,
                    variables: {
                        clientId: this.clientV2.id,
                        input: {
                            sendCodaAndSodaByMail: this.sendCodaAndSodaByMail,
                        },
                    },
                });
                if (!data.updateClient.errors) {
                    this.$emit('clientupdated');
                } else {
                    notify.error(this.$t('err-unknown'));
                    this.resetProperty();
                }
            },
        },
    };
</script>

<template>
    <content-box :title='$t("ttl-settings")'
                 v-slot='{ edit }'
                 editable
                 :cancel='resetProperty'
                 :save='saveProperty'
                 class='mt-6'
    >
        <ValidationObserver ref='clientForm'
                            tag='div'
                            id='clientInfo'
        >
            <FormField
                v-model='sendCodaAndSodaByMail'
                type='boolean'
                :name='$t("stngs-client-send-statement")'
                :edit='edit'
                :info='$t("stngs-client-send-statement-info")'
                id='sendCodaAndSodaByMail'
                vid='sendCodaAndSodaByMail'
                no-margin
            />
        </ValidationObserver>
    </content-box>
</template>
