<script>
    export default {
        name: 'warning',
    };
</script>
<template>
    <div class='panel panel-superwarning mt20'>
        <div class='panel-heading'>
            <h3 class='panel-title'>
                <slot name='header'></slot>
            </h3>
        </div>
        <div class='panel-body'>
            <slot></slot>
        </div>
    </div>
</template>
