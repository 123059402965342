<script>
export default {
    props: {
        id: {
            type: String,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        active () {
            return this.$parent.localValue === this.id;
        },
    },
    created () {
        if (this.selected) {
            this.change();
        }
    },
    methods: {
        change () {
            this.$parent.change(this.id);
        },
    },
};
</script>

<template>
    <div class='relative'>
        <span class='background' :class='{ active }'></span>
        <button
            @click='change'
            class='option'
            :class='{ active }'
        >
            <slot></slot>
        </button>
    </div>
</template>

<style lang="postcss" scoped>
.option {
    @apply px-3 rounded-none border-none focus:outline-none bg-transparent relative text-blue-500 rounded-md flex items-center;
    height: 34px;
}

.active {
    @apply bg-blue-500 text-white;
}

.background.active:before {
    @apply border border-solid border-blue-500 bg-blue-500 rounded-md;
    content: "";
    display: inline-block;
    position: absolute;
    right: -1px;
    bottom: -1px;
    top: -1px;
    left: -1px;
}
</style>
