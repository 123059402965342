<script>
    import Api from '../api';
    import validate from '../validate.js';
    import router from '../router.js';
    import passwords from '../passwords.js';
    import Loader from '../loader.js';
    import notify from '../notify.js';
    import Footer from '../components/Footer.vue';
    import FormField from '../components/FormField';
    import NavBar from '@/components/NavBar.vue';

    export default {
        name: 'signup',
        components: {
            'cb-footer': Footer,
            FormField,
            NavBar,
        },
        data () {
            return {
                'username': '',
                'password': '',
                'password2': '',
            };
        },
        computed: {
            tokenValid () {
                return this.$store.state.signupInfo.state !== 'completed';
            },
            token () {
                return this.$route.params.token || this.$route.query.invitation;
            },
            matchedPasswords () {
                return this.password === this.password2;
            },
            passwordValidation () {
                var error = passwords.getPasswordValidation(this.password);
                return { error: error };
            },
        },
        mounted () {
            this.$store.dispatch('logout').then((res) => {
                return this.$store.dispatch('loadSignupInfo', this.token);
            }).catch(() => {
                notify.error(this.$t('error-invalid-signup-token'));
            });
        },
        methods: {
            signup () {
                if (!this.token) { return; }

                Loader.start();

                this.$refs.form.validate()
                    .then((res) => {
                        if (!this.matchedPasswords) {
                            this.$refs.form.setErrors({password2: this.$t('val-password-mismatch')});
                            return Promise.reject();
                        } else if (this.passwordValidation.error !== '') {
                            this.$refs.form.setErrors({password: this.$t(this.passwordValidation.error)});
                            return Promise.reject();
                        }
                    }).then((res) => {
                        return Api.signup(this.username, this.password, this.token);
                    }).then((res) => {
                        Loader.stop();
                        router.replace('/signup-done');
                    }).catch((err) => {
                        Loader.stop();
                        return validate.catchFieldErrors(err, this.$refs.form, {
                            'This field must be unique.': 'err-username-taken',
                        });
                    }).catch((err) => {
                        return validate.notifyErrors(err);
                    });
            },
        },
    };
</script>

<template>
    <div v-if='$store.state.signupInfo.loaded' class='container'>
        <NavBar no-nav />
        <section style='margin-top:20px;'>
            <div class='panel panel-default'>
                <div class='panel-heading'>
                    <h3 class='panel-title'>
                        {{ $t('h-signup') }}
                    </h3>
                </div>
                <div class='panel-body'>
                    <template v-if='token'>
                        <template v-if='tokenValid'>
                            <p> {{ $t('p-confirm-signup-account', { accountName: $store.state.signupInfo.account_name }) }} </p>
                            <ValidationObserver ref='form'
                                                tag='form'
                                                @submit.prevent='signup'
                            >
                                <FormField
                                    :value='$store.state.signupInfo.email'
                                    :name='$t("lbl-email")'
                                    :placeholder='$t("lbl-email")'
                                    vid='email'
                                    type='email'
                                    required
                                    :edit='false'
                                />
                                <FormField
                                    v-model='username'
                                    :placeholder='$t("lbl-username")'
                                    :name='$t("lbl-username")'
                                    vid='username'
                                    required
                                    edit
                                />
                                <FormField
                                    v-model='password'
                                    type='password'
                                    vid='password'
                                    :name='$t("lbl-password")'
                                    :placeholder='$t("lbl-password")'
                                    :info='$t("val-password-strength-info")'
                                    required
                                    edit
                                />
                                <FormField
                                    v-model='password2'
                                    type='password'
                                    vid='password2'
                                    :name='$t("lbl-password2")'
                                    :placeholder='$t("lbl-password2")'
                                    required
                                    edit
                                />
                                <button type='submit' class='btn btn-primary'>
                                    {{ $t('btn-signup') }}
                                </button>
                            </ValidationObserver>
                        </template>
                        <template v-else>
                            <p>
                                {{ $t('p-error-signup-completed', {
                                    username: $store.state.signupInfo.username
                                }) }}
                                <a href='https://mycodabox.com'>https://mycodabox.com</a>.
                                <br>
                                {{ $t('p-error-signup-completed-resend', {
                                    email: $store.state.signupInfo.email
                                }) }}
                            </p>
                        </template>
                    </template>
                    <template v-else>
                        <p> {{ $t('p-invitation-required') }} </p>
                    </template>
                </div>
            </div>
        </section>

        <cb-footer />
    </div>
</template>
<style scoped>
p {
    max-width: 100%;
}
</style>
