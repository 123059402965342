<script>
    import IbanInput from './IbanInput.vue';
    import { gql } from '@apollo/client/core';

    const ACCOUNT_TYPE_NEW = 'new';
    const ACCOUNT_TYPE_EXISTING = 'existing';

    export default {
        name: 'client-add-coda',
        props: {
            minInput: {
                type: Number,
                default: 0,
            },
            clientV2: {
                type: Object,
            },
        },
        components: {
            IbanInput,
        },
        data () {
            return {
                accounts: [],
                id: 0,
                orderableBankAccounts: [],
                notOrderableBankAccounts: [],
            };
        },
        computed: {
            showDeleteButton () {
                return this.newAccounts.length > this.minInput;
            },
            newAccounts () {
                return this.accounts.filter(account => {
                    return account.type === ACCOUNT_TYPE_NEW;
                });
            },
            existingAccounts () {
                return this.accounts.filter(account => {
                    return account.type === ACCOUNT_TYPE_EXISTING;
                });
            },
        },
        watch: {
            accounts: {
                deep: true,
                handler () {
                    const value = [];
                    for (const account of this.accounts) {
                        if (account.iban) {
                            value.push({
                                iban: account.iban,
                            });
                        }
                    }
                    this.$emit('input', value);
                },
            },
        },
        async mounted () {
            this.initInput();
            await this.getExistingBankAccount();
        },
        methods: {
            initInput () {
                for (let i = 0; i < this.minInput; i++) {
                    this.addBankAccount('', ACCOUNT_TYPE_NEW, false);
                }
            },
            isIbanDuplicated (iban) {
                return (iban.length > 0) && // we do not want to show the nessage in multiple empty ibans
                       this.accounts.filter(acc => acc.iban.replace(/\s+/g, '') === iban.replace(/\s+/g, '')).length > 1;
            },
            async getExistingBankAccount () {
                if (this.clientV2) {
                    const { data } = await this.$apollo.query({
                        query: gql`query getBankAccounts($clientId: String) {
                            bankAccounts(clientId: $clientId) {
                                id
                                bankId
                                type
                                state
                                iban
                            }
                        }`,
                        variables: {
                            clientId: this.clientV2.id,
                        },
                    });
                    for (const bankAccount of data.bankAccounts) {
                        const bank = this.$store.state.allBanks.find((bank) => bank.id === bankAccount.bankId);
                        bankAccount.bank = bank;
                        if (bankAccount.state === 'archived' && bank && bank.isSupported) {
                            this.orderableBankAccounts.push(bankAccount);
                        } else {
                            this.notOrderableBankAccounts.push(bankAccount);
                        }
                    }
                }
            },
            handleOrderableBankAccountCheckbox (e) {
                if (e.target.checked) {
                    this.addBankAccount(e.target.value, ACCOUNT_TYPE_EXISTING, false);
                } else {
                    const existingAccount = this.existingAccounts.find(account => {
                        return account.iban === e.target.value;
                    });

                    this.removeAccount(existingAccount.id, false);
                }
            },
            addBankAccount (iban = '', type = ACCOUNT_TYPE_NEW, runValidation = true) {
                this.id = this.id + 1;
                this.accounts.push({
                    id: this.id,
                    type: type,
                    iban: iban,
                });

                if (runValidation) {
                    this.validateBankAccounts();
                }
            },
            removeAccount (id, runValidation = true) {
                var newaccounts = [];
                for (var i = 0; i < this.accounts.length; i++) {
                    var account = this.accounts[i];
                    // this.errors.remove('account_' + i);
                    if (account.id !== id) {
                        newaccounts.push(account);
                    }
                }
                this.$set(this, 'accounts', newaccounts);
                if (runValidation) {
                    this.validateBankAccounts();
                }
            },
            resetBankAccounts () {
                this.$set(this, 'accounts', []);
            },
            async validateBankAccounts () {
                // Clean errors
                this.orderableBankAccounts = this.orderableBankAccounts.map(acc => {
                    delete acc.error;
                    return acc;
                });

                let hasErrors = false;

                // Run input validation
                if (!(await this.$refs.accountsValidation.validate())) {
                    hasErrors = true;
                }

                // Validate duplicate in the new accounts provided via text input
                for (const [i, acc] of this.accounts.entries()) {
                    const duplicate = await this.isIbanDuplicated(acc.iban);
                    if (duplicate) {
                        if (acc.type === ACCOUNT_TYPE_NEW) {
                            this.$refs['account_' + i][0].$refs.input.setErrors([this.$t('val-unique-acc')]);
                        } else if (acc.type === ACCOUNT_TYPE_EXISTING) {
                            this.orderableBankAccounts = this.orderableBankAccounts.map(orderableAcc => {
                                if (orderableAcc.iban === acc.iban) {
                                    orderableAcc.error = this.$t('val-unique-acc');
                                }

                                return orderableAcc;
                            });
                        }

                        hasErrors = true;
                    } else {
                        if (acc.type === ACCOUNT_TYPE_NEW) {
                            const validIban = await this.$refs['account_' + i][0].validate();
                            if (!validIban) {
                                hasErrors = true;
                            }
                        }
                    }
                }

                return !hasErrors;
            },
        },
    };
</script>
<template>
    <section>
        <ValidationObserver ref='accountsValidation'>
            <div class='row'>
                <div class='col-md-12'>
                    <h4>{{ $t('ttl-new-bank-accounts') }}</h4>

                    <div class='form-inline cb-form-line' v-for='(account, i) in accounts' :key='i'>
                        <div v-if='account.type === "new"' class='flex'>
                            <IbanInput
                                v-model='account.iban'
                                :field='"account_" + i'
                                :ref='"account_" + i'
                                :key='account.id'
                                :id='"account_" + i'
                            />

                            <template v-if='showDeleteButton'>
                                <button class='btn btn-alt cb-btn-tr ml-6' @click.prevent='removeAccount(account.id)' :id='"removeBankAccountButton-"+i'>
                                    <i class='fa fa-times'></i>
                                </button>
                            </template>
                        </div>
                    </div>
                    <button class='btn btn-new btn-sm' @click.prevent='() => {addBankAccount("", "new", false)}' id='addBankAccountButton'>
                        <i class='fa fa-plus-square-o'></i>
                        <span>{{ $t('btn-add-bank-account') }}</span>
                    </button>
                </div>
            </div>

            <div class='row orderable-bank-accounts' v-if='orderableBankAccounts.length'>
                <div class='col-md-12'>
                    <h4>{{ $t('ttl-orderable-bank-accounts', {clientName: clientV2.enterpriseName}) }}</h4>

                    <p>
                        {{ $t('txt-orderable-bank-accounts') }}
                    </p>

                    <div
                        :class='`checkbox${orderableBankAccount.error ? " has-error" : ""}`'
                        v-for='(orderableBankAccount, index) in orderableBankAccounts'
                        :key='index'
                    >
                        <label>
                            <input
                                type='checkbox'
                                :id='"orderable_ba_" + orderableBankAccount.iban'
                                :value='orderableBankAccount.iban'
                                @change='handleOrderableBankAccountCheckbox'
                            >

                            {{ orderableBankAccount.iban }} ({{ orderableBankAccount.bank.name }})
                        </label>

                        <p v-if='orderableBankAccount.error' class=''>
                            {{ orderableBankAccount.error }}
                        </p>
                    </div>
                </div>
            </div>
        </ValidationObserver>

        <div class='row not-orderable-bank-accounts' v-if='notOrderableBankAccounts.length'>
            <div class='col-md-12'>
                <h4>{{ $t('ttl-not-orderable-bank-accounts', {clientName: clientV2.enterpriseName}) }}</h4>

                <p>
                    {{ $t('txt-not-orderable-bank-accounts') }}
                </p>

                <ul class='list-unstyled'>
                    <li v-for='(notOrderableBankAccount, index) in notOrderableBankAccounts' :key='index'>
                        {{ notOrderableBankAccount.iban }} ({{ notOrderableBankAccount.bank.name }})
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<style lang='scss' scoped>
    .has-error, .has-error.checkbox label {
        color: $error-color;
    }

    .orderable-bank-accounts,
    .not-orderable-bank-accounts {
        margin-top: 20px;

        p {
            font-weight: 300;
        }
    }
</style>
