<script>
    export default {
        name: 'purchase-terms-nl',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Beschrijving van de dienst
        </h3>

        <p>De dienst VOILA is een extra dienst in het gamma van CodaBox. De aankoopfacturen van uw klanten, verzonden via verschillende kanalen (Peppol, Zoomit, …), kunt u voortaan in elektronisch formaat ontvangen. Uw klant hoeft deze aankoopfacturen dan niet langer aan u te bezorgen.</p>

        <p>Je kan de dienst VOILA activeren via het menu ‘KLANTEN VOLMACHTEN & DIENSTEN', dit kan voor al je klanten in één keer of voor elke klant afzonderlijk en vraagt slechts enkele muisklikken!</p>

        <p>BELANGRIJK: Informeer je klanten dat ze deze dienst van CodaBox zelf nog moeten aanvaarden. Dit is belangrijk om het succes van de inschrijving op deze dienst te verhogen.</p>

        <p>Hoe werkt deze dienst voor uw klant?</p>

        <ol>
            <li>Wanneer je de dienst voor je klant bestelt, zal deze via e-mail gevraagd worden de dienst VOILA te aanvaarden.</li>
            <li>Je klanten worden door CodaBox geïnformeerd over de volgende stappen na het aanvaarden van deze dienst, en wat dit voor hen betekent.</li>
            <li>Zodra je klant de dienst heeft aanvaard, zal CodaBox deze bevestiging gebruiken om je klant in te schrijven op de verschillende netwerken voor elektronische facturatie.</li>
            <li>Je klant zal de facturen die hij via deze kanalen ontvangt niet meer zelf aan jou moeten bezorgen. Zodra een leverancier een factuur verzendt zal CodaBox deze aan jouw klant bezorgen via de gekozen leveringswijze (jouw boekhoudkundig klantenportaal of e-mail). Jij beschikt meteen over zijn elektronische facturen die CodaBox u bezorgt via dezelfde weg als voor de ontvangst van CODA.</li>
        </ol>

        <h3>Tarieven</h3>

        <h4 class='cb-service-title'>
            Administratieve kosten
        </h4>

        <p>Er worden geen administratieve kosten aangerekend.</p>

        <h4 class='cb-service-title'>
            Recurrente kosten
        </h4>

        <p>Tot 1 juli 2022 wordt het VOILA-Product gratis aangeboden. Vanaf 1 juli 2022 is de hiernavermelde prijslijst voor het VOILA-Product van toepassing. Tot nader order past CodaBox een korting toe van 65% op de vermelde standaardprijzen. Als CodaBox deze korting zou verminderen of intrekken, zal CodaBox je hiervan minstens drie maanden op voorhand inlichten.</p>
        <table class='table'>
            <tbody>
                <tr>
                    <td>+ 500 ondernemingsnummers</td>
                    <td>3,45 €/maand/ondernemingsnummer</td>
                </tr>
                <tr>
                    <td>Tussen 241 en 500 ondernemingsnummers</td>
                    <td>4,32 €/maand/ondernemingsnummer</td>
                </tr>
                <tr>
                    <td>Tussen 121 en 240 ondernemingsnummers</td>
                    <td>4,78 €/maand/ondernemingsnummer</td>
                </tr>
                <tr>
                    <td>Tussen 61 en 120 ondernemingsnummers</td>
                    <td>5,99 €/maand/ondernemingsnummer</td>
                </tr>
                <tr>
                    <td>Tussen 3 en 60 ondernemingsnummers</td>
                    <td>7,18 €/maand/ondernemingsnummer</td>
                </tr>
                <tr>
                    <td>Voor 1 en 2 ondernemingsnummers</td>
                    <td>8,94 €/maand/ondernemingsnummer</td>
                </tr>
            </tbody>
        </table>
        <p>
            Voor de bepaling van het correcte tarief wordt ook rekening gehouden met het aantal actieve diensten voor CODA en CARO. Meer details kan je vinden in de Algemene Voorwaarden, via de link hieronder.
        </p>

        <h3>Algemene Voorwaarden</h3>

        <p>
            <a href='/static/pdfs/MyCodaBox.com 202301 CB-Fidu NL-Annex VOILA (B3-C3).pdf' target='_blank'>Algemene voorwaarden van de dienst</a>
        </p>
    </div>
</template>

<style>
    .hl { color: rgb(153,204,0); }

    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }
</style>
