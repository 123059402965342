var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inline-block"},[_c('span',{staticClass:"cb-friendly-button",class:{
              secondary:_vm.secondary,
              warning: _vm.warning,
              danger: _vm.danger,
              enabled:!(_vm.disabled||_vm.busy),
              disabled:_vm.disabled,
              busy:_vm.busy,
              small: _vm.small,
              "extra-small": _vm.extraSmall,
              "no-margin": _vm.noMargin,
              "no-text": _vm.noText,
              square: _vm.square,
              micro: _vm.micro
          },on:{"click":_vm.doClick}},[(!_vm.noText)?_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.label))+" ")]):_vm._e(),(_vm.symbol)?_c('i',{staticClass:"mr-2 ml-0",class:_vm.symbolClass}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }