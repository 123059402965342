<script>
    import i18n from '../i18n.js';
    import flagEn from '../assets/flag-en.svg';
    import flagBe from '../assets/flag-be.svg';
    import Dropdown from '@/components/Dropdown.vue';

    export default {
        name: 'language-switcher',
        components: {
            'dropdown': Dropdown,
        },
        data () {
            return {
                flagEn: flagEn,
                flagBe: flagBe,
                flagFr: flagBe,
            };
        },
        computed: {
            lang () {
                return this.$i18n.locale();
            },
            lng () {
                return this.$i18n.locale().split('_')[0];
            },
            flagSrc () {
                return {
                    'en_US': flagEn,
                    'fr_FR': flagBe,
                    'nl_BE': flagBe,
                }[this.$i18n.locale()];
            },
        },
        methods: {
            setLang (lang) {
                i18n.setLocale(lang);
            },
        },
    };
</script>

<template>
    <dropdown tag='li'>
        <template slot='toggle'>
            <span class='cb-lang'>
                <img class='flag big' :src='flagSrc'>
            </span>
            <i class='fa fa-chevron-down'></i>
        </template>
        <template slot='list'>
            <li v-if='lang !== "en_US"'>
                <a href='javascript:void(0)' @click.prevent='setLang("en_US")'>
                    <img class='flag' :src='flagEn' alt='English'>
                    English
                </a>
            </li>
            <li v-if='lang !== "nl_BE"'>
                <a href='javascript:void(0)' @click.prevent='setLang("nl_BE")'>
                    <img class='flag' :src='flagBe' alt='Nederlands'>
                    Nederlands
                </a>
            </li>
            <li v-if='lang !== "fr_FR"'>
                <a href='javascript:void(0)' @click.prevent='setLang("fr_FR")'>
                    <img class='flag' :src='flagFr' alt='Français'>
                    Français
                </a>
            </li>
        </template>
    </dropdown>
</template>

<style scoped>
    .cb-lang {
        margin-right: 10px;
    }
</style>
