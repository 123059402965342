<script>
    import Api from '../api';
    import validate from '../validate.js';
    import notify from '../notify.js';
    import Footer from '../components/CenteredFooter.vue';
    import NavBar from '@/components/NavBar.vue';

    export default {
        name: 'signup',
        components: {
            'cb-footer': Footer,
            NavBar,
        },
        data () {
            return {
                'state': 'sending',
            };
        },
        mounted () {
            this.$store.dispatch('loadSignupInfo', this.$route.params.invitation).then(res => {
                this.confirmSignup();
            }).catch(() => {
                notify.error(this.$t('error-invalid-signup-token'));
            });
        },
        methods: {
            confirmSignup () {
                this.state = 'sending';

                var invitation = this.$route.params.invitation;
                var confirmation = this.$route.params.confirmation;

                Api.signupConfirm(invitation, confirmation)
                    .then((res) => {
                        this.state = 'success';
                    }).catch((err) => {
                        if (err.error !== 'api') {
                            return validate.notifyErrors(err);
                        } else if (err.body.confirmation_token) {
                            let msg = err.body.confirmation_token[0];
                            if (msg === 'Signup already confirmed') {
                                this.state = 'success';
                            } else if (msg === 'Confirmation token expired') {
                                this.state = 'expired';
                            } else {
                                this.state = 'failure';
                            }
                        } else if (err.body.non_fields_errors) {
                            let msg = err.body.non_field_errors[0];
                            if (msg === 'User has already completed the signup process') {
                                this.state = 'success';
                            } else {
                                this.state = 'failure';
                            }
                        } else {
                            this.state = 'failure';
                        }
                    });
            },
        },
    };
</script>
<template>
    <div class='container'>
        <NavBar no-nav />
        <section>
            <div class='panel panel-default panel-narrow panel-vertical-center panel-shadow'>
                <div class='panel-heading'>
                    <h3 class='panel-title'>
                        {{ $t('h-confirm-signup-account') }}
                    </h3>
                </div>
                <div class='panel-body'>
                    <template v-if='state === "sending"'>
                        <i class='fa fa-circle-o-notch fa-spin'></i>
                        <span>{{ $t('lbl-waiting-for-confirmation') }}</span>
                    </template>
                    <template v-if='state === "success"'>
                        <h3 class='mt10 mb20'>
                            {{ $t('h-signup-confirmation-success-account-name',
                                  {accountName: $store.state.signupInfo.account_name})
                            }}
                        </h3>
                        <p> {{ $t('p-signup-confirmation-success') }}</p>
                        <p> {{ $t('lbl-username') }} : {{ $store.state.signupInfo.username }}</p>
                        <p> {{ $t('lbl-email') }} : {{ $store.state.signupInfo.email }}</p>
                        <div class='text-center mt40 mb20'>
                            <router-link to='/logout' class='btn btn-primary'>
                                {{ $t('btn-login-new-account') }}
                            </router-link>
                        </div>
                    </template>
                    <template v-if='state === "failure"'>
                        <h3>{{ $t('h-signup-confirmation-failed') }}</h3>
                        <p> {{ $t('p-signup-confirmation-failed') }}</p>
                    </template>
                    <template v-if='state === "expired"'>
                        <h3>{{ $t('h-signup-confirmation-expired-resend') }}</h3>
                        <p>
                            {{ $t('p-signup-confirmation-expired-resend',
                                  {email: $store.state.signupInfo.email })
                            }}
                        </p>
                    </template>
                </div>
            </div>
        </section>

        <cb-footer />
    </div>
</template>
