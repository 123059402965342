<script>
import Api from '@/api';
import Loader from '../loader.js';
import NavBar from '@/components/NavBar.vue';

export default {
    name: 'unsubscribe',
    components: {
        NavBar,
    },
    data: function () {
        return {
            token: null,
            status: null,
        };
    },
    created: function () {
        this.token = this.$route.query.token;
        this.unsubscribe();
    },
    methods: {
        unsubscribe: function () {
            Loader.start();
            Api.unsubscribe(this.token)
                .then(() => {
                    Loader.stop();
                    this.status = 'success';
                })
                .catch(() => {
                    Loader.stop();
                    this.status = 'error';
                });
        },
    },
};
</script>

<template>
    <div class='unsubscribe'>
        <NavBar no-nav no-login />
        <div v-if='status === "success"' class='unsubscribe__inner'>
            <p class='unsubscribe__status unsubscribe__status--success'>
                {{ $t('unsubscribe-head-success') }}
            </p>
            <p class='unsubscribe__details'>
                {{ $t('unsubscribe-details-success') }}
            </p>
        </div>
        <div v-if='status === "error"' class='unsubscribe__inner'>
            <p class='unsubscribe__status unsubscribe__status--error'>
                {{ $t('unsubscribe-head-error') }}
            </p>
            <p class='unsubscribe__details'>
                {{ $t('unsubscribe-details-error') }}
            </p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.unsubscribe {
    height: 100%;
}

.unsubscribe__inner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    padding: 80px 30px 130px;
    max-width: 600px;
    height: 100%;
}

.unsubscribe__status {
    font-size: 24px;
    margin-bottom: 30px;
}

.unsubscribe__status--success {
    color: $validation-color;
}

.unsubscribe__status--error {
    color: $error-color;
}

.unsubscribe__details {
    font-size: 18px;
}
</style>
