<script>

export default {
    name: 'settings',
    props: {
        tag: {
            type: String,
            default: 'div',
        },
    },
};
</script>

<template>
    <component :is='tag' class='settings'>
        <slot></slot>
    </component>
</template>

<style lang="scss" scoped>
@import '@/scss/mixins.scss';

.settings {
    @include no-type-list();
    display: flex;
    flex-direction: column;

    >* {
        padding-bottom: 10px;
    }

    li:last-child {
        padding-bottom: 0;
    }
}
</style>
