<script>
    import VueRouter from 'vue-router';
    import ResetPassword from '../components/ResetPassword.vue';
    import Footer   from '../components/CenteredFooter.vue';
    import store    from '../store';
    import router   from '../router.js';
    import validate from '../validate';
    import FormField from '../components/FormField';
    import NavBar from '@/components/NavBar.vue';
    import notify from '@/notify';

    const { isNavigationFailure, NavigationFailureType } = VueRouter;

    export default {
        name: 'login',
        components: {
            'cb-footer': Footer,
            'reset-password': ResetPassword,
            FormField,
            NavBar,
        },
        data () {
            return {
                username: '',
                password: '',
                showReset: false,
                isLoginOngoing: false,
            };
        },
        mounted () {
            if (this.$route.name === 'login-user') {
                this.username = this.$route.params.username;
                this.$refs.password.focus();
            }
        },
        methods: {
            async login () {
                if (this.isLoginOngoing) return;
                const valid = await this.$refs.form.validate();

                if (valid) {
                    this.isLoginOngoing = true;
                    store.dispatch('login', {
                        username: this.username,
                        password: this.password,
                    }).then((res) => {
                        return router.replace('/default');
                    }).catch((err) => {
                        // ignore router errors thrown by the redirect from /default
                        if (!isNavigationFailure(err, NavigationFailureType.redirect)) {
                            throw err;
                        }
                    }).catch((err) => {
                        if (err.status === 400) {
                            validate.reportGQLFieldErrors(err.body.errors, this.$refs.form);
                            validate.notifyGQLValidationErrors(err.body.errors, {
                                'invalid': 'err-invalid-credentials',
                            });
                        } else {
                            notify.error(this.$t('err-unknown'));
                        }
                        return;
                    }).finally(() => {
                        this.isLoginOngoing = false;
                    });
                }
            },
            toggleReset () {
                this.showReset = !this.showReset;
            },
        },
    };

</script>
<template>
    <div class='login'
         @keyup.enter='login'
    >
        <NavBar no-nav />
        <ValidationObserver ref='form'
                            slim
        >
            <div id='login-form'
                 class='login__panel login-form'
            >
                <FormField
                    v-model='username'
                    :placeholder='$t("lbl-username")'
                    vid='username'
                    required
                    mode='lazy'
                    edit
                />

                <FormField
                    v-model='password'
                    type='password'
                    vid='password'
                    :placeholder='$t("lbl-password")'
                    required
                    mode='lazy'
                    edit
                />

                <button id='login-submit'
                        class='btn btn-primary'
                        @click='login()'
                >
                    {{ $t('btn-login') }}
                </button>

                <span style='margin-left:10px;'> </span>

                <a href='javascript:void(0)' v-if='!showReset' @click.prevent='toggleReset'>
                    {{ $t('a-reset-password') }}
                </a>
                <a href='javascript:void(0)' v-if='showReset' @click.prevent='toggleReset'>
                    {{ $t('a-hide-reset-password') }}
                </a>
            </div>
        </ValidationObserver>

        <div v-if='showReset' class='login__panel'>
            <reset-password />
        </div>

        <cb-footer class='login__footer' />
    </div>
</template>

<style lang="scss" scoped>
.login {
    background: url('~@/assets/login-background.svg') no-repeat center center;
    display: flex;
    background-size: cover;
    flex-direction: column;
    width: 100vw;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 50px);
}

.login__panel {
    max-width: 430px;
    width: 90%;
    margin: 0 0 40px;
}

.login-form {
    margin-top: auto;
    padding-top: 40px;
}

.login__footer {
    margin-top: auto;
    margin-bottom: 30px;
}
</style>
