<script>
import Fuse from 'fuse.js';
export default {
    props: {
        list: Array,
        value: String,
    },
    data: function () {
        return {
            active: false,
            searchResults: [],
            fuse: {},
            selectedValue: '',
            options: {
                threshold: 0.6,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
            },
        };
    },
    watch: {
        value: function (val, oldVal) {
            if (val !== this.selectedValue) {
                this.active = true;
            }
            this.searchResults = this.fuse.search(val);
        },
        list: function (val) {
            this.fuse = new Fuse(val, this.options);
        },
    },
    mounted () {
        this.fuse = new Fuse(this.list, this.options);

        document.querySelector('body').addEventListener('click', this.disableInput);
    },
    destroyed () {
        document.querySelector('body').removeEventListener('click', this.disableInput);
    },
    methods: {
        setValue (index) {
            this.active = false;
            this.selectedValue = this.$props.list[index];
            this.$emit('set', this.selectedValue);
        },
        disableInput () {
            this.active = false;
        },
    },
};
</script>

<template>
    <div class='friendly-input-fuzzy'
         @click.stop='() => {
             if (!$props.list.find(elem => {
                 return elem === $props.value;
             })) {
                 active = true
             }
         }'
    >
        <slot name='input'></slot>
        <ul class='friendly-input-fuzzy__results' v-show='active && $props.value.length > 1'>
            <li v-for='result in searchResults.slice(0, 5)'
                :key='result'
                @click.stop='setValue(result)'
                class='friendly-input-fuzzy__item'
            >
                {{ $props.list[result] }}
            </li>
            <li v-if='searchResults.length > 5'>
                ...
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
.friendly-input-fuzzy {
    position: relative;
}

.friendly-input-fuzzy__results {
    position: absolute;
    list-style: none;
    background: #F9F9FA;
    left: 0;
    right: 0;
    border-radius: 4px;
    z-index: 1000;
    font-size: 18px;
    padding: 5px;
    margin: 0;
}

.friendly-input-fuzzy__item {
    padding: 5px 0;
    cursor: pointer;
}
</style>
