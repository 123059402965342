<script>
import FormField from '@/components/FormField';
import { AsYouType } from 'libphonenumber-js';

export default {
    props: {
        fid: {
            type: String,
            default: null,
        },
        info: {
            type: String,
        },
        name: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        value: String,
    },
    components: {
        FormField,
    },
    computed: {
        phoneNumber: {
            get: function () {
                if (this.value && this.value !== '-') {
                    const asYouType = new AsYouType('BE');
                    return asYouType.input(this.value);
                } else {
                    return this.value;
                }
            },
            set: function (val) {
                const asYouType = new AsYouType('BE');
                asYouType.input(val);

                const phoneNumber = asYouType.getNumber();
                if (phoneNumber) {
                    this.$emit('input', phoneNumber.number);
                } else {
                    this.$emit('input', val);
                }
            },
        },
    },
};
</script>

<template>
    <FormField
        v-model='phoneNumber'
        type='phone'
        :fid='fid'
        :name='name'
        :placeholder='$t("+ _ _ _ _ _ _ _ _ _ _ _")'
        :info='$t("info-ccs-client-phone")'
        info-icon='fa fa-question-circle'
        :required='required'
        :edit='edit'
    />
</template>

<style scoped>
</style>
