<script>
    import vt from 'vue-types';

    export default {
        name: 'list-loader',
        props: {
            list: vt.object.isRequired,
        },
    };
</script>
<template>
    <div class='cb-list-loader' v-if='list.loading'>
        <i class='fa fa-circle-o-notch fa-spin'></i>
    </div>
</template>
