<script>
    import ListView from '../components/ListView.js';
    import EnterpriseNumber from '../components/EnterpriseNumber.vue';
    import ContentBox from '../components/ContentBox.vue';

    export default {
        name: 'rslr-fidu-search',
        mixins: [ListView],
        props: {
            currentReseller: {
                type: Object,
            },
        },
        components: {
            EnterpriseNumber,
            ContentBox,
        },
        data () {
            const data = this.listViewData({
                apiModel: 'bff/api/search/reseller/fiduciaries',
                defaultSortKey: 'name',
            });

            this.configureSelectFilter(data, 'activeFidus', [
                {
                    value: 'allfidus',
                    label: 'opt-allfidus',
                    filter: null,
                },
                {
                    value: 'archived',
                    label: 'opt-fidus-archived',
                    filter: {'state': 'archived'},
                },
                {
                    value: 'active',
                    label: 'opt-fidus-active',
                    filter: {'state': 'active'},
                },
            ]);

            return data;
        },
        mounted () {
            this.watchFilters();
            this.search();
        },
        methods: {
            dynamicSearchParams () {
                return { 'resellerIds': [this.currentReseller.id] };
            },
        },
    };
</script>

<template>
    <div>
        <div class='cb-fidu-title'>
            <div class='cb-fidu-actions'>
            </div>
            <h1 class='page-header'>
                {{ $t('h-fidus') }}
            </h1>
        </div>

        <content-box>
            <form @submit.prevent.stop class='form-inline clearfix cb-fidu-search'>
                <searchbox :list='list' />

                <selector :filter='true' :model='list.filters.activeFidus' />

                <div class='cb-search-uppernav'>
                    <pagination :list='list'
                                :pagination='pagination'
                                :ctrls='getControls()'
                                size='small'
                    />
                </div>
            </form>

            <div class='cb-bordered' :class='{"table-responsive": $store.state.gui === "mobile"}'>
                <table class='table cb-searchresults'>
                    <colgroup>
                        <collumn class='cb-col-md' :sorting='sorting' skey='name' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='enterprise_num' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='state' />
                        <col class='cb-col-md'>
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <span>{{ $t('th-fidu-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='name' />
                            </th>
                            <th>
                                <span>{{ $t('th-enterprise-number') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='enterprise_num' />
                            </th>
                            <th>
                                <span>{{ $t('th-status') }}</span>
                            </th>
                            <th>
                                <span>{{ $t('th-documents') }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='(record, i) in list.records' :key='i'>
                            <td>{{ record.name }}</td>
                            <td><enterprise-number :enterprise-number='record.enterprise_num' /></td>
                            <td>{{ record.state }}</td>
                            <td>
                                <template v-if='record.state !== "archived"'>
                                    <router-link
                                        class='btn btn-alt btn-xs'
                                        :to='{name:"rslr-coda-search-fidu", params:{ uid: (record.id || record.uuid)}}'
                                    >
                                        {{ $t('btn-codas') }}
                                    </router-link>
                                &nbsp;
                                    <router-link
                                        class='btn btn-alt btn-xs'
                                        :to='{name:"rslr-soda-search-fidu", params:{ uid: (record.id || record.uuid)}}'
                                    >
                                        {{ $t('btn-sodas') }}
                                    </router-link>
                                &nbsp;
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <listloader :list='list' />

            <div class='text-center' v-if='!list.loading'>
                <pagination :list='list'
                            :pagination='pagination'
                            :ctrls='getControls()'
                            scroll='scrollTop'
                            no-margin
                />
            </div>
        </content-box>
    </div>
</template>
