<script>
    import Api from '../api';
    import validate from '../validate.js';
    import passwords from '../passwords.js';
    import notify from '../notify.js';
    import router from '../router.js';
    import Footer from '../components/Footer.vue';
    import FormField from '../components/FormField';
    import NavBar from '@/components/NavBar.vue';

    export default {
        name: 'confirm-reset-password',
        components: {
            'cb-footer': Footer,
            FormField,
            NavBar,
        },
        data () {
            return {
                'password': '',
                'password2': '',
            };
        },
        computed: {
            token () {
                return this.$route.params.token;
            },
            uuid () {
                return this.$route.params.uuid;
            },
            matchedPasswords () {
                return this.password === this.password2;
            },
            passwordValidation () {
                var error = passwords.getPasswordValidation(this.password);
                return { error: error };
            },
        },
        methods: {
            resetPasswordConfirm () {
                if (!this.token || !this.uuid) { return; }

                this.$refs.form.validate()
                    .then(() => {
                        if (!this.matchedPasswords) {
                            this.$refs.form.setErrors({password2: this.$t('val-password-mismatch')});
                            return Promise.reject();
                        } else if (this.passwordValidation.error !== '') {
                            this.$refs.form.setErrors({password: this.$t(this.passwordValidation.error)});
                            return Promise.reject();
                        }
                    }).then(() => {
                        return Api.passwordResetConfirm(
                            this.uuid,
                            this.token,
                            this.password,
                            this.password2,
                        );
                    }).then(() => {
                        notify.success(this.$t('suc-confirm-reset-password'));
                        router.replace('/');
                    }).catch((err) => {
                        return validate.catchFieldErrors(err, this.$refs.form, {
                            'Invalid token for given user.': 'err-obsolete-reset-confirm-link',
                        });
                    }).catch((err) => {
                        return validate.notifyErrors(err);
                    });
            },
        },
    };
</script>
<template>
    <div class='container'>
        <NavBar :user='$store.state.user' />
        <section>
            <ValidationObserver
                ref='form'
                class='panel panel-default'
                tag='div'
            >
                <div class='panel-heading'>
                    <h3 class='panel-title'>
                        {{ $t('h-confirm-reset-password') }}
                    </h3>
                </div>
                <div class='panel-body'>
                    <form role='form' @submit.prevent='resetPasswordConfirm'>
                        <FormField
                            v-model='password'
                            type='password'
                            vid='password'
                            :name='$t("lbl-new-password")'
                            :placeholder='$t("lbl-new-password")'
                            :info='$t("val-password-strength-info")'
                            required
                            edit
                        />
                        <FormField
                            v-model='password2'
                            vid='password2'
                            type='password'
                            :name='$t("lbl-new-password2")'
                            :placeholder='$t("lbl-new-password2")'
                            required
                            edit
                        />
                        <button type='submit' class='btn btn-primary'>
                            {{ $t('btn-confirm-reset-password') }}
                        </button>
                    </form>
                </div>
            </ValidationObserver>
        </section>

        <cb-footer class='login__footer' />
    </div>
</template>
