<script>
    import utils from '../utils.js';
    import Modal from '../components/Modal.vue';
    import vt from 'vue-types';
    import { mapState } from 'vuex';

    export default {
        name: 'prismic-popup',
        props: {
            space: vt.string.isRequired,
        },
        components: {
            Modal,
        },
        data () {
            return {
                showPopup: true,
            };
        },
        computed: {
            popup () {
                if (!this.$store.state.popups.loaded) {
                    return null;
                } else {
                    let debug  = !!this.$store.state.flags.debug;
                    let popups = this.$store.state.popups.navigation;
                    let seen   = this.loadSeenPopups();
                    for (let popup of popups) {
                        if (!popup[this.space]) {
                            continue;
                        }
                        if (!debug && !popup.prod) {
                            continue;
                        }
                        if (!seen[popup.slug]) {
                            return popup;
                        }
                    }
                    return null;
                }
            },
            popupContent () {
                if (!this.popup) {
                    return {};
                }
                let documentId = this.popup[this.$store.state.i18n.locale];
                let doc = this.$store.state.popups.articles[documentId];
                if (!doc) {
                    console.error('Missing Prismic Document:', documentId);
                    return {};
                }

                return {
                    title: utils.prismicDomToText(doc.data['popup-title']),
                    body:  utils.prismicDomToHtml(this.$store, this.space, doc.data['popup-body']),
                };
            },
            ...mapState(['popups']),
        },
        mounted () {
            this.$store.dispatch('loadPrismicPopups');
        },
        methods: {
            close () {
                this.showPopup = false;
                if (this.popup) {
                    let email = this.$store.state.user.email;
                    let key   = 'prismic_popup_' + email;
                    let seen  = JSON.parse(localStorage[key] || '{}');
                    seen[this.popup.slug] = true;
                    localStorage[key] = JSON.stringify(seen);
                }
            },
            loadSeenPopups () {
                let email = this.$store.state.user.email;
                let key   = 'prismic_popup_' + email;
                let seen  = JSON.parse(localStorage[key] || '{}');
                return seen;
            },
        },
    };
</script>

<template>
    <Modal :show='showPopup' v-if='popup && popups.visible'>
        <div class='modal-header'>
            <button type='button' class='close' @click.prevent='close'>
                <span>&times;</span>
            </button>
            <h4 class='modal-title'>
                {{ popupContent.title }}
            </h4>
        </div>
        <div class='modal-body' v-html='popupContent.body'>
        </div>
        <div class='modal-footer'>
            <button type='button' class='btn btn-default' @click.prevent='close'>
                {{ $t('btn-ok') }}
            </button>
        </div>
    </Modal>
</template>
