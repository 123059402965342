<script>

    import ListView         from '../components/ListView.js';
    import Exporter         from '../components/Exporter.vue';
    import Bankaccount      from '../components/Bankaccount.vue';
    import Codastate        from '../components/Codastate.vue';
    import Clientlink       from '../components/Clientlink.vue';
    import ContentBox       from '../components/ContentBox.vue';
    import CustomTitle from '@/components/Title';

    export default {
        name: 'coda-search',
        mixins: [ListView],
        props: {
            currentFiduciary: {
                type: Object,
            },
        },
        components: {
            'exporter':      Exporter,
            'bankaccount':   Bankaccount,
            'codastate':     Codastate,
            'clientlink':    Clientlink,
            ContentBox,
            CustomTitle,
        },
        data () {
            var data = this.listViewData({
                apiModel: 'bff/api/search/fiduciary/coda-mandates',
                defaultSortKey: 'client__client_code',
                archival: {
                    showWarning: false,
                    confirm: { confirmed: false },
                    mandateId: null,
                },
                defaultFilters: {'client__state': 'active'},
            });

            this.configureSelectFilter(data, 'state', [
                {
                    value: 'allstates',
                    label: 'opt-mandate-all',
                    filter: null,
                }, {
                    value: 'pre-treatment',
                    label: 'lbl-coda-pre-treatment',
                    filter: {'state': 'pre-treatment'},
                }, {
                    value: 'prepared',
                    label: 'lbl-coda-prepared',
                    filter: {'state': 'prepared'},
                }, {
                    value: 'sent-client',
                    label: 'lbl-coda-sent-client',
                    filter: {'state': 'sent-client'},
                }, {
                    value: 'available-online',
                    label: 'lbl-coda-available-online',
                    filter: {'state': 'available-online'},
                }, {
                    value: 'signed',
                    label: 'lbl-coda-signed',
                    filter: {'state': 'signed'},
                }, {
                    value: 'bank-procedure',
                    label: 'lbl-coda-bank-procedure',
                    filter: {'state': 'bank-procedure'},
                }, {
                    value: 'active',
                    label: 'lbl-coda-active',
                    filter: {'state': 'active'},
                }, {
                    value: 'archived',
                    label: 'lbl-coda-archived',
                    filter: {'state': 'archived'},
                }, {
                    value: 'problem',
                    label: 'lbl-coda-problem',
                    filter: {'state': 'problem'},
                },
            ]);

            var _banks = [{
                value: 'allbanks',
                label: 'opt-banks-all',
                filter: null,
            }];

            for (var bank of this.$store.state.allBanks) {
                if (bank.isSupported) {
                    _banks.push({
                        value: bank.id,
                        label: bank.name,
                        filter: {'bank__bank_id': bank.id},
                    });
                }
            }

            this.configureSelectFilter(data, 'banks', _banks);

            return data;
        },
        watch: {
            'currentFiduciary.id': function () {
                this.search();
            },
        },
        mounted () {
            this.watchFilters();
            this.search();
        },
        methods: {
            dynamicSearchParams () {
                return { 'fiduciaryIds': [this.currentFiduciary.id] };
            },
        },
    };
</script>

<template>
    <div>
        <div class='cb-fidu-title'>
            <div class='cb-fidu-actions'>
                <div class='cb-action-label'>
                    {{ $t('lbl-tools') }}
                </div>
                <exporter role='fiduciary' model='coda-mandates' :fiduciary-id='currentFiduciary.id'>
                    <span>{{ $t('btn-export-codas') }}</span>
                </exporter>
                <exporter role='fiduciary' model='bank-accounts' :fiduciary-id='currentFiduciary.id'>
                    <span>{{ $t('btn-export-accounts') }}</span>
                </exporter>
            </div>
            <CustomTitle class='mt-12 mb-6'>
                {{ $t('h-coda-mandates') }}
            </CustomTitle>
        </div>

        <content-box>
            <form @submit.prevent.stop class='form-inline clearfix cb-fidu-search'>
                <searchbox :list='list' />

                <selector :filter='true' :model='list.filters.state' />
                <selector :filter='true' :model='list.filters.banks' />

                <div class='cb-search-uppernav'>
                    <pagination :list='list'
                                :pagination='pagination'
                                :ctrls='getControls()'
                                size='small'
                    />
                </div>
            </form>

            <div class='cb-bordered' :class='{"table-responsive": $store.state.gui === "mobile"}'>
                <table class='table cb-searchresults'>
                    <colgroup>
                        <collumn class='cb-col-lg' :sorting='sorting' skey='client__client_code' />
                        <collumn :sorting='sorting' skey='client__name' />
                        <col>
                        <collumn :sorting='sorting' skey='bank__name' />
                        <collumn :sorting='sorting' skey='state_modifed_at' />
                        <collumn :sorting='sorting' skey='state' />
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <span>{{ $t('th-client-code') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='client__client_code' />
                            </th>
                            <th>
                                <span>{{ $t('th-client-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='client__name' />
                            </th>
                            <th class='cb-col-lg'>
                                <span>{{ $t('th-accounts') }}</span>
                            </th>
                            <th>
                                <span>{{ $t('th-bank') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='bank__name' />
                            </th>
                            <th>
                                <span>{{ $t('th-state-date') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='state_modified_at' />
                            </th>
                            <th>
                                <span>{{ $t('th-state') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='state' />
                            </th>
                            <th>
                                <span>{{ $t('th-actions') }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='(record, index) in list.records' :key='index'>
                            <td>
                                <clientlink :client='record.client' document='coda' />
                            </td>
                            <td>{{ record.client.name }}</td>
                            <td>
                                <bankaccount v-for='account in record.bank_accounts' :key='account.iban' :account='account' />
                            </td>
                            <td>{{ record.bank.name }}</td>
                            <td>{{ record.state_modified_at | dateFormat }}</td>
                            <td>
                                <codastate :mandate='record' />
                            </td>
                            <td class='flex'>
                                <router-link class='btn btn-sm btn-default mr-3'
                                             :to='{name:"fidu-client-uid-coda", params:{
                                                 uid: record.client.id,
                                                 openResendCodaModal: true
                                             }}'
                                             :event='record.bank_accounts.some((ba) => ba.state === "active") ? "click" : ""'
                                             :disabled='!record.bank_accounts.some((ba) => ba.state === "active")'
                                >
                                    {{ $t('btn-resend-coda') }}
                                </router-link>
                                <router-link class='btn btn-sm btn-primary'
                                             :to='{name:"fidu-client-uid-coda", params:{
                                                 uid: record.client.id,
                                                 selectedCodaId: record.id
                                             }}'
                                >
                                    {{ $t('btn-manage') }}
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <listloader :list='list' />

            <div class='text-center' v-if='!list.loading'>
                <pagination :list='list'
                            :pagination='pagination'
                            :ctrls='getControls()'
                            scroll='scrollTop'
                            no-margin
                />
            </div>
        </content-box>
    </div>
</template>

<style lang="scss" scoped>
.no-row-content {
    height: 18px;
    display: flex;
    align-items: center;

    &:after {
        content: ' ';
        display: block;
        width: 15px;
        height: 1px;
        background: lighten($color-grey-medium, 20%);
    }
}
</style>
