<script>
    import { gql } from '@apollo/client/core';
    import FormField from '@/components/FormField';
    import Warning from './components/Warning.vue';
    import ImpactedCodaMandates from './components/ImpactedCodaMandates';
    import EnterpriseNumInput from '@/components/EnterpriseNumInput';
    import RequiredNotice from '@/components/RequiredNotice.vue';
    import ContentBox from '@/components/ContentBox.vue';
    import EmailPill from '@/components/EmailPill.vue';
    import InsightInfo from '@/components/InsightInfo.vue';
    import Loader from '@/loader';
    import notify from '@/notify';
    import validate from '@/validate';

    export default {
        name: 'client-info',
        props: {
            clientV2: {type: Object, required: true},
            accountant: {type: Object, required: true},
            currentFiduciary: {type: Object, required: true},
        },
        components: {
            FormField,
            Warning,
            ImpactedCodaMandates,
            EnterpriseNumInput,
            RequiredNotice,
            ContentBox,
            EmailPill,
            InsightInfo,
        },
        data () {
            return {
                edit: false,
                saving: false,
                tempClientInfos: null,
                impactedCodaMandates: [],
            };
        },
        computed: {
            isFiduExactOnline () {
                return this.currentFiduciary.isExactOnline;
            },
            isFiduciaryClient () {
                return this.clientV2.enterpriseNumber === this.accountant.enterpriseNumber;
            },
            problems () {
                return this.$store.state.currentClientProblems;
            },
            hasExactProblem () {
                return this.problems.filter(p => p.type === 'exactOnlineDeliveryProblem').length > 0;
            },
            hasUnreachableContactEmailProblem () {
                return this.problems.filter(p => p.type === 'contactEmailUndeliverable').length > 0;
            },
        },
        watch: {
            clientV2: function () {
                this.setTempClient();
            },
        },
        mounted () {
            this.setTempClient();
        },
        methods: {
            setTempClient () {
                this.tempClientInfos = {
                    enterpriseName: this.clientV2.enterpriseName,
                    language: this.clientV2.language,
                    address: this.clientV2.address,
                    address2: this.clientV2.address2,
                    zip: this.clientV2.zip,
                    city: this.clientV2.city,
                    representativeName: this.clientV2.representativeName,
                    representativeFunction: this.clientV2.representativeFunction,
                    contactEmail: this.clientV2.contactEmail,
                    exactEmail: this.clientV2.exactEmail,
                    clientCode: this.clientV2.clientCode,
                };
            },
            handleEdit () {
                this.edit = true;
            },
            handleCancel () {
                this.setTempClient();
                this.edit = false;
            },
            async handleImpactedCodaMandates () {
                const { data } = await this.$apollo.query({
                    query: gql`query getCodaMandate($clientId: String) {
                            codaMandates(
                                clientId:$clientId,
                                excludeMandatesWithoutBankAccounts:true,
                                states: [
                                    "available-online",
                                    "prepared",
                                    "sent-client",
                                    "problem",
                                ]
                            ) {
                                id
                                clientId
                                fiduciaryId
                                bankId
                                bank {
                                    id
                                    name
                                    slug
                                    abbreviation
                                    isSupported
                                    isTwikeySupported
                                    isSignhereSupported
                                    disableSendPdfMandate
                                    isCaroSupported
                                    isStopCodaSupported
                                    regexSavings
                                }
                                state
                                reasonCode
                                stateDate
                                bankAccounts{
                                    id
                                    type
                                    bankId
                                    state
                                    stateDate
                                    flowState
                                    bankMandateState
                                    iban
                                }
                                twikeyUrl
                                hasTeletransmissionPdf
                                signHerePackage {
                                    packageStatus
                                    packageSignUrl
                                    packageRejectionReason
                                }
                            }
                        }`,
                    variables: {
                        clientId: this.clientV2.id,
                    },
                });

                this.impactedCodaMandates = data.codaMandates;

                if (this.impactedCodaMandates.length > 0) {
                    let showImpactedPopup = false;
                    const sensibleFields = [
                        'enterpriseName',
                        'enterpriseNumber',
                        'address',
                        'address2',
                        'zip',
                        'city',
                        'contactEmail',
                        'language',
                        'representativeName',
                        'representativeFunction',
                    ];

                    for (let field of sensibleFields) {
                        if (this.clientV2[field] !== this.tempClientInfos[field]) {
                            showImpactedPopup = true;
                            break;
                        }
                    }

                    this.$refs.impactedCodaMandates.show = showImpactedPopup;
                }
            },

            async updateClientInfos () {
                if (this.saving) { return; }
                const valid = await this.$refs.clientForm.validate();

                if (valid) {
                    Loader.start();
                    try {
                        const { data } = await this.$apollo.mutate({
                            mutation: gql`mutation updateClient($clientId: String!, $input: FiduciaryClientUpdateInput!) {
                            updateClient(clientId: $clientId, input: $input) {
                                errors { code, detail, source { pointer } }
                            }
                        }`,
                            variables: {
                                clientId: this.clientV2.id,
                                input: this.tempClientInfos,
                            },
                        });

                        if (data.updateClient.errors) {
                            validate.reportGQLFieldErrors(data.updateClient.errors, this.$refs.clientForm, {
                                'Client code already used for this fiduciary.': 'err-client-code-not-unique',
                                'Already in use by a client of another fiduciary.': 'err-exact-email-not-unique',
                            });
                        } else {
                            await this.handleImpactedCodaMandates();
                            this.$emit('clientupdated');
                            notify.success(this.$t('suc-client-modified'));
                            this.edit = false;
                        }

                        this.saving = false;
                        Loader.stop();
                    } catch (error) {
                        notify.error(this.$t('err-unknown'));
                        this.saving = false;
                        Loader.stop();
                    }
                }
            },
        },
    };
</script>
<template>
    <ValidationObserver ref='clientForm'
                        tag='div'
                        class='client-subppage'
                        id='clientInfo'
    >
        <ImpactedCodaMandates
            ref='impactedCodaMandates'
            :current-fiduciary='currentFiduciary'
            :mandates='impactedCodaMandates'
            @refreshCodaMandates='handleImpactedCodaMandates'
        />
        <content-box :title='$t("h-client-info")'>
            <template #actions>
                <div v-if='!edit' class='client-subppage-header__actions__edit'>
                    <div
                        class='client-subppage-header__actions__edit__notice'
                        v-if='isFiduciaryClient'
                    >
                        {{ $t('p-client-edit-disabled-notice') }}
                        <div class='input-toggle__info cb-legend'>
                            {{ $t('legend-client-edit-disabled-notice') }}
                            <div class='cb-legend-body input-toggle__info__body'>
                                {{ $t('p-fidu-client-notice') }}
                            </div>
                        </div>
                    </div>
                    <button class='btn btn-primary'
                            @click='handleEdit'
                            :disabled='isFiduciaryClient'
                            id='edit'
                            data-dd-action-name='clientEdit'
                    >
                        {{ $t('btn-edit') }}
                    </button>
                </div>
                <template v-else>
                    <button class='btn btn-primary'
                            @click='handleCancel'
                    >
                        {{ $t('btn-cancel') }}
                    </button>
                    &nbsp;
                    <button class='btn btn-primary'
                            @click='updateClientInfos'
                            :disabled='saving'
                            id='save'
                    >
                        {{ $t('btn-save') }}
                    </button>
                </template>
            </template>
            <div class='row mb10' v-if='tempClientInfos'>
                <div class='col-md-4'>
                    <div class='form-group'>
                        <FormField
                            v-model='tempClientInfos.clientCode'
                            vid='clientCode'
                            ref='clientCode'
                            :name='$t("lbl-client-code")'
                            :placeholder='$t("lbl-client-code")'
                            :edit='edit'
                            required
                            :max='50'
                            id='clientCode'
                        />
                    </div>
                    <div class='form-group'>
                        <InsightInfo
                            :has-problem='hasUnreachableContactEmailProblem'
                        >
                            <FormField
                                v-model='tempClientInfos.contactEmail'
                                type='email'
                                vid='contactEmail'
                                ref='contactEmail'
                                :name='$t("lbl-client-email")'
                                :placeholder='$t("lbl-email")'
                                :edit='edit'
                                required
                                :max='254'
                                id='contactEmail'
                            >
                                <template #customValue>
                                    <EmailPill
                                        :email='tempClientInfos.contactEmail'
                                        :client-id='clientV2.id'
                                        used-copys='contactEmail'
                                        class='w-full'
                                    />
                                </template>
                            </FormField>
                        </InsightInfo>
                    </div>
                    <div class='form-group'>
                        <FormField
                            v-model='tempClientInfos.language'
                            vid='language'
                            type='select'
                            :name='$t("lbl-language")'
                            :placeholder='$t("lbl-language-select")'
                            :edit='edit'
                            required
                            id='language'
                        >
                            <option value='nl'>
                                {{ $t('lbl-dutch') }}
                            </option>
                            <option value='fr'>
                                {{ $t('lbl-french') }}
                            </option>
                            <option value='en'>
                                {{ $t('lbl-english') }}
                            </option>
                        </FormField>
                    </div>
                    <div class='form-group' v-if='isFiduExactOnline || clientV2.exactEmail'>
                        <InsightInfo
                            :has-problem='hasExactProblem'
                        >
                            <FormField
                                v-model='tempClientInfos.exactEmail'
                                vid='exactEmail'
                                ref='exactEmail'
                                type='email'
                                :name='$t("lbl-exact-email")'
                                :placeholder='$t("lbl-exact-email")'
                                :disabled='!isFiduExactOnline'
                                :edit='edit'
                                nullable
                                :max='254'
                                id='exactEmail'
                            />
                        </InsightInfo>
                    </div>
                </div>
                <div class='col-md-4'>
                    <div class='form-group'>
                        <FormField
                            v-model='tempClientInfos.enterpriseName'
                            ref='enterpriseName'
                            vid='enterpriseName'
                            :name='$t("lbl-enterprise-name")'
                            :placeholder='$t("lbl-enterprise-name")'
                            required
                            :max='160'
                            :edit='clientV2.enterpriseNumber && edit'
                            id='enterpriseName'
                        />
                    </div>
                    <div class='form-group'>
                        <EnterpriseNumInput
                            v-model='clientV2.enterpriseNumber'
                            vid='enterpriseNumber'
                            field='enterpriseNumber'
                            disabled
                            id='enterpriseNumber'
                        />
                        <FormField
                            v-model='clientV2.hasBelgianVatNumber'
                            vid='hasBelgianVatNumber'
                            field='hasBelgianVatNumber'
                            :name='$t("lbl-has-belgian-vat-number")'
                            type='boolean'
                            disabled
                            id='hasBelgianVatNumber'
                        />
                    </div>
                    <div class='form-group'>
                        <FormField
                            v-model='tempClientInfos.representativeName'
                            vid='representativeName'
                            :name='$t("lbl-representative-name")'
                            :placeholder='$t("placeholder-representative-name")'
                            :max='160'
                            required
                            :edit='edit'
                            id='representativeName'
                            type='composedName'
                            clean
                            :info='$t("p-tooltip-representative-name")'
                        />
                    </div>
                    <div class='form-group'>
                        <FormField
                            v-model='tempClientInfos.representativeFunction'
                            vid='representativeFunction'
                            :name='$t("lbl-representative-function")'
                            :placeholder='$t("lbl-representative-function")'
                            required
                            :max='128'
                            :edit='edit'
                            id='representativeFunction'
                        />
                    </div>
                </div>
                <div class='col-md-4'>
                    <div class='form-group'>
                        <FormField
                            v-model='tempClientInfos.address'
                            vid='address'
                            :name='$t("lbl-address")'
                            :placeholder='$t("lbl-address-line-1")'
                            required
                            :max='100'
                            :edit='edit'
                            id='address'
                        />
                    </div>
                    <div class='form-group'>
                        <FormField
                            v-model='tempClientInfos.address2'
                            vid='address2'
                            :max='100'
                            :placeholder='$t("lbl-address-line-2")'
                            :edit='edit'
                            id='address2'
                        />
                    </div>
                    <div class='form-group'>
                        <FormField
                            v-model='tempClientInfos.zip'
                            vid='zip'
                            :name='$t("lbl-zip")'
                            :placeholder='$t("lbl-zip")'
                            required
                            :min='4'
                            :max='20'
                            :edit='edit'
                            id='zip'
                        />
                    </div>
                    <div class='form-group'>
                        <FormField
                            v-model='tempClientInfos.city'
                            vid='city'
                            :name='$t("lbl-city")'
                            :placeholder='$t("lbl-city")'
                            required
                            :max='50'
                            :edit='edit'
                            id='city'
                        />
                    </div>
                </div>
            </div>

            <div>
                <!-- email warning when delivery mode is email -->
                <Warning v-if='$refs.contactEmail &&
                    $refs.contactEmail.hasFocus &&
                    currentFiduciary.sendCodaAndSodaByMail &&
                    clientV2.sendCodaAndSodaByMail'
                >
                    <template #header>
                        {{ $t('h-warn-email-changes') }}
                    </template>
                    {{ $t('warn-email-accurate') }} <br><br>
                    {{ $t('warn-email-accurate-2') }}
                </Warning>

                <!-- exact email warning -->
                <Warning v-if='$refs.exactEmail && $refs.exactEmail.hasFocus && !isFiduExactOnline'>
                    <template #header>
                        {{ $t('h-exact-email-warning') }}
                    </template>
                    {{ $t('p-exact-email-warning') }}
                </Warning>

                <!-- client code warning -->
                <Warning v-if='$refs.clientCode && $refs.clientCode.hasFocus' id='clientCodeWarning'>
                    <template #header>
                        {{ $t('h-client-code-warning') }}
                    </template>
                    {{ $t('p-client-code-warning') }}
                </Warning>

                <!-- enterprise name warning -->
                <Warning v-if='$refs.enterpriseName && $refs.enterpriseName.hasFocus' id='enterpriseNameWarning'>
                    <template #header>
                        {{ $t('h-enterprise-name-warning') }}
                    </template>
                    {{ $t('p-enterprise-name-warning') }}
                </Warning>
            </div>

            <RequiredNotice class='mt-3' v-if='edit' />
        </content-box>
    </ValidationObserver>
</template>

<style lang="scss" scoped>
.client-subppage {
    min-height: calc(100vh - 450px);
}

.client-subppage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 20px;

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }
}

.client-subppage-header .btn+.btn {
    @apply ml-3;
}

.client-page__settings {
    margin-top: 20px;
}

.client-subppage-subtitle {
    margin: 40px 0 20px 0;
}

.client-subppage-header__actions {
    @apply flex items-center ml-auto;
}
.client-subppage-header__actions__edit {
    display: flex;
    align-items: center;
    color: $warning-color;

    .cb-legend {
        opacity: 1;
        text-decoration: underline;
    }

    .cb-legend-body {
        color: $color-grey-dark;
    }
}

.client-subppage-header__actions__edit__notice {
    margin-right: 10px;
}
</style>
