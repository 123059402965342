import Vue from 'vue';
import utils from './utils.js';

function dateFormat (value) {
    var date = new Date(value);
    return date.getFullYear() + '-' +
           utils.lzpad(2, date.getMonth() + 1) + '-' +
           utils.lzpad(2, date.getDate());
}

Vue.filter('dateFormat', dateFormat);

export default {
    'dateFormat': dateFormat,
};
