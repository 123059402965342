<script>
export default {
    name: 'list-items',
};
</script>

<template>
    <td>
        <slot></slot>
    </td>
</template>
