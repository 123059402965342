<script>
import clickOutside from '@/directives/click-outside';

export default {
    name: 'dropdown-v2',
    props: {
        icon: {
            type: String,
            default: 'chevron-down',
        },
        name: String,
        defaultValue: String,
        value: String || Array,
        nullable: {
            type: Boolean,
            default: false,
        },
        direction: {
            type: String,
            default: 'left',
        },
        small: {
            type: Boolean,
            default: false,
        },
        semiTransparent: {
            type: Boolean,
            default: false,
        },
    },
    directives: {
        clickOutside: clickOutside,
    },
    data () {
        return {
            menuOpened: false,
            selectedHtml: false,
        };
    },
    computed: {
        options () {
            return this.$slots.default;
        },
        selected: {
            get: function () {
                return this.value;
            },
            set: function (newValue) {
                this.updateSelectedHtml(newValue);
                this.$emit('input', newValue);
                this.close();
            },
        },
    },
    watch: {
        '$store.state.i18n.locale': function () {
            this.updateSelectedHtml(this.selected);
        },
        value: function () {
            this.updateSelectedHtml(this.selected);
        },
    },
    created () {
        this.$on('activate', this.change);
    },
    methods: {
        updateSelectedHtml (value) {
            this.options.map(e => {
                if (e.componentOptions && e.componentOptions.propsData.id === value) {
                    this.$nextTick(() => {
                        this.selectedHtml = e.elm.outerHTML;
                    });
                }
            });
        },
        toggle () {
            this.menuOpened = !this.menuOpened;
        },
        close () {
            this.menuOpened = false;
        },
        change (selected) {
            this.selected = selected;
            if (this.selected !== selected) {
                this.$emit('changed');
            }
        },
        reset () {
            this.change(this.defaultValue ? this.defaultValue : null);
        },
    },
};
</script>

<template>
    <div class='dropdown-v2' v-click-outside='close'>
        <div class='dropdown-v2-button' :class='{ "dropdown-v2-button-small": small, "semi-transparent": semiTransparent }' @click='toggle'>
            <i class='fa mr-3 text-sm' :class='`fa-${icon}`'></i>
            <span v-if='name'>{{ name }}</span>
            <span class='flex items-center' v-if='selected'>
                <span class='separator' :class='{ "semi-transparent": semiTransparent }' v-if='name'>|</span>
                <span v-html='selectedHtml' class='ml-2'></span>
                <div v-if='nullable' class='ml-2 text-grey-300 cursor-pointer hover:text-blue-300' @click='reset'><i class='fa fa-times'></i></div>
            </span>
        </div>
        <div class='dropdown-v2-menu' :class='[{"hidden": !menuOpened}, direction ]'>
            <slot></slot>
        </div>
    </div>
</template>

<style lang="postcss">
.dropdown-v2 {
    position: relative;
}

.dropdown-v2-button {
    @apply border border-solid border-grey-50 bg-grey-50 rounded px-5 text-grey-700 h-12 flex items-center justify-between whitespace-nowrap cursor-pointer;
    overflow: hidden;
}

.dropdown-v2-button-small {
    @apply px-3 h-9;
}

.dropdown-v2-button.semi-transparent {
    @apply text-white;
    background-color: rgb(255 255 255 / .1);
    border-color: rgb(255 255 255 / .0);
}

.dropdown-v2-menu {
    @apply top-12 z-10;
    max-height: 200px;
    overflow-y: scroll;
}

.dropdown-v2-menu.right {
    @apply rounded px-5 py-3 shadow-lg absolute right-0 bg-white;
}

.dropdown-v2-menu.left {
    @apply rounded px-5 py-3 shadow-lg absolute left-0 bg-white;
}

.dropdown-v2-item {
    @apply py-2 cursor-pointer;
}

.separator {
    @apply ml-2 text-grey-300;
}

.separator.semi-transparent {
    color: rgb(255 255 255 / .3);
}
</style>
