<script>
    import vt from 'vue-types';

    export default {
        name: 'client-enterprise-number',
        props: {
            enterpriseNumber: vt.string,
        },
        computed: {
            valid () {
                return !!this.enterpriseNumber;
            },
        },
    };
</script>

<template>
    <span>
        <span class='cb-state-label'>
            <template v-if='enterpriseNumber'> {{ this.enterpriseNumber }} </template>
            <template v-else> - </template>
        </span>
    </span>
</template>
