<script>
    import vt from 'vue-types';
    import utils from '../utils.js';

    export default {
        name: 'exact-email',
        props: {
            email: vt.string,
        },
        computed: {
            valid () {
                if (this.email) {
                    return utils.validateEmail(this.email);
                } else {
                    return false;
                }
            },
        },
    };
</script>

<template>
    <span>
        <span class='cb-state-label' :class='{"warning-color":!valid}'>
            <template v-if='email'> {{ email }} </template>
            <template v-else> {{ $t('lbl-missing-data') }} </template>
        </span>
    </span>
</template>
