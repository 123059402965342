<template>
    <div>
        <NavBar
            :user='$store.state.user'
        />

        <div class='cb-sidebar'>
            <ul class='nav-sidebar'>
                <li :class='{"active": $route.name === "organization-settings-team-members"}'>
                    <router-link to='/organization/settings' class='inline-block pl-6 mt-6 py-3 w-full active:no-underline font-bold'>
                        <i class='fa fa-users'></i>{{ $t('nav-settings-team-members') }}
                    </router-link>
                </li>
            </ul>
        </div>

        <div class='cb-main min-h-screen'>
            <div class='flex flex-col justify-between min-h-screen'>
                <router-view />
                <Footer />
            </div>
        </div>
    </div>
</template>

<script>
    import NavBar from '@/components/NavBar.vue';
    import Footer from '@/components/FancyFooter.vue';

    export default {
        name: 'org-settings',
        components: {
            NavBar,
            Footer,
        },
    };
  </script>
