<script>
    import Api from '../api';
    import notify from '../notify.js';
    import validate from '../validate.js';
    import FormField from '../components/FormField';

    export default {
        name: 'reset-password',
        components: {
            FormField,
        },
        data () {
            return {
                'email': '',
                'isSubmitButtonDisabled': false,
            };
        },
        computed: {
            isSubmitDisabled () { return !this.email || this.isSubmitButtonDisabled; },
        },
        watch: {
            email (oldValue, newValue) {
                if (oldValue !== newValue) {
                    this.enableSubmitButton();
                }
            },
        },
        methods: {
            async resetPassword () {
                const valid = await this.$refs.form.validate();

                if (valid && !this.isSubmitDisabled) {
                    Api.passwordReset(this.email)
                        .then((res) => {
                            notify.success(this.$t('suc-reset-password'));
                            this.isSubmitButtonDisabled = true;
                        }).catch((err) => {
                            return validate.catchFieldErrors(err, this.errors, this.fields);
                        }).catch((err) => {
                            return validate.notifyErrors(err);
                        });
                }
            },
            enableSubmitButton () {
                this.isSubmitButtonDisabled = false;
            },
        },
    };
</script>

<template>
    <div class='panel panel-default'>
        <div class='panel-heading'>
            <h3 class='panel-title'>
                {{ $t('h-reset-password') }}
            </h3>
        </div>
        <div class='panel-body'>
            <ValidationObserver ref='form'
                                tag='form'
                                @submit.prevent='resetPassword'
                                id='resetPasswordForm'
            >
                <FormField
                    v-model='email'
                    type='email'
                    vid='email'
                    :name='$t("lbl-email")'
                    :placeholder='$t("lbl-email")'
                    required
                    edit
                    id='resetPasswordEmail'
                />
                <button id='resetPasswordSubmitButton'
                        class='btn btn-warning'
                        type='submit'
                        :class='{ "disabled": isSubmitDisabled }'
                        :disabled='isSubmitDisabled'
                >
                    {{ $t('btn-reset-password') }}
                </button>
            </ValidationObserver>
        </div>
    </div>
</template>
