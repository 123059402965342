<script>
    import { gql } from '@apollo/client/core';
    import ClientPlatformTransferRequestPopup from './ClientPlatformTransferRequestPopup';
    import utils from '../utils.js';
    import { mapState } from 'vuex';
    import Loader from '@/loader';
    import validate from '@/validate';
    import Problems from '@/fiduciary/modules/Problems';
    import ArchiveClientPopup from '@/fiduciary/components/ArchiveClientPopup';
    import Dropdown from '@/components/Dropdown';
    import NotificationBadge from '@/components/NotificationBadge.vue';
    import notify from '@/notify.js';
    import i18n from '@/i18n.js';
    import Popup from '@/clientcomponents/Popup.vue';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import { datadogRum } from '@datadog/browser-rum';

    export default {
        name: 'fidu-client',
        props: {
            routeName: {type: String, required: true},
            clientId: {type: String, required: true},
            accountant: {type: Object, required: true},
            currentFiduciary: {type: Object, required: true},
        },
        components: {
            ClientPlatformTransferRequestPopup,
            Problems,
            Dropdown,
            ArchiveClientPopup,
            NotificationBadge,
            Popup,
            FriendlyButton,
        },
        data () {
            return {
                saving: false,
                showArchiveClientPopup: false,
                clientV2: null,
                wrongPlatformPopupShown: false,
                temporaryPlatformName: null,
            };
        },
        computed: {
            hasRelatedFiduciaries () {
                return this.accountant.fiduciaries.length > 1;
            },
            relatedFiduciaries () {
                return this.accountant.fiduciaries.filter((fidu) => {
                    return fidu.id !== this.currentFiduciary.id;
                });
            },
            requestTransferUrl () {
                return `/fiduciary/client/${this.clientV2.id}/request-transfer/`;
            },
            showCaro () {
                return !this.currentFiduciary.isEnterprise;
            },
            showVoila () {
                // do not show voila 2.0 for fidu enterprise as it is currently not supported
                return !this.currentFiduciary.isEnterprise;
            },
            isRepresentativeNameInvalid () {
                return !validate.isComposedName(this.clientV2.representativeName);
            },
            routeToProblemDomain () {
                return {
                    'fidu-client-uid-info': 'clientInfo',
                    'fidu-client-uid-coda': 'coda',
                    'fidu-client-uid-soda': 'soda',
                    'fidu-client-uid-voila': 'voila',
                    'fidu-client-uid-credit-card-statement': 'caro',
                }[this.routeName];
            },
            clientInfoProblems () {
                return this.currentClientProblems.filter(p => [
                    'exactOnlineDeliveryProblem',
                    'contactEmailUndeliverable',

                ].includes(p.type));
            },
            codaProblems () {
                return this.currentClientProblems.filter(p => [
                    'kbcCbcIncompleteBankProcedure',
                ].includes(p.type));
            },
            ...mapState(['currentClientProblems']),
        },
        watch: {
            clientId () {
                this.loadClientV2(this.clientId);
            },
        },
        mounted () {
            utils.scrollTop();
        },
        created: function () {
            this.loadClientV2(this.clientId);
        },
        methods: {
            resetSpecialErrors () {
                this.specialErrors.uniqueClient = false;
                this.specialErrors.uniqueClientPlatform = false;
            },
            handlePlatformTransferRequestClick () {
                this.$refs.platformTransferRequest.open();
            },
            async loadClientV2 (clientId) {
                Loader.start();
                try {
                    const { data } = await this.$apollo.query({
                        query: gql`query fiduciaryClient($clientId: String!) {
                                fiduciaryClient(clientId:$clientId) {
                                    id
                                    fiduciaryId
                                    enterpriseName
                                    enterpriseNumber
                                    hasBelgianVatNumber
                                    language
                                    address
                                    address2
                                    zip
                                    city
                                    representativeName
                                    representativeFunction
                                    contactEmail
                                    exactEmail
                                    clientCode
                                    sendCodaAndSodaByMail
                                    state
                                }
                            }`,
                        variables: { clientId },
                    });

                    Loader.stop();

                    await this.checkIfWrongPlatform(data.fiduciaryClient);

                    if (data.fiduciaryClient.state === 'archived') {
                        notify.error(i18n.t('err-client-not-found'));
                        this.$router.push('/fiduciary/clients/');
                        return;
                    }
                    this.clientV2 = data.fiduciaryClient;
                } catch (error) {
                    Loader.stop();

                    if (error && error.graphQLErrors) {
                        if (error.graphQLErrors.filter(e => e.extensions && e.extensions.code === 'FORBIDDEN')[0]) {
                            notify.error(i18n.t('err-client-not-found'));
                            this.$router.push('/fiduciary/clients/');
                        } else if (error.graphQLErrors.filter(e => e.extensions && e.extensions.code === 'BAD_USER_INPUT')[0]) {
                            notify.error(i18n.t('err-client-not-found'));
                            this.$router.push('/fiduciary/clients/');
                        }
                    }
                }
            },
            async reloadClientV2 () {
                await this.loadClientV2(this.clientV2.id);
            },
            problemsLoaded (problems) {
                this.$store.commit('setCurrentClientProblems', problems);
            },
            openArchiveClientPopup () {
                this.showArchiveClientPopup = true;
            },
            closeArchiveClientPopup () {
                this.showArchiveClientPopup = false;
            },
            showWrongPlatformPopup () {
                this.wrongPlatformPopupShown = true;
            },
            closeWrongPlatformPopup () {
                this.$emit('wrong-platform-popup-closed');
                this.wrongPlatformPopupShown = false;
            },
            doSwitchPlatform () {
                this.wrongPlatformPopupShown = false;
                this.$emit('wrong-platform-popup-switch');
            },
            async checkIfWrongPlatform (loadedClient) {
                return new Promise((resolve, reject) => {
                    if (loadedClient.fiduciaryId !== this.currentFiduciary.id) {
                        this.temporaryPlatformName = this.accountant.fiduciaries.find(fidu => loadedClient.fiduciaryId === fidu.id).name;
                        this.showWrongPlatformPopup();
                        this.$once('wrong-platform-popup-switch', () => {
                            this.$emit('selectCurrentFiduciary', loadedClient.fiduciaryId);
                            resolve();
                            datadogRum.addUserAction('changePlatformFromClient');
                            notify.info(i18n.t('lbl-platform-switched-to', { platform: this.temporaryPlatformName }));
                        });
                        this.$once('wrong-platform-popup-closed', () => {
                            this.$router.push('/fiduciary/clients/');
                            reject();
                        });
                    } else {
                        resolve();
                    }
                });
            },
        },
    };
</script>
<template>
    <section class='cb-form-container'>
        <div v-if='saving' class='cb-form-overlay'></div>

        <header class='header' v-if='clientV2'>
            <h1 class='m-0'>
                {{ clientV2.enterpriseName || clientV2.clientCode }}
            </h1>

            <div
                v-if='!clientV2.contactEmail'
                class='text-white mb-3 mt-5 px-4 py-2 bg-orange-300 inline-block rounded-md flex items-center'
                id='missing-contact-email-warning'
            >
                <i class='fa fa-exclamation-triangle mr-3'></i>
                {{ $t('lbl-missing-email-warning') }}
            </div>
            <div
                v-if='isRepresentativeNameInvalid'
                class='text-white mb-3 mt-5 px-4 py-2 bg-orange-300 inline-block rounded-md flex items-center'
                id='invalid-representative-name-warning'
            >
                <i class='fa fa-exclamation-triangle mr-3'></i>
                {{ $t('lbl-no-composed-name-warning') }}
            </div>

            <div class='client-nav mt-6'>
                <ul class='nav nav-pills'>
                    <li role='presentation'
                        :class='{ active: routeName === "fidu-client-uid-info" }'
                        class='relative'
                    >
                        <NotificationBadge :value='clientInfoProblems ? clientInfoProblems.length : 0' />
                        <router-link :to='{ name: "fidu-client-uid-info", params: { useCache: true }}'>
                            {{ $t('nav-client') }}
                        </router-link>
                    </li>
                    <li role='presentation'
                        :class='{ active: routeName === "fidu-client-uid-coda" }'
                        class='relative'
                    >
                        <NotificationBadge :value='codaProblems ? codaProblems.length : 0' />
                        <router-link :to='{ name: "fidu-client-uid-coda", params: { useCache: true }}'>
                            CODA
                        </router-link>
                    </li>
                    <li role='presentation'
                        :class='{ active: routeName === "fidu-client-uid-soda" }'
                    >
                        <router-link :to='{ name: "fidu-client-uid-soda", params: { useCache: true }}'>
                            SODA
                        </router-link>
                    </li>
                    <li role='presentation'
                        :class='{ active: routeName === "fidu-client-uid-voila" }'
                        v-if='showVoila'
                    >
                        <router-link :to='{ name: "fidu-client-uid-voila", params: { useCache: true }}'>
                            {{ $t('nav-purchases') }}
                        </router-link>
                    </li>
                    <li role='presentation'
                        :class='{ active: routeName === "fidu-client-uid-credit-card-statement" }'
                        v-if='showCaro'
                    >
                        <router-link :to='{ name: "fidu-client-uid-credit-card-statement", params: { useCache: true }}'>
                            {{ $t('nav-credit-card-statements') }}
                        </router-link>
                    </li>
                </ul>
                <div class='flex'>
                    <dropdown
                        tag='div'
                        hoverable
                        large
                        id='client-actions'
                    >
                        <template slot='toggle'>
                            <FriendlyButton
                                :label='$t("btn-client-actions")'
                                extra-small
                                symbol='caret-down'
                                secondary
                                square
                                no-margin
                                propagate-click
                            />
                        </template>
                        <template slot='list'>
                            <li
                                @click.stop='openArchiveClientPopup'
                                id='client-actions-archive'
                                data-dd-action-name='clientDetailPageOpenClientArchivePopup'
                            >
                                {{ $t('btn-action-archive-client') }}
                            </li>
                            <li
                                v-if='hasRelatedFiduciaries'
                                @click='handlePlatformTransferRequestClick'
                            >
                                {{ $t('btn-request-platform-transfer') }}
                            </li>
                        </template>
                    </dropdown>
                </div>
            </div>
        </header>

        <ArchiveClientPopup
            v-if='clientV2'
            :client-id='clientId'
            :show='showArchiveClientPopup'
            @close='closeArchiveClientPopup'
        />

        <Problems
            v-if='clientV2'
            class='mb-6'
            :client-id='clientV2.id'
            :current-fiduciary='currentFiduciary'
            :domain='routeToProblemDomain'
            @loaded='problemsLoaded'
            problems-only
            no-controls
            hide-if-empty
            :loading-amount='1000'
        />

        <router-view
            v-if='clientV2'
            :client-v2='clientV2'
            :accountant='accountant'
            :current-fiduciary='currentFiduciary'
            @clientupdated='reloadClientV2'
        />

        <ClientPlatformTransferRequestPopup
            v-if='clientV2'
            :client-v2='clientV2'
            :related-fiduciaries='relatedFiduciaries'
            ref='platformTransferRequest'
        />

        <Popup :show='wrongPlatformPopupShown' :close='closeWrongPlatformPopup' small>
            <template slot='title'>
                {{ $t('ttl-popup-client-wrong-platform', { platform: temporaryPlatformName }) }}
            </template>
            <p>{{ $t('p-popup-client-wrong-platform-popup-body') }}</p>
            <p class='mb-0'>
                {{ $t('p-popup-client-wrong-platform-popup-body-p2') }}
            </p>

            <template slot='buttons'>
                <FriendlyButton
                    label='lbl-popup-client-wrong-platform-popup-body-reject'
                    :action='closeWrongPlatformPopup'
                    symbol='times'
                    small
                    square
                    secondary
                />
                <FriendlyButton
                    :label='$t("lbl-popup-client-wrong-platform-popup-body-proceed", { platform: temporaryPlatformName })'
                    :action='doSwitchPlatform'
                    small
                    symbol='check'
                    square
                    class='voila-order-popup-submit-button'
                />
            </template>
        </Popup>
    </section>
</template>

<style lang='postcss' scoped>
    .header {
        margin-top: 40px;
        padding-bottom: 20px;
        border-bottom: 1px solid $color-grey;
    }
    .client-nav {
        @apply flex justify-between items-end;
    }

    .menu-dot {
        width: 15px;
        height: 15px;
        background: url('~@/assets/menu_dot.svg') center center;
        background-size: 100% 100%;
        display: inline-block;
    }
</style>
