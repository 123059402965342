<script>
    import ClientTransferList from './mixins/ClientTransferList.vue';
    import Modal            from '../components/Modal.vue';
    import Dropdown from '@/components/DropdownV2';
    import DropdownItem from '@/components/DropdownItem';
    import List from '@/components/List';
    import ListColumn from '@/components/ListColumn';
    import ListRow from '@/components/ListRow';
    import ListItem from '@/components/ListItem';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import ExactEmail       from '../components/ExactEmail.vue';
    import ContentBox       from '../components/ContentBox.vue';

    export default {
        name: 'client-transfer-regular-list',
        mixins: [ClientTransferList],
        props: {
            currentFiduciary: {
                type: Object,
            },
        },
        components: {
            'exact-email':  ExactEmail,
            'modal': Modal,
            ContentBox,
            Dropdown,
            DropdownItem,
            List,
            ListColumn,
            ListRow,
            ListItem,
            FriendlyButton,
        },
        data () {
            return {
                transferTypesFilter: ['regularTransfer', 'downgradeTransfer', 'upgradeTransfer'],
            };
        },
        computed: {
            isFiduExactOnline () {
                return this.currentFiduciary.isExactOnline;
            },
            fiduciaryId () {
                return this.currentFiduciary.id;
            },
        },
    };
</script>

<template>
    <div>
        <modal :show='isCancelPopupDisplayed'>
            <div class='modal-header alert-ok'>
                <h4>{{ $t('ttl-cancel-client-transfer-request-popup') }}</h4>
            </div>
            <div class='modal-body'>
                <p>
                    {{ $t('p-cancel-client-transfer-request-popup') }}
                </p>
            </div>
            <div class='modal-footer'>
                <button type='button' class='btn btn-default' @click.prevent='isCancelPopupDisplayed = false'>
                    {{ $t('btn-cancel-client-transfer-request-close') }}
                </button>
                <button type='button'
                        class='btn btn-primary'
                        @click.prevent='cancelConfirmation()'
                >
                    {{ $t('btn-cancel-client-transfer-request-confirms') }}
                </button>
            </div>
        </modal>

        <div class='transfer-intro-text'>
            {{ $t('p-client-regular-transfer-request-intro') }}
        </div>

        <content-box>
            <div>
                <div class='flex mb-3'>
                    <Dropdown v-model='stateCriteria'
                              :name='$t("ctr-state-filter")'
                              class='z-50'
                              nullable
                              icon='sort'
                              id='ctr-state-filter'
                              default-value='all'
                    >
                        <DropdownItem id='all' default>
                            {{ $t('ctr-display-state-all') }}
                        </DropdownItem>
                        <DropdownItem id='open'>
                            {{ $t('ctr-display-state-open') }}
                        </DropdownItem>
                        <DropdownItem id='closed'>
                            {{ $t('ctr-display-state-closed') }}
                        </DropdownItem>
                    </Dropdown>
                </div>

                <List :loading='loading'
                      id='ctr-platform-list'
                      tooltip-fix
                      class='cb-bordered'
                      :class='{"table-responsive": $store.state.gui === "mobile"}'
                >
                    <template #columns>
                        <ListColumn>{{ $t('th-client-code') }}</ListColumn>
                        <ListColumn>{{ $t('th-client-name') }}</ListColumn>
                        <ListColumn>{{ $t('th-enterprise-number') }}</ListColumn>
                        <ListColumn>{{ $t('th-client-email') }}</ListColumn>
                        <ListColumn v-if='isFiduExactOnline'>
                            {{ $t('lbl-exact-email') }}
                        </ListColumn>
                        <ListColumn>{{ $t('th-state-date') }}</ListColumn>
                        <ListColumn>{{ $t('th-transfer-state') }}</ListColumn>
                        <ListColumn>{{ $t('th-actions') }}</ListColumn>
                    </template>
                    <template #rows>
                        <ListRow
                            v-for='(ctr, i) in clientTransferRequests'
                            :key='i'
                            :landmark='displayRowLandmark(i)'
                            :background='Number.isInteger(((i+1)/2))'
                        >
                            <ListItem>
                                {{ ctr.clientCode }}
                            </ListItem>
                            <ListItem>
                                {{ ctr.enterpriseName }}
                            </ListItem>
                            <ListItem>
                                {{ ctr.enterpriseNumber }}
                            </ListItem>
                            <ListItem>
                                {{ ctr.contactEmail }}
                            </ListItem>
                            <ListItem v-if='isFiduExactOnline'>
                                <exact-email :email='ctr.exactEmail' />
                            </ListItem>
                            <ListItem>
                                {{ ctr.stateModifiedAt | dateFormat }}
                            </ListItem>
                            <ListItem>
                                {{ $t(getStateLabel(ctr.state)) }}
                            </ListItem>
                            <ListItem>
                                <div class='flex'>
                                    <FriendlyButton
                                        v-if='ctr.state === "new" || ctr.state === "waitingFiduFeedback" || ctr.state === "waitingClientConfirmation"'
                                        label='btn-cancel-client-transfer-request-init'
                                        micro
                                        no-margin
                                        square
                                        :action='() => cancelRequest(ctr)'
                                    />
                                    <router-link
                                        v-if='ctr.state === "done" && fiduciaryId === ctr.currentFiduciaryId'
                                        :to='`/fiduciary/client/${ctr.clientId}/`'
                                    >
                                        <!-- Only display "view client" link if CTR is done and client still belongs to the current fiduciary (client could be transferred to another fiduciary) -->
                                        <span class='client-link'>{{ $t('btn-view-client') }}</span>
                                    </router-link>
                                </div>
                            </ListItem>
                        </ListRow>
                    </template>
                </List>
                <div class='flex justify-center'>
                    <FriendlyButton
                        :label='`btn-loadmore`'
                        :action='loadMore'
                        id='btn-loadmore'
                        extra-small
                        square
                        :disabled='!nextPageCursor'
                    />
                </div>
            </div>
        </content-box>
    </div>
</template>
