<script>
    export default {
        name: 'purchase-terms-en',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Service Description
        </h3>

        <p>With the VOILA service you can receive your clients' purchase invoices from different channels (Zoomit, Peppol, ...) in electronic format. This way, your clients no longer have to send you these purchase invoices.</p>

        <p>Similarly to our CODA and SODA service, you can request the activation of your VOILA service for each client separately in your MyCodaBox environment. Activating the service only takes a few clicks!</p>

        <p>Go to the section 'CLIENT MANDATES & SERVICES - VOILA' to order the service for all your clients at once or one by one.</p>

        <p>IMPORTANT: Inform your clients they will be contacted by CodaBox to accept the VOILA service. This is a crucial step in order to increase the activation rate of the service.</p>

        <p>How does this service work for your client?</p>
        <ol>
            <li>
                When you order the service for your clients, they will receive an invitation via email to accept the VOILA service.
            </li>
            <li>
                Your clients are informed by CodaBox about the next steps after accepting the mandate and what this means for them.
            </li>
            <li>
                Once your client accepted the service, CodaBox will use this confirmation to register your client on the various electronic invoicing networks.
            </li>
            <li>
                Your client will no longer have to deliver invoices received via these channels to you. As soon as a supplier sends an invoice, CodaBox will deliver this invoice to your client through the chosen delivery method (via your client portal or via e-mail). CodaBox delivers a copy these electronic invoices to you via your existing secure delivery channel.
            </li>
        </ol>

        <h3>Pricing</h3>

        <h4 class='cb-service-title'>
            Administrative costs
        </h4>

        <p>No administrative costs are charged.</p>

        <h4 class='cb-service-title'>
            Recurrent Costs
        </h4>

        <p>Until 1 July 2022, the VOILA Product is offered free of charge. As of July 1, 2022, the price list below for the VOILA Product applies. Until further notice, CodaBox applies a 65% discount to the standard prices listed. If CodaBox would reduce or withdraw this discount, CodaBox will inform you at least three months in advance.</p>
        <table class='table'>
            <tbody>
                <tr>
                    <td>+ 500 enterprise numbers</td>
                    <td>3,45 €/month/enterprise number</td>
                </tr>
                <tr>
                    <td>Between 241 and 500 enterprise numbers</td>
                    <td>4,32 €/month/enterprise number</td>
                </tr>
                <tr>
                    <td>Between 121 and 240 enterprise numbers</td>
                    <td>4,78 €/month/enterprise number</td>
                </tr>
                <tr>
                    <td>Between 61 and 120 enterprise numbers</td>
                    <td>5,99 €/month/enterprise number</td>
                </tr>
                <tr>
                    <td>Between 3 and 60 enterprise numbers</td>
                    <td>7,18 €/month/enterprise number</td>
                </tr>
                <tr>
                    <td>For 1 and 2 enterprise numbers</td>
                    <td>8,94 € /month/enterprise number</td>
                </tr>
            </tbody>
        </table>

        <p>To determine the correct price, the number of CODA-services and CARO-services are also taken into account. More details can be found in the General Terms and Conditions (links below).</p>

        <h3>General Terms and Conditions</h3>

        <p>
            Terms and conditions of the service:
            <a href='/static/pdfs/MyCodaBox.com 202301 CB-Fidu FR-Annex VOILA (B3-C3).pdf' target='_blank'>FR </a>
            <a href='/static/pdfs/MyCodaBox.com 202301 CB-Fidu NL-Annex VOILA (B3-C3).pdf' target='_blank'>NL</a>
        </p>
    </div>
</template>

<style>
    .hl { color: rgb(153,204,0); }

    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }
</style>
