<script>

import FormField from '@/components/FormField.vue';
import Modal from '@/components/Modal.vue';
import PhoneField from '@/components/PhoneField.vue';
import IbanInput from '@/components/IbanInput.vue';
import Loader from '@/loader';
import { gql } from '@apollo/client/core';
import validate from '@/validate';
import notify from '@/notify';

const activationErrorsMap = {
    'ALREADY_ACTIVE': 'err-ccs-already-active',
    'INVALID': 'err-css-invalid-json',
    'INVALID_SIZE': 'err-css-invalid-json',
    'INVALID_MISSING': 'err-css-invalid-json',
    'INVALID_PATTERN': 'err-css-invalid-json',
    'INVALID_UUID': 'err-css-invalid-json',
    'INVALID_EMAIL': 'err-css-invalid-json',
    'INVALID_VALUE': 'err-css-invalid-json',
    'INVALID_LUHN_CHECK': 'err-css-invalid-json',
    'INVALID_HANDLER': 'err-server-fail',
    'INVALID_JSON': 'err-server-fail',
};

const ibanSupportedBanks = ['KREDBEBB', 'CREGBEBB'];

export default {
    name: 'order-caro',
    props: {
        clientV2: { type: Object, required: true },
        accountant: { type: Object, required: true },
        visible: { type: Boolean, required: true },
        activation: { type: Object, required: false },
        isNew: { type: Boolean, required: true },
    },
    components: { PhoneField, Modal, FormField, IbanInput },
    data () {
        var caroSupportedBanks = this.$store.state.allBanks.filter((bank) => bank.isCaroSupported);
        return {
            saving: false,
            caroSupportedBanks: caroSupportedBanks,
            isIbanFlowEnabled: caroSupportedBanks.filter((bank) => ibanSupportedBanks.includes(bank.id)).length !== 0,
            selectedBic: null,
        };
    },
    computed: {
        isIbanFlow () {
            return ibanSupportedBanks.includes(this.selectedBic);
        },
    },
    methods: {
        closeActivationModal () {
            this.$emit('orderCaroClosed');
        },
        async addActivation () {
            const valid = await this.$refs.activationForm.validate();

            if (valid) {
                Loader.start();
                this.saving = true;
                try {
                    const { data } = await this.$apollo.mutate({
                        mutation: gql`mutation OrderCaro($input: OrderCaroMandateInput!) {
                            orderCaroMandate(input: $input) {
                                errors {
                                    code,
                                    detail,
                                    source {
                                        pointer
                                    }
                                }
                            }
                        }`,
                        variables: {
                            input: {
                                clientId: this.clientV2.id,
                                clientLanguage: this.clientV2.language,
                                accountantLegalName: this.accountant.enterpriseName,
                                clientEmail: this.activation.clientEmail,
                                clientEnterpriseNumber: this.clientV2.enterpriseNumber,
                                clientReference: this.activation.clientReference,
                                clientLegalName: this.clientV2.representativeName,
                                clientMobilePhoneNumber: this.isIbanFlow ? null : this.activation.clientMobilePhoneNumber,
                                iban: this.isIbanFlow ? this.activation.iban : null,
                                bic: this.selectedBic,
                            },
                        },
                    });
                    if (data.orderCaroMandate.errors) {
                        validate.reportGQLFieldErrors(data.orderCaroMandate.errors, this.$refs.activationForm, activationErrorsMap);
                        validate.notifyGQLValidationErrors(data.orderCaroMandate.errors, activationErrorsMap);
                        Loader.stop();
                        this.saving = false;
                        return;
                    } else {
                        notify.success(this.$t('suc-css-invited'));
                    }
                } catch (e) {
                    notify.error(this.$t('err-unknown'));
                }
                Loader.stop();
                this.saving = false;
                this.closeActivationModal();
                this.$emit('caroInvitationSent');
            }
        },
    },
    emits: ['caroInvitationSent', 'orderCaroClosed'],
};
</script>

<template>
    <modal :show='visible' id='order-modal' large>
        <div class='modal-header'>
            <button type='button' class='close' @click.prevent='closeActivationModal'>
                <span>&times;</span>
            </button>
            <h4 class='modal-title' v-if='isNew'>
                {{ $t('btn-add-activation') }}
            </h4>
            <h4 class='modal-title' v-else>
                {{ $t('ttl-ccs-popup-edit-activation', { client_reference: activation.clientReference }) }}
            </h4>
        </div>
        <div class='modal-body'>
            <ValidationObserver
                ref='activationForm'
                tag='div'
            >
                <label v-if='isIbanFlowEnabled'>{{ $t('ttl-select-supported-caro-bank') }}</label>
                <p v-else>{{ $t('p-caro-supported-banks-info') }}</p>
                <FormField
                    v-if='isIbanFlowEnabled'
                    v-model='selectedBic'
                    vid='selectedBic'
                    fid='caro-banks-select'
                    type='select'
                    :placeholder='$t("ttl-select-supported-caro-bank")'
                    edit
                    required
                >
                    <option
                        v-for='bank in caroSupportedBanks'
                        :value='bank.id'
                    >
                        {{ bank.name }}
                    </option>
                </FormField>
                <FormField
                    v-model='activation.clientReference'
                    :name='$t("lbl-ccs-client-reference")'
                    :disabled='!isNew'
                    type='ccsClientReference'
                    vid='clientReference'
                    fid='client-reference'
                    placeholder='_ _ _ _ _ _ _ _ _ _'
                    edit
                    required
                    rules='not-null'
                    info='<img src="static/images/client-reference-info.png" width="280" />'
                    info-icon='fa fa-question-circle'
                />
                <p v-if='!isNew'>
                    {{ $t('p-caro-activation-edit') }}
                </p>
                <div>
                    <FormField
                        v-model='activation.clientEmail'
                        type='email'
                        :name='$t("lbl-ccs-client-email")'
                        vid='clientEmail'
                        fid='client-email'
                        :min='3'
                        :max='255'
                        :placeholder='$t("lbl-email")'
                        edit
                        required
                        rules='not-null'
                    />
                </div>
                <div v-if='isIbanFlow'>
                    <IbanInput
                        v-model='activation.iban'
                        formField='true'
                        fid='client-iban'
                        :label='$t("lbl-ccs-client-iban")'
                    />
                </div>
                <PhoneField
                    v-else
                    v-model='activation.clientMobilePhoneNumber'
                    vid='clientMobilePhoneNumber'
                    fid='client-phone-number'
                    :name='$t("lbl-ccs-client-phone")'
                    :info='$t("info-ccs-client-phone")'
                    required
                    edit
                />
            </ValidationObserver>
        </div>
        <div class='modal-footer'>
            <button type='button' class='btn btn-default' @click.prevent='closeActivationModal'>
                {{ $t('btn-cancel') }}
            </button>
            <button
                id='order-caro-modal-button'
                type='button'
                class='btn btn-primary'
                :disabled='saving'
                @click.prevent='addActivation'
            >
                <span v-if='isNew'>{{ $t('btn-add-activation') }}</span>
                <span v-else>{{ $t('btn-ccs-popup-edit-activation') }}</span>
            </button>
        </div>
    </modal>
</template>
