<script>
    import Modal from '@/components/Modal';
    import Bankaccount from '@/components/Bankaccount';
    import Codastate from '@/components/Codastate';
    import CodaMandateButtons from '@/components/CodaMandateButtons.vue';
    import SendCodaMandateByMailButton from '@/components/SendCodaMandateByMailButton';

    export default {
        name: 'impacted-coda-mandates',
        props: {
            mandates: {
                type: Array,
                default: () => [],
            },
            currentFiduciary: {
                type: Object,
            },
        },
        components: {
            Modal,
            Bankaccount,
            Codastate,
            CodaMandateButtons,
            SendCodaMandateByMailButton,
        },
        data: function () {
            return {
                show: false,
            };
        },
        methods: {
            close () {
                this.show = false;
            },
        },
    };
</script>

<template>
    <div>
        <Modal :show='show' large>
            <div class='modal-header'>
                <button type='button' class='close' @click.prevent='close'>
                    <span>&times;</span>
                </button>
                <h4 class='modal-title'>
                    {{ $t('h-impacted-coda-mandates-modal') }}
                </h4>
            </div>
            <div class='modal-body modal-body-large impacted-coda-mandate-popup'>
                <p
                    v-if='currentFiduciary.delegatesMandateDelivery'
                    v-html='$t("p-impacted-coda-mandates-modal-delegate")'
                ></p>
                <p
                    v-else
                    v-html='$t("p-impacted-coda-mandates-modal")'
                ></p>
                <div :class='{"table-responsive": $store.state.gui === "mobile"}'>
                    <table class='table'>
                        <colgroup>
                            <col class='cb-col-md'>
                            <col class='cb-col-md'>
                            <col class='cb-col-md'>
                            <col class='cb-col-md'>
                            <col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th> {{ $t('th-accounts') }} </th>
                                <th> {{ $t('th-bank') }} </th>
                                <th> {{ $t('th-state') }} </th>
                                <th> {{ $t('th-actions') }} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for='(mandate, index) in mandates' :key='index'>
                                <td>
                                    <Bankaccount v-for='account in mandate.bankAccounts' :key='account.id' :account='account' />
                                </td>
                                <td>{{ mandate.bank.name }}</td>
                                <td>
                                    <Codastate :mandate='mandate' :only-label='true' />
                                </td>
                                <td>
                                    <div class='action'>
                                        <CodaMandateButtons :mandate='mandate' @refreshCodaMandates='$emit("refreshCodaMandates")' />
                                    </div>
                                    <div class='action'>
                                        <SendCodaMandateByMailButton :mandate='mandate' v-if='mandate.state === "sent-client" && currentFiduciary.delegatesMandateDelivery' />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class='modal-footer'>
                <button type='button' class='btn btn-default' @click.prevent='close'>
                    {{ $t('btn-ok') }}
                </button>
            </div>
        </Modal>
    </div>
</template>

<style scoped>
.action {
    display: block;
    margin-bottom: 5px;
}

.modal-body-large p {
  max-width: none;
}
</style>
