
var __LOADER__ = {
    'loading': false,
    'progress': 0,
    'cssprgrss': '0%',
    'loadingId': 0,
    'progressInterval': null,
};

export default {
    start: function () {
        __LOADER__.loadingId += 1;
        __LOADER__.loading = true;
        __LOADER__.progressInterval = setInterval(function () {
            __LOADER__.progress += 0.01;
            if (__LOADER__.progress > 1.0) {
                __LOADER__.progress = 0;
            }
            __LOADER__.cssprgrss = -__LOADER__.progress * 100 + '%';
        }, 1000 / 60);

        return __LOADER__.loadingId;
    },

    stop: function (id) {
        if (!id || id === __LOADER__.loadingId) {
            clearInterval(__LOADER__.progressInterval);
            __LOADER__.loading = false;
            __LOADER__.loadingId += 1;
        }
    },

    getState: function () {
        return __LOADER__;
    },
};
