<script>
    import vt from 'vue-types';
    import ArchiveWarningModal from '../components/ArchiveWarningModal.vue';

    export default {
        name: 'soda-archive-warning-modal',
        props: {
            show:    vt.bool.isRequired,
            proceed: vt.func.isRequired,
            confirm: vt.object.isRequired,
            close:   vt.func.isRequired,
        },
        components: {
            'archive-warning-modal': ArchiveWarningModal,
        },
    };
</script>
<template>
    <archive-warning-modal :show='show'
                           :proceed='proceed'
                           :confirm='confirm'
                           :close='close'
    >
        <p> {{ $t('p-confirm-archive-soda-a') }}</p>
    </archive-warning-modal>
</template>
