
import Vue from 'vue';
import VuexI18n from 'vuex-i18n';
import store from './store';
import en  from './locales/en/translation.json';
import fr  from './locales/fr/translation.json';
import nl  from './locales/nl/translation.json';

Vue.use(VuexI18n.plugin, store);
Vue.i18n.add('en_US', en);
Vue.i18n.add('fr_FR', fr);
Vue.i18n.add('nl_BE', nl);
Vue.i18n.fallback('en_US');

function loadSavedLocale (def) {
    return localStorage['locale'] || def || 'en_US';
}

function simpleLocale (locale) {
    return locale.split('_')[0].split('-')[0];
}

function fullLocale (locale) {
    return {
        'fr': 'fr_FR',
        'nl': 'nl_BE',
        'en': 'en_US',
    }[simpleLocale(locale)] || 'en_US';
}

function detectBrowserLocale () {
    return fullLocale(simpleLocale(window.navigator.userLanguage || window.navigator.language));
}

const DETECTED_LOCALE = detectBrowserLocale();
const SAVED_LOCALE    = loadSavedLocale(DETECTED_LOCALE);

console.info('Detected Locale:', DETECTED_LOCALE, '| Saved Locale:', SAVED_LOCALE);

if (window.Cypress) {
    Vue.i18n.set('en_US');
} else {
    Vue.i18n.set(SAVED_LOCALE);
}

function setLocale (locale) {
    if (locale) {
        localStorage['locale'] = fullLocale(locale);
        Vue.i18n.set(fullLocale(locale));
    }
}

window.addEventListener('storage', function (event) {
    if (event.key === 'locale') {
        loadSavedLocale();
    }
});

Vue.i18n.setLocale = setLocale;
Vue.i18n.loadSavedLocale = loadSavedLocale;
Vue.i18n.simpleLocale = simpleLocale;
Vue.i18n.fullLocale = fullLocale;
Vue.i18n.$t = Vue.prototype.$t;
Vue.i18n.t = Vue.prototype.$t;

export default Vue.i18n;
