<script>
    import vt from 'vue-types';

    export default {
        name: 'searchbox',
        props: {
            list: vt.object.isRequired,
        },
    };
</script>

<template>
    <div class='cb-search-box' :class='{"not-empty":list.textsearch}'>
        <input class='form-control'
               type='text'
               v-model='list.textsearch'
               @submit.prevent.stop
               :placeholder='$t("lbl-search")'
        >
        <span class='cb-search-box-icon'>
            <i v-if='!list.reloading' class='fa fa-search'></i>
            <i v-else class='fa fa-spin fa-gear'></i>
        </span>
    </div>
</template>
