<template>
    <component
        :is='`h${level}`'
        :class='`
            title
            h${styleOf ? styleOf : level}
            ${variant}
            ${noDefaultMargins ? "m-0 p-0" : ""}
        `'
    >
        <slot></slot>
        <span v-if='content'>{{ $t(content) }}</span>
    </component>
</template>

<script>
export default {
    name: 'custom-title',
    props: {
        level: {
            type: [Number, String],
            default: 1,
        },
        variant: {
            type: String,
            default: '',
        },
        styleOf: {
            type: Number,
        },
        content: {
            type: String,
            default: null,
        },
        noDefaultMargins: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="postcss" scoped>
.title {
    @apply text-grey-700;
}

.h1 {
  @apply text-4xl leading-tight;
}

.h2 {
  @apply text-3xl leading-tight;
}

.h3 {
  @apply text-4xl font-bold leading-tight;
}

.h4 {
  @apply text-2xl leading-tight;
}

.h5 {
  @apply text-2xl leading-tight;
}

.h6 {
  @apply text-base uppercase;
}

.bold {
  @apply font-bold;
}

.big {
  font-size: 3em;
}

.white {
  @apply text-white;
}

@screen md {
    .h1 {
        @apply text-5xl;
    }

    .h2 {
        @apply text-4xl;
    }

    .h3 {
        @apply text-3xl;
    }

    .big {
        font-size: 5em;
    }
}
</style>
