const MINIMUM_LENGTH = 12;

/* -- Password Validation -- */

function getPasswordValidation (password) {
    var error = '';

    if (password) {
        var length = password.length;
        var lowercase = (password.match(/[a-z]/g) || []).length;
        var uppercase = (password.match(/[A-Z]/g) || []).length;
        var number = (password.match(/[0-9]/g) || []).length;
        var special = length - lowercase - uppercase - number;

        if (length < MINIMUM_LENGTH) {
            error = ('val-password-error-length');
        } else if (special === 0) {
            error = ('val-password-error-special');
        } else if (lowercase === 0 || uppercase === 0) {
            error = ('val-password-error-case');
        }
    }

    return error;
}

export default {
    getPasswordValidation,
};
