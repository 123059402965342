import store from '@/store';
import bus from '@/bus.js';
import 'whatwg-fetch';
import Api from '@/api';

export async function authFetch (url, opts) {
    if (store.state.token) {
        opts.headers = {
            ...opts.headers,
            ...{
                'Authorization': 'JWT ' + store.state.token,
            },
        };
    }

    try {
        const response = await fetch(url, opts);

        if (response.status === 401) {
            try {
                const body = await response.json();
                // No chance we can refresh the token, so fail fast
                if (body.detail !== 'Signature has expired.') {
                    bus.$emit('session-expired', null);
                    return response;
                }
            } catch {
                return response;
            }

            // Try to refresh the token + redo the call
            try {
                await Api.refreshToken();
                return fetch(url, opts);
            } catch (err) {
                bus.$emit('session-expired', null);
                throw err;
            }
        } else {
            return response;
        }
    } catch (err) {
        throw err;
    }
};
