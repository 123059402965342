<template>
    <div class='text-center flex flex-col items-center justify-center mb-auto'>
        <NavBar :user='$store.state.user' />
        <h1 class='mt-20'>
            {{ $t('ttl-connect-api-page') }}
        </h1>
        <div class='mt-12 flex flex-col '>
            <div class='border border-solid border-grey-200 p-6 inline-block rounded-md'>
                <div class='inline-block'>
                    <div class='info-row'>
                        <span>{{ $t('ttl-connect-api-software-name') }}</span> {{ consent.softwareName }}
                    </div>
                    <div class='info-row'>
                        <span>{{ $t('ttl-connect-api-accountant-name') }}</span> {{ consent.accountantName }}
                    </div>
                </div>
            </div>
            <div class='mt-6'>
                {{ $t('lbl-connect-api-disclaimer') }}
            </div>
            <div class='mt-12 inline-block'>
                <settings-toggle
                    v-model='acceptTerms'
                    id='terms-toggle'
                >
                    <span v-html='$t("lbl-connect-api-terms")'></span>
                </settings-toggle>
            </div>
            <div class='mt-6 grid grid-cols-2 gap-6'>
                <custom-button variant='grey' @click='refuse' id='refuse'>
                    {{ $t('btn-connect-api-refuse') }}
                </custom-button>
                <custom-button :disabled='!acceptTerms' @click='confirm' id='confirm'>
                    {{ $t('btn-connect-api-confirm') }}
                </custom-button>
            </div>
        </div>
        <cb-footer />
    </div>
</template>

<script>
import VueRouter from 'vue-router';
import SettingsToggle from '@/components/SettingsToggle';
import CustomButton from '@/components/Button';
import notify from '../notify.js';
import CbFooter from '@/components/FancyFooter.vue';
import NavBar from '@/components/NavBar.vue';
import { gql } from '@apollo/client/core';

const { isNavigationFailure, NavigationFailureType } = VueRouter;

export default {
    components: {
        SettingsToggle,
        CustomButton,
        CbFooter,
        NavBar,
    },
    data () {
        return {
            acceptTerms: false,
            consent: {},
        };
    },
    created () {
        this.getConsentObject();
    },
    methods: {
        async getConsentObject () {
            try {
                const { data } = await this.$apollo.query({
                    query: gql`query accountingSoftwareConsent($softwareId: String!) {
                        accountingSoftwareConsent(softwareId:$softwareId) {
                            id
                            userId
                            softwareName
                            accountantId
                            accountantName
                            confirmationUri
                            state
                            created
                            modified
                            redirectUri
                            softwareId
                            accountantCompanyNumber
                        }
                    }`,
                    variables: {
                        softwareId: this.$route.query.uuid,
                    },
                });
                if (data.accountingSoftwareConsent.state !== 'unconfirmed') {
                    notify.error(this.$t('lbl-connect-api-incorrect-state'));
                    this.redirect();
                }
                this.consent = data.accountingSoftwareConsent;
            } catch (e) {
                notify.error(this.$t('err-connect-api-not-found'));
                this.redirect();
            }
        },
        async refuse () {
            try {
                const { data } = await this.$apollo.mutate({
                    mutation: gql`mutation refuseAccountingSoftwareConsent($softwareId: String!) {
                        refuseAccountingSoftwareConsent(softwareId: $softwareId) {
                            errors { code, detail, source { pointer } }
                        }
                    }`,
                    variables: {
                        softwareId: this.$route.query.uuid,
                    },
                });
                this.redirect();
                if (!data.refuseAccountingSoftwareConsent.errors) {
                    notify.success(this.$t('lbl-connect-api-refuse-success'));
                    setTimeout(() => { window.location.href = this.consent.redirectUri; }, 5000);
                } else {
                    notify.error(this.$t('err-unknown'));
                }
            } catch (e) {
                notify.error(this.$t('err-connect-api-not-found'));
                this.redirect();
            }
        },
        async confirm () {
            try {
                const {data} = await this.$apollo.mutate({
                    mutation: gql`mutation acceptAccountingSoftwareConsent($softwareId: String!) {
                        acceptAccountingSoftwareConsent(softwareId: $softwareId) {
                            errors { code, detail, source { pointer } }
                        }
                    }`,
                    variables: {
                        softwareId: this.$route.query.uuid,
                    },
                });
                this.redirect();
                if (!data.acceptAccountingSoftwareConsent.errors) {
                    notify.success(this.$t('lbl-connect-api-confirm-success'));
                    setTimeout(() => { window.location.href = this.consent.redirectUri; }, 5000);
                } else {
                    notify.error(this.$t('err-unknown'));
                }
            } catch (e) {
                notify.error(this.$t('err-connect-api-not-found'));
                this.redirect();
            }
        },
        redirect () {
            this.$router.push('/').catch((err) => {
                // ignore router errors thrown by the redirect from /
                if (!isNavigationFailure(err, NavigationFailureType.redirect)) {
                    throw err;
                }
            });
        },
    },
};
</script>

<style lang="postcss" scoped>
.info-row {
    @apply mb-2;
}

.info-row:last-child {
    @apply mb-0;
}

.info-row span {
    @apply font-bold mr-2;
}
</style>
