<script>
    import vt from 'vue-types';

    export default {
        name: 'sorttoggle',
        props: {
            sorting: vt.object.isRequired,
            ctrls:   vt.object.isRequired,
            skey:    vt.string.isRequired,
        },
    };
</script>

<template>
    <span class='cb-sorttoggle'
          :class='{"active":sorting.key === skey}'
          @click.prevent='ctrls.toggleSort(skey)'
    >
        <span v-if='sorting.key === skey'>
            <span v-if='sorting.dir > 0'>
                <i class='fa fa-sort-desc'></i>
            </span>
            <span v-if='sorting.dir < 0'>
                <i class='fa fa-sort-asc'></i>
            </span>
        </span>
        <span v-if='sorting.key !== skey'>
            <i class='fa fa-sort'></i>
        </span>
    </span>
</template>
