<script>
export default {
    name: 'list-column',
};
</script>

<template>
    <th class='px-5 h-12 font-bold'>
        <slot></slot>
    </th>
</template>
