<script>
    import vt from 'vue-types';

    export default {
        name: 'collumn',
        props: {
            sorting: vt.object.isRequired,
            skey:    vt.string.isRequired,
        },
    };
</script>
<template>
    <col :class='{"cb-sorted": sorting.key === skey}'>
</template>
