<template>
    <div id='main' class='page-host'>
        <loader />
        <router-view />
    </div>
</template>

<script>
    import Loader from './components/Loader.vue';
    import store  from './store';
    import Api    from './api';
    import router from './router.js';
    import notify from './notify.js';
    import i18n from './i18n.js';

    export default {
        name: 'app',
        components: {
            'loader': Loader,
        },
        computed: {
            matchNewNavBarRoutes () {
                switch (this.$route.name) {
                    case 'invoices-subscribe':
                        return true;
                    case 'email-verification':
                        return true;
                    case 'email-verification-refuse':
                        return true;
                    default:
                        return false;
                }
            },
        },
        watch: {
            '$store.state.maintenance': function () {
                store.dispatch('logout');
            },
        },
        mounted () {
            this.onResize();
            window.addEventListener('resize', this.onResize);
            setInterval(this.refreshToken, 10 * 60 * 1000);
        },
        beforeDestroy () {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            onResize () {
                let w = window.innerWidth;
                if (w <= 767) {
                    store.commit('setGui', 'mobile');
                } else if (w < 1200) {
                    store.commit('setGui', 'desktop');
                } else {
                    store.commit('setGui', 'widescreen');
                }
            },
            refreshToken () {
                Api.refreshToken().catch((err) => {
                    if (err.error !== 'network') {
                        notify.info(i18n.t('err-session-expired'));
                        router.push('/logout');
                    }
                });
            },
        },
    };
</script>

<style lang='scss'>
    @import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
    @import './app.scss';
</style>
