<script>
import { VoilaMandateStates } from '@/constants/voila2';
import StatePill from '@/components/StatePill.vue';

export default {
    name: 'voila-state-pill',
    props: {
        status: String,
        inline: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        StatePill,
    },
    computed: {
        statusLabel () {
            if (!this.status) {
                return this.$t('voila-peppol-status-not-registered');
            } else {
                return this.$t({
                    [VoilaMandateStates.Invited]: 'voila-peppol-status-not-registered',
                    [VoilaMandateStates.InvitationExpired]: 'voila-peppol-status-not-registered',
                    [VoilaMandateStates.InValidation]: 'voila-peppol-status-not-registered',
                    [VoilaMandateStates.InRegistration]: 'voila-peppol-status-not-registered',
                    [VoilaMandateStates.Problem]: 'voila-peppol-status-not-registered',
                    [VoilaMandateStates.Active]: 'voila-peppol-status-registered',
                    [VoilaMandateStates.Cancelled]: 'voila-peppol-status-not-registered',
                }[this.status]);
            }
        },
        statusLegend () {
            if (!this.status) {
                return this.$t('voila-peppol-status-legend-not-registered');
            } else {
                return this.$t({
                    [VoilaMandateStates.Invited]: 'voila-peppol-status-legend-not-registered',
                    [VoilaMandateStates.InvitationExpired]: 'voila-peppol-status-legend-not-registered',
                    [VoilaMandateStates.InValidation]: 'voila-peppol-status-legend-not-registered',
                    [VoilaMandateStates.InRegistration]: 'voila-peppol-status-legend-not-registered',
                    [VoilaMandateStates.Problem]: 'voila-peppol-status-legend-not-registered',
                    [VoilaMandateStates.Active]: 'voila-peppol-status-legend-registered',
                    [VoilaMandateStates.Cancelled]: 'voila-peppol-status-legend-not-registered',
                }[this.status]);
            }
        },
        statusBackgroundClass () {
            if (!this.status) {
                return 'bg-grey-300';
            } else {
                return {
                    [VoilaMandateStates.Invited]: 'bg-grey-300',
                    [VoilaMandateStates.InvitationExpired]: 'bg-grey-300',
                    [VoilaMandateStates.InValidation]: 'bg-grey-300',
                    [VoilaMandateStates.InRegistration]: 'bg-grey-300',
                    [VoilaMandateStates.Problem]: 'bg-grey-300',
                    [VoilaMandateStates.Active]: 'bg-green-300',
                    [VoilaMandateStates.Cancelled]: 'bg-grey-300',
                }[this.status];
            }
        },
    },
};
</script>

<template>
    <div class='voila-peppol-sate-pill'>
        <StatePill :label='statusLabel' :legend='statusLegend' :background-class='statusBackgroundClass' :inline='inline' />
    </div>
</template>
