<script>
    import { gql } from '@apollo/client/core';
    import Footer from '../components/FancyFooter.vue';
    import PrismicPopup from '../components/PrismicPopup.vue';
    import notify from '@/notify.js';
    import NavBar from '@/components/NavBar.vue';

    export default {
        name: 'reseller-root',
        components: {
            'cb-footer': Footer,
            PrismicPopup: PrismicPopup,
            NavBar,
        },
        data () {
            return {
                loaded: false,
                reseller: null,
            };
        },
        computed: {
            resellerId () {
                return this.$store.state.user.resellerIds[0];
            },
            routeName () {
                return {
                    'rslr-fidu-search': 'fidu',
                    'rslr-coda-search': 'coda',
                    'rslr-soda-search': 'soda',
                    'rslr-purchase-search': 'purchase',
                    'rslr-coda-search-fidu': 'coda',
                    'rslr-soda-search-fidu': 'soda',
                    'rslr-purchase-search-fidu': 'purchase',
                    'rslr-help':         'help',
                    'rslr-help-article': 'help',
                }[this.$route.name];
            },
        },
        async created () {
            await this.loadData(this.resellerId);
        },
        methods: {
            async loadData (resellerId) {
                try {
                    const {data} = await this.$apollo.query({
                        query: gql`query resellerData($resellerId: String!) {
                            reseller(resellerId:$resellerId) {
                                id
                                name
                                state
                                language
                                fiduciaries {
                                    id
                                    name
                                }
                            }
                        }`,
                        variables: { resellerId },
                    });
                    this.reseller = data.reseller;
                    this.loaded = true;
                } catch {
                    notify.error(this.$t('err-invalid-credentials'));
                    this.$router.push('/logout');
                }
            },
        },
    };
</script>

<template>
    <div>
        <NavBar
            :user='$store.state.user'
            :help-url='$t("help-url")'
            :gdpr-url='$t("nav-gdpr-url")'
        >
            <template #platform v-if='loaded'>
                <span class='text-white text-lg font-medium'> {{ reseller.name }} </span>
            </template>
        </NavBar>
        <PrismicPopup space='reseller' />

        <div class='cb-sidebar'>
            <ul class='nav-sidebar'>
                <li class='tq pl-6 mt-12 mb-3 font-bold inline-block'>
                    {{ $t('nav-fiduciaries') }}
                </li>
                <li :class='{"active": routeName == "fidu"}'>
                    <router-link to='/reseller/fiduciaries' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        {{ $t('nav-fiduciary-list') }}
                    </router-link>
                </li>

                <li class='tq pl-6 mt-6 mb-3 font-bold'>
                    {{ $t('nav-documents') }}
                </li>
                <li :class='{"active": routeName == "coda"}'>
                    <router-link to='/reseller/coda' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        {{ $t('nav-coda') }}
                    </router-link>
                </li>
                <li :class='{"active": routeName == "soda"}'>
                    <router-link to='/reseller/soda' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        {{ $t('nav-soda') }}
                    </router-link>
                </li>
                <div class='cb-sidebar-footer'>
                    <a target='_blank' href='http://www.codabox.com' class='inline-block pl-6 py-3 w-full active:no-underline'>
                        <img class='cb-sidebar-logo' src='@/assets/codabox-logo-full.svg'>
                    </a>
                </div>
            </ul>
        </div>

        <div class='cb-main min-h-screen' v-if='loaded'>
            <div class='flex flex-col justify-between min-h-screen'>
                <router-view :current-reseller='reseller' />
                <cb-footer />
            </div>
        </div>
    </div>
</template>
