<script>
    import vt from 'vue-types';
    import { datadogRum } from '@datadog/browser-rum';

    export default {
        props: {
            label: vt.string.isRequired,
            action: vt.func,
            symbol: vt.string,
            secondary: Boolean,
            warning: Boolean,
            danger: Boolean,
            disabled: Boolean,
            small: Boolean,
            extraSmall: Boolean,
            micro: Boolean,
            outline: Boolean,
            datadogAction: String,
            square: {
                type: Boolean,
                default: false,
            },
            noMargin: {
                type: Boolean,
                default: false,
            },
            noText: {
                type: Boolean,
                default: false,
            },
            propagateClick: {
                type: Boolean,
                default: false,
            },
        },
        data () {
            return {
                busy: false,
            };
        },
        computed: {
            symbolClass () {
                if (this.symbol) {
                    let klass = {
                        fa: true,
                    };
                    klass['fa-' + this.symbol] = true;
                    return klass;
                } else {
                    return {};
                }
            },
        },
        methods: {
            doClick (e) {
                if (!this.propagateClick) {
                    e.stopPropagation();
                }
                if (this.action && !this.busy && !this.disabled) {
                    if (this.datadogAction) {
                        datadogRum.addUserAction(this.datadogAction);
                    }
                    this.busy = true;
                    Promise.resolve(this.action())
                        .then(() => {
                            this.busy = false;
                        });
                }
            },
        },
    };
</script>

<template>
    <div class='inline-block'>
        <span class='cb-friendly-button'
              :class='{
                  secondary:secondary,
                  warning,
                  danger,
                  enabled:!(disabled||busy),
                  disabled:disabled,
                  busy:busy,
                  small,
                  "extra-small": extraSmall,
                  "no-margin": noMargin,
                  "no-text": noText,
                  square,
                  micro
              }'
              @click='doClick'
        >
            <span v-if='!noText'>
                {{ $t(label) }}
            </span>
            <i v-if='symbol' :class='symbolClass' class='mr-2 ml-0'></i>
        </span>
    </div>
</template>

<style lang='postcss' scoped>
    .cb-friendly-button {
        position: relative;
        top: 0px;
        display: inline-block;
        height: 60px;
        line-height: 1em;
        font-size: 18px;
        padding: 0px 30px;
        border-radius: 30px;
        border: solid 2px transparent;
        background: #0d6ccc;
        cursor: pointer;
        color: white;
        box-shadow: 0px 0px 0px #62709a6b;
        user-select: none;
        transition: all linear 100ms;
        margin: 10px 10px;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
    }
    .cb-friendly-button.small {
        height: 50px;
        padding: 0px 20px;
    }
    .cb-friendly-button.extra-small {
        @apply text-base;
        height: 34px;
        font-size: 14px;
        padding: 0px 10px;
    }
    .cb-friendly-button.micro {
        @apply text-base;
        height: 28px;
        font-size: 14px;
        padding: 0px 8px;
    }
    .cb-friendly-button.no-margin {
        margin: 0;
    }
    .cb-friendly-button.square {
        @apply rounded-md;
    }
    .cb-friendly-button.secondary,
    .cb-friendly-button.enabled.secondary:hover:active,
    .cb-friendly-button.enabled.secondary:hover
    {
        top: 0px;
        background: transparent;
        color: #0d6ccc;
        border-color: #0d6ccc;
        box-shadow: none;
    }
    .cb-friendly-button.warning,
    .cb-friendly-button.enabled.warning:hover:active,
    .cb-friendly-button.enabled.warning:hover
    {
        top: 0px;
        background: #FF8900;
        box-shadow: none;
    }
    .cb-friendly-button.danger,
    .cb-friendly-button.enabled.danger:hover:active,
    .cb-friendly-button.enabled.danger:hover
    {
        top: 0px;
        background: #e74c3c;
        box-shadow: none;
    }
    .cb-friendly-button i {
        position: relative;
        left: 7px;
        margin-left: 5px;
        font-size: 16px;
    }
    .cb-friendly-button.enabled:hover {
        top: -2px;
        box-shadow: 0px 2px 6px #62709a6b;
        background: #4691f5;
    }
    .cb-friendly-button.enabled:hover:active {
        top: 0px;
        box-shadow: 0px 0px 0px #62709a6b;
        background: #438ef0;
    }
    .cb-friendly-button.enabled.warning:hover {
        top: -2px;
        box-shadow: 0px 2px 6px #62709a6b;
        background: #ff9215;
    }
    .cb-friendly-button.enabled.warning:hover:active {
        top: 0px;
        box-shadow: 0px 0px 0px #7a5f5b;
        background: #ff961f;
    }
    .cb-friendly-button.enabled.danger:hover {
        top: -2px;
        box-shadow: 0px 2px 6px #62709a6b;
        background: #e74c3c;
    }
    .cb-friendly-button.enabled.danger:hover:active {
        top: 0px;
        box-shadow: 0px 0px 0px #7a5f5b;
        background: #e74c3c;
    }
    .cb-friendly-button.disabled {
        cursor: default;
        background: #d1e2f3;
    }
    .cb-friendly-button.secondary.disabled {
        cursor: default;
        color: #d6def7;
        background: transparent;
        border-color: #c9d4f3;
    }
    .cb-friendly-button.busy {
        cursor: default;
        background: linear-gradient(90deg, #4691f5, #1977d4 , #4691f5);
        background-position-x: 0px;
        background-position-y: -3px;
        background-size: 100px 100px;
        animation: friendly-bars 800ms linear infinite;
    }
    .cb-friendly-button.secondary.busy {
        background: linear-gradient(90deg, #81a1ff54, #5e85ff11 , #81a1ff54);
        background-position-x: 0px;
        background-position-y: -3px;
        background-size: 100px 100px;
        animation: friendly-bars 800ms linear infinite;
    }
    .cb-friendly-button.warning.busy {
        background: linear-gradient(90deg, #f37062, #e74c3c , #f37062);
        background-position-x: 0px;
        background-position-y: -3px;
        background-size: 100px 100px;
        animation: friendly-bars 800ms linear infinite;
    }
    .cb-friendly-button.danger.busy {
        background: linear-gradient(90deg, #f37062, #e74c3c , #f37062);
        background-position-x: 0px;
        background-position-y: -3px;
        background-size: 100px 100px;
        animation: friendly-bars 800ms linear infinite;
    }
    .cb-friendly-button.no-text {
        padding-left: 0;
    }
    .cb-friendly-button.no-text i {
        margin-left: 0;
    }
    @keyframes friendly-bars {
      0% {
        background-position-x: 0px;
      }
      100% {
        background-position-x: -100px;
      }
    }

</style>
