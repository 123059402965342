<script>
    import Vue from 'vue';
    import { gql } from '@apollo/client/core';
    import VueMasonry from 'vue-masonry-css';
    import Modal from '../components/Modal.vue';
    import ClientAddCoda from '../components/ClientAddCoda';
    import UniqueBankAccountNotice from '../components/UniqueBankAccountNotice';
    import CodaCard from '../components/CodaCard';
    import utils from '@/utils';
    import ResendCodaButton from '@/fiduciary/components/ResendCodaButton';
    import Tooltip from '@/components/Tooltip.vue';
    import Loader from '@/loader';
    import notify from '@/notify';
    import validate from '@/validate';
    import ContentBox from '@/components/ContentBox';
    import SendSodaCodaByMail from './components/SendSodaCodaByMail';

    Vue.use(VueMasonry);

    export default {
        name: 'client-coda',
        props: {
            clientV2: { type: Object, required: true },
            currentFiduciary: { type: Object, required: true },
        },
        components: {
            Modal,
            ClientAddCoda,
            UniqueBankAccountNotice,
            CodaCard,
            ResendCodaButton,
            Tooltip,
            ContentBox,
            SendSodaCodaByMail,
        },
        data () {
            return {
                saving: false,
                codaMandates: null,
                newBankAccounts: [],
                ibanUnavailableError: false,
                codaLoaded: false,
                newMandateModal: false,
            };
        },
        apollo: {
            currentVoilaMandate: {
                query: gql`query VoilaMandate($clientId: String) {
                    currentVoilaMandate(clientId:$clientId) {
                         ibans {
                            value
                         }
                    }
                }`,
                variables () {
                    return { clientId: this.clientV2.id };
                },
            },
        },
        computed: {
            selectedCodaId () {
                return this.$route.params.selectedCodaId || null;
            },
            bankAccounts () {
                if (!this.codaMandates) {
                    return [];
                }
                let bankAccounts = [];
                for (let mandate of this.codaMandates) {
                    let bankName = mandate.bank.name;
                    bankAccounts = bankAccounts.concat(
                        mandate.bankAccounts.map(
                            ba => ({bankName, ...ba}),
                        ),
                    );
                }
                return bankAccounts;
            },
            invalidRepresentativeName () {
                return !validate.isComposedName(this.clientV2.representativeName);
            },
            missingContactEmail () {
                return !this.clientV2.contactEmail;
            },
        },
        async beforeMount () {
            await this.getCodaMandates();
            await this.getCodaRedeliveryRequests(this.clientV2.id, this.codaMandates.map(mandate => { return mandate.id; }));
        },
        methods: {
            async getCodaMandates () {
                Loader.start();
                const { data } = await this.$apollo.query({
                    query: gql`query getCodaMandate($clientId: String) {
                            codaMandates(clientId:$clientId, excludeMandatesWithoutBankAccounts:true) {
                                id
                                clientId
                                fiduciaryId
                                bankId
                                bank {
                                    id
                                    name
                                    slug
                                    abbreviation
                                    isSupported
                                    isTwikeySupported
                                    disableSendPdfMandate
                                    isCaroSupported
                                    isStopCodaSupported
                                    regexSavings
                                    isSignhereSupported
                                }
                                state
                                reasonCode
                                stateDate
                                bankAccounts{
                                    id
                                    type
                                    bankId
                                    state
                                    stateDate
                                    flowState
                                    bankMandateState
                                    iban
                                }
                                signHerePackage {
                                    packageStatus
                                    packageSignUrl
                                    packageRejectionReason
                                }
                                twikeyUrl
                                hasTeletransmissionPdf
                                reminders {
                                    date
                                    targetEmail
                                    type
                                }
                            }
                        }`,
                    variables: {
                        clientId: this.clientV2.id,
                    },
                });
                data.codaMandates.forEach(mandate => {
                    mandate.hasRedeliveryRequest = false;
                });
                this.codaMandates = data.codaMandates;
                this.codaLoaded = true;
                Loader.stop();
            },

            async orderCodaMandates () {
                this.orderCodaMandatesVoila();
            },

            async orderCodaMandatesVoila () {
                if (this.saving || !this.newBankAccounts.length) { return; }

                const codaValid = await this.$refs.coda.validateBankAccounts();
                if (!codaValid) { return; }

                Loader.start();
                this.saving = true;

                let bankAccountsData = [];
                for (const bankAccount of this.newBankAccounts) {
                    bankAccountsData.push({
                        'id': bankAccount.iban,
                    });
                }

                const res = await this.$apollo.mutate({
                    mutation: gql`mutation OrderCodaMandate($input: CodaOrderingInput!) {
                        orderCodaMandate(input: $input) {
                            errors {
                                code,
                                detail,
                                source {
                                    pointer
                                }
                            }
                        }
                    }`,
                    variables: {
                        input: {
                            fiduciaryId: this.currentFiduciary.id,
                            clientId: this.clientV2.id,
                            bankAccounts: bankAccountsData,
                        },
                    },
                });
                const response = res.data.orderCodaMandate;

                Loader.stop();
                this.saving = false;

                if (response.errors) {
                    try {
                        await this.catchOrderCodaMandateErrorsVoila2(response.errors);
                    } catch (err) {
                        await validate.notifyErrors(err);
                    }

                    return;
                }

                notify.success(this.$t('p-order-coda-success'));

                await this.getCodaMandates();

                this.closeOrderCodaMandateModal();
            },
            catchOrderCodaMandateErrorsVoila2 (errors) {
                for (let i = 0; i < errors.length; i++) {
                    let error = errors[i];
                    if (error && error.source && error.source.pointer.match(/^\/data\/bankAccounts\/\d+\/id$/) && this.$refs.coda) {
                        let inputIndex = error.source.pointer.substring(19, 20);
                        this.$refs.coda.$refs[`account_${inputIndex}`][0].$refs.input.setErrors([this.$t(`err-${error.code}`)]);

                        if (error.code === 'ibanUnavailable') {
                            this.ibanUnavailableError = true;
                        }

                        delete errors[i];
                    }
                }

                if (utils.count(errors)) {
                    return Promise.reject(errors);
                }

                return Promise.resolve();
            },
            openOrderCodaMandateModal () {
                this.newMandateModal = true;
            },
            closeOrderCodaMandateModal () {
                this.ibanUnavailableError = false;
                this.newMandateModal = false;
            },
            async getCodaRedeliveryRequests (clientId, codaMandates) {
                const { data } = await this.$apollo.query({
                    query: gql`query codaRedeliverySearch($clientId: String, $mandates: [String]!, $state: [String]) {
                        codaRedeliverySearch(clientId: $clientId, codaMandates: $mandates, states: $state) {
                            mandate
                            bankAccount
                            state
                        }
                    }`,
                    variables: {
                        clientId: clientId,
                        mandates: codaMandates,
                        state: ['pending'],
                    },
                });
                data.codaRedeliverySearch.forEach(crr => {
                    const mandate = this.codaMandates.find(mandate => {
                        return mandate.id === crr.mandate;
                    });
                    mandate.hasRedeliveryRequest = true;
                });
            },
        },
    };
</script>
<template>
    <div class='client-subppage'>
        <div class='panel panel-superwarning' id='coda-missing-contact-email-warning' v-if='missingContactEmail'>
            <div class='panel-heading'>
                <h3 class='panel-title'>
                    <i class='fa fa-info-circle'></i>
                </h3>
            </div>
            <div class='panel-body'>
                <p>
                    {{ $t('info-coda-missing-contact-email') }}
                </p>
            </div>
        </div>
        <div class='panel panel-superwarning' id='coda-invalid-representative-name-warning' v-if='invalidRepresentativeName'>
            <div class='panel-heading'>
                <h3 class='panel-title'>
                    <i class='fa fa-info-circle'></i>
                </h3>
            </div>
            <div class='panel-body'>
                <p>
                    {{ $t('info-coda-invalid-representative-name') }}
                </p>
            </div>
        </div>
        <content-box class='mb-6' :title='$t("ttl-mandates")'>
            <template #actions v-if='codaMandates'>
                <div class='flex'>
                    <resend-coda-button
                        class='mr-3'
                        :client-v2='clientV2'
                        :bank-accounts='bankAccounts'
                        :open-at-load='$route.params.openResendCodaModal'
                    />

                    <button
                        class='btn btn-primary'
                        :disabled='missingContactEmail || invalidRepresentativeName'
                        @click='openOrderCodaMandateModal'
                        id='orderCodaMandateButton'
                    >
                        {{ $t('btn-new-coda-mandate') }}
                    </button>
                </div>
            </template>
            <masonry
                :cols='{default: 2, 1200: 2, 1000: 1}'
                :gutter='15'
                v-if='codaMandates'
            >
                <coda-card
                    v-for='(mandate, index) in codaMandates'
                    :mandate='mandate'
                    :key='index'
                    :selected='(selectedCodaId === mandate.id) && codaMandates.length > 1'
                    :animated='typeof selectedCodaId === "string"'
                    @getCodaMandatesEvent='getCodaMandates'
                    :id='"codaCard_"+mandate.id'
                />
            </masonry>
            <tooltip big v-if='codaMandates && codaMandates.length === 0'>
                <template #trigger>
                    <span
                        class='py-1 px-3 rounded-full bg-green-300 inline-block bg-grey-300 bg-opacity-20'
                    >
                        {{ $t('state-no-coda') }}
                    </span>
                </template>
                <template #content>
                    {{ $t('p-no-coda') }}
                </template>
            </tooltip>
        </content-box>

        <SendSodaCodaByMail
            v-if='codaMandates && codaMandates.length > 0'
            :client-v2='clientV2'
            @clientupdated='$emit("clientupdated")'
        />

        <modal :show='newMandateModal' large id='orderCodaMandateModal'>
            <div class='modal-header'>
                <button type='button' class='close' @click.prevent='closeOrderCodaMandateModal'>
                    <span>&times;</span>
                </button>
                <h4 class='modal-title'>
                    {{ $t('ttl-add-coda') }}
                </h4>
            </div>
            <ValidationObserver
                tag='div'
                class='modal-body'
            >
                <ClientAddCoda
                    v-model='newBankAccounts'
                    :client-v2='clientV2'
                    ref='coda'
                />

                <UniqueBankAccountNotice v-if='ibanUnavailableError' />
            </ValidationObserver>
            <div class='modal-footer'>
                <button type='button' class='btn btn-default' @click.prevent='closeOrderCodaMandateModal'>
                    {{ $t('btn-cancel') }}
                </button>
                <button type='button'
                        class='btn btn-primary'
                        :disabled='saving || !newBankAccounts.length'
                        @click.prevent='orderCodaMandates'
                        id='confirmOrderCodaMandateButton'
                >
                    {{ $t('btn-save-mandate') }}
                </button>
            </div>
        </modal>
    </div>
</template>

<style lang='postcss' scoped>
    .client-subppage {
        min-height: calc(100vh - 450px);
    }

    .client-subppage-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0 20px;

        h1, h2, h3, h4, h5, h6 {
            margin: 0;
        }
    }

    .client-subppage-header .btn+.btn {
        @apply ml-3;
    }

    .client-page__settings {
        margin-top: 20px;
    }

    .client-subppage-subtitle {
        margin: 40px 0 20px 0;
    }

    .client-subppage-header__actions {
        @apply flex items-center ml-auto;
    }
</style>
