<script>
    import Api from '../api';
    import utils from '../utils.js';
    import notify from '../notify.js';
    import Loader from '../loader.js';
    import WizardTransitions from '../clientcomponents/WizardTransitions.vue';
    import WizardScreen from '../clientcomponents/WizardScreen.vue';
    import WizardBox from '../clientcomponents/WizardBox.vue';
    import FriendlyButton from '../clientcomponents/FriendlyButton.vue';
    import FriendlyInput from '../clientcomponents/FriendlyInput.vue';
    import FriendlyInputFuzzy from '../clientcomponents/FriendlyInputFuzzy.vue';
    import ButtonBar from '../clientcomponents/ButtonBar.vue';
    import Popup from '../clientcomponents/Popup.vue';
    import BreadCrumbs from '../clientcomponents/BreadCrumbs.vue';
    import NavBar from '@/components/NavBar.vue';

    export default {
        name: 'purchase-wizard',
        components: {
            WizardTransitions,
            WizardScreen,
            WizardBox,
            FriendlyButton,
            FriendlyInputFuzzy,
            FriendlyInput,
            ButtonBar,
            Popup,
            BreadCrumbs,
            NavBar,
        },
        data () {
            return {
                step: 'verification',
                showRefuseConfirmation: false,
                showRetryMessage: false,
                response: [''],
                history: ['verification'],
                nameResponses: [],
                errorMessage: null,
                showBreadcrumps: false,
                loaded: false,
                state: '',
                remainingAttempts: 0,
                legalEntity: {},
                challengeAnswerRequired: null,
                email: '',
            };
        },
        computed: {
            mobile () {
                return this.$store.state.gui !== 'widescreen';
            },
            locale () {
                return this.$i18n.locale();
            },
            wizardSteps () {
                return [
                    {
                        keys: ['verification'],
                        label: 'lbl-step-verification',
                    }, {
                        keys: ['done'],
                        label: 'lbl-step-done',
                        freeze: ['verification'],
                    },
                ];
            },
            canConfirm () {
                return this.$refs.name.$refs.field.errors.length <= 0 && this.$refs.name.value.length > 0;
            },
        },
        watch: {
            step: function (step) {
                if (step === 'done') {
                    utils.remove(this.history, 'verification');
                }
            },
        },
        created: function () {
            this.getEmailVerification();
        },
        methods: {
            getEmailVerification () {
                Loader.start();
                Api.getEmailVerification(this.$route.params.token)
                    .then((data) => {
                        this.nameResponses = data.challenge_fiduciary_name;
                        this.email = data.email;
                        this.legalEntity = data.client_legal_entity;
                        this.challengeAnswerRequired = data.challenge_answer_required;
                        this.state = data.state;
                        switch (this.state) {
                            case 'verification-sent':
                                this.showBreadcrumps = true;
                                break;
                            case 'reminder-sent':
                                this.showBreadcrumps = true;
                                break;
                            case 'verified-email':
                                if (this.$route.meta.entry === 'refuse') {
                                    this.state = 'already-validated';
                                }
                                this.nextStep('done');
                                break;
                            case 'verified-pi-mandate':
                                if (this.$route.meta.entry === 'refuse') {
                                    this.state = 'already-validated';
                                }
                                this.nextStep('done');
                                break;
                            case 'failed-refused':
                                if (this.$route.meta.entry === 'accept') {
                                    this.state = 'already-refused';
                                }
                                this.nextStep('done');
                                break;
                            default:
                                this.nextStep('done');
                        }
                    })
                    .catch(error => {
                        if (error.status === 404) {
                            this.state = 'failed-expired';
                        }
                        this.nextStep('done');
                        this.showBreadcrumps = false;
                    })
                    .finally(() => {
                        this.loaded = true;
                        Loader.stop();
                    });
            },
            submitEmailVerification () {
                Loader.start();

                Api.submitEmailVerification(this.$route.params.token, 'verified-email', this.response[0])
                    .then((data) => {
                        this.state = data.state;
                        this.remainingAttempts = data.remaining_attempts;
                    })
                    .catch(error => {
                        if (error.status === 400) {
                            if (error.body.non_field_errors && error.body.non_field_errors[0] === 'The email is already refused') {
                                this.state = 'already-refused';
                            } else {
                                this.remainingAttempts = error.body.remaining_attempts;
                                this.showRetryMessagePopup();
                                this.response = [''];
                            }
                        } else {
                            notify.error(this.$t('err-unknown'));
                        }
                    })
                    .finally(() => {
                        Loader.stop();
                        if (this.remainingAttempts === 0 || this.state === 'verified-email') {
                            this.showRetryMessage = false;
                            this.nextStep('done');
                        }
                    });
            },
            refuseVerification () {
                Loader.start();
                Api.submitEmailVerification(this.$route.params.token, 'failed-refused')
                    .then((data) => {
                        this.state = data.state;
                    })
                    .catch(error => {
                        if (error.status === 400 && error.body.non_field_errors[0] === 'The email is already verified') {
                            this.state = 'already-validated';
                        }
                    })
                    .finally(() => {
                        Loader.stop();
                        this.nextStep('done');
                        this.closeRefuseConfirmationPopup();
                    });
            },
            closeRefuseConfirmationPopup () {
                this.showRefuseConfirmation = false;
            },
            showRefuseConfirmationPopup () {
                this.showRefuseConfirmation = true;
            },
            showRetryMessagePopup () {
                this.showRetryMessage = true;
            },
            nextStep (step) {
                this.step = step;
                setTimeout(() => {
                    utils.scrollTop();
                }, 150);
            },
            validateName (value) {
                if (value.length > 0 && this.nameResponses.find((elem) => {
                    return elem === value;
                })) {
                    return { state: 'valid' };
                } else {
                    return { state: 'incomplete' };
                }
            },
        },
    };
</script>

<template>
    <div>
        <NavBar no-nav no-login />
        <Popup :show='showRefuseConfirmation' :close='closeRefuseConfirmationPopup'>
            <template slot='title'>
                {{ $t('h-are-you-sure') }}
            </template>
            <template slot='alert'>
            </template>
            <template slot='buttons'>
                <FriendlyButton label='btn-cancel' :action='closeRefuseConfirmationPopup' symbol='times' secondary />
                <FriendlyButton label='btn-refuse-mandate' :action='refuseVerification' symbol='check' />
            </template>
        </Popup>

        <WizardTransitions>
            <BreadCrumbs :steps='wizardSteps'
                         :history='history'
                         v-model='step'
                         v-if='showBreadcrumps'
                         key='breadcrumbs'
            />
        </WizardTransitions>

        <WizardTransitions v-show='loaded'>
            <WizardScreen key='verification'
                          v-if='step === "verification" && challengeAnswerRequired && $route.meta.entry === "accept"'
            >
                <WizardBox animated fullwidth left-align v-if='$route.meta.entry === "accept"'>
                    <h1>{{ $t('lbl-email-question-title') }}</h1>
                    <p>
                        {{ $t('lbl-email-question-body').replace("legal_entity.name", legalEntity.name) }}
                    </p>
                </WizardBox>
                <WizardBox animated centered>
                    <template>
                        <ButtonBar thinner>
                            <FriendlyInputFuzzy
                                :value='response[0]'
                                @set='value => { $set(response, 0, value) }'
                                :list='nameResponses'
                            >
                                <FriendlyInput
                                    fullwidth
                                    autocomplete='off'
                                    slot='input'
                                    ref='name'
                                    label='lbl-accountant-name'
                                    v-model='response[0]'
                                    :validator='validateName'
                                    field='accountantName'
                                />
                            </FriendlyInputFuzzy>

                            <p v-if='showRetryMessage'
                               class='remaining-attempts'
                               v-html='$t("lbl-remaining_attempts-email-verification", {remaining_attempts: remainingAttempts})'
                            >
                            </p>
                        </ButtonBar>
                        <ButtonBar thinner>
                            <FriendlyButton label='btn-confirm'
                                            :action='submitEmailVerification'
                                            symbol='arrow-right'
                                            :disabled='$refs.name ? !canConfirm : true'
                            />
                        </ButtonBar>
                    </template>
                </WizardBox>
            </WizardScreen>

            <WizardScreen key='verification'
                          v-if='step === "verification" && !challengeAnswerRequired && $route.meta.entry === "accept"'
            >
                <WizardBox animated centered>
                    <template>
                        <h1>
                            {{ $t('lbl-no-challenge-question-title').replace("legal_entity.name", legalEntity.name) }}
                        </h1>
                        <p>
                            <span v-html='$t("lbl-no-challenge-question-body").replace("client.email", email)'></span>
                            <strong>
                                {{ legalEntity.name }}
                                <span v-if='legalEntity.enterprise_num'>
                                    ({{ legalEntity.enterprise_num }})
                                </span>
                            </strong>
                        </p>
                        <ButtonBar>
                            <FriendlyButton label='btn-confirm'
                                            :action='submitEmailVerification'
                                            symbol='arrow-right'
                            />
                        </ButtonBar>
                    </template>
                </WizardBox>
            </WizardScreen>

            <WizardScreen key='verification'
                          v-if='step === "verification" && $route.meta.entry === "refuse"'
            >
                <WizardBox animated centered>
                    <template>
                        <h1>{{ $t('lbl-email-refuse-confirm-title') }}</h1>
                        <p>
                            {{ $t('lbl-email-refuse-confirm-body') }}
                        </p>
                        <ButtonBar>
                            <FriendlyButton label='btn-refuse'
                                            :action='showRefuseConfirmationPopup'
                                            symbol='arrow-right'
                            />
                        </ButtonBar>
                    </template>
                </WizardBox>
            </WizardScreen>

            <WizardScreen key='done'
                          v-if='step === "done"'
            >
                <WizardBox animated-long centered v-if='state === "failed-expired"'>
                    <h1>{{ $t('lbl-email-expired-title') }}</h1>
                    <p v-html='$t("lbl-email-expired-body")'></p>
                </WizardBox>
                <WizardBox animated-long centered v-else-if='state === "verified-email" || state === "verified-pi-mandate"'>
                    <h1>{{ $t('lbl-email-verification-success-title') }}</h1>
                    <p v-if='challengeAnswerRequired'>
                        {{ $t('lbl-email-verification-success-body') }}
                    </p>
                    <p v-else>
                        {{ $t('lbl-email-verification-no-challenge-success-body') }}
                    </p>
                </WizardBox>
                <WizardBox animated-long centered v-else-if='state === "failed-refused"'>
                    <h1>{{ $t('lbl-email-refuse-success-title') }}</h1>
                    <p>
                        {{ $t('lbl-email-refuse-success-body') }}
                    </p>
                </WizardBox>
                <WizardBox animated-long centered v-else-if='state === "already-validated"'>
                    <h1>{{ $t('lbl-email-already-validated-title') }}</h1>
                    <p>
                        {{ $t('lbl-email-already-validated-body') }}
                    </p>
                </WizardBox>
                <WizardBox animated-long centered v-else-if='state === "already-refused"'>
                    <h1>{{ $t('lbl-email-already-refused-title') }}</h1>
                    <p>
                        {{ $t('lbl-email-already-refused-body') }}
                    </p>
                </WizardBox>
                <WizardBox animated-long centered v-else-if='state === "failed-challenge"'>
                    <h1>{{ $t('lbl-email-verification-fail-title') }}</h1>
                    <p v-html='$t("lbl-email-verification-fail-body")'></p>
                </WizardBox>
                <WizardBox animated-long centered v-else>
                    <h1>{{ $t('lbl-email-verification-fail-title') }}</h1>
                </WizardBox>
            </WizardScreen>
        </WizardTransitions>
    </div>
</template>

<style lang="scss" scoped>
    .remaining-attempts {
        color: $error-color;
        margin-top: 20px;
    }
</style>
