<script>
export default {
    name: 'search-input',
    props: {
        value: String,
        label: {
            type: String,
            default: 'placeholder-search-input',
        },
    },
    methods: {
        input (e) {
            this.$emit('input', e.target.value);
        },
    },
};
</script>

<template>
    <div class='search-input'>
        <i class='fa fa-search icon'></i>
        <input type='text' :placeholder='$t(label)' @input='input'>
    </div>
</template>

<style lang="postcss" scoped>
.search-input {
    @apply text-grey-700;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}

.search-input .icon {
    @apply left-5;
    position: absolute;
}

.search-input input {
    @apply border border-solid border-grey-250 rounded px-5 pl-12 text-grey-700 h-12;
    width: 100%;
}

.search-input input:focus {
    @apply border border-solid border-blue-300;
    outline: none;
}
</style>
