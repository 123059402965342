<script>
    import { gql } from '@apollo/client/core';
    import Modal from '../components/Modal.vue';
    import ArchiveButton from '../components/ArchiveButton';
    import ArchiveModal from '../components/SodaArchiveWarningModal';
    import ClientAddSoda from '../components/ClientAddSoda';
    import SodaState from '../components/Sodastate';
    import Loader from '@/loader';
    import notify from '@/notify';
    import validate from '@/validate';
    import utils from '@/utils';
    import Tooltip from '@/components/Tooltip.vue';
    import ContentBox from '@/components/ContentBox.vue';
    import SendSodaCodaByMail from './components/SendSodaCodaByMail';

    const defaultDetSodaMandatesParams = {
        loading: true,
    };

    export default {
        name: 'client-soda',
        props: {
            clientV2: { type: Object, required: true },
            currentFiduciary: { type: Object, required: true },
        },
        components: {
            SodaState,
            ArchiveButton,
            ArchiveModal,
            Modal,
            ClientAddSoda,
            Tooltip,
            ContentBox,
            SendSodaCodaByMail,
        },
        data () {
            return {
                sodaMandates: [],
                newSocialOffices: [],
                archival: {
                    showWarning: false,
                    confirm: { confirmed: false },
                    mandateId: null,
                },
                newMandateModal: false,
                saving: false,
            };
        },
        beforeMount () {
            this.getSodaMandates();
        },
        mounted () {
            this.document = 'soda';
        },
        methods: {
            async orderSodaMandates () {
                if (this.saving) { return; }

                const valid = await this.$refs.soda.validateWelfareOffices();
                if (valid) {
                    Loader.start();
                    this.saving = true;

                    let socialOfficesData = [];
                    for (const socialOffice of this.newSocialOffices) {
                        socialOfficesData.push({
                            'id': socialOffice.social_welfare_id,
                        });
                    }
                    try {
                        const { data } = await this.$apollo.mutate({
                            mutation: gql`mutation OrderSodaMandate($input: SodaOrderingInput!) {
                                orderSodaMandate(input: $input) {
                                    errors { code, detail, source { pointer } }
                                }
                            }`,
                            variables: {
                                input: {
                                    fiduciaryId: this.currentFiduciary.id,
                                    clientId: this.clientV2.id,
                                    socialOffices: socialOfficesData,
                                },
                            },
                        });
                        Loader.stop();
                        this.saving = false;
                        if (!data.orderSodaMandate.errors) {
                            notify.success(this.$t('p-order-soda-success'));
                            this.getSodaMandates();
                            this.closeNewMandateModal();
                            return;
                        } else {
                            let errors = data.orderSodaMandate.errors;
                            for (let i = 0; i < errors.length; i++) {
                                let error = errors[i];
                                if (error && error.source && error.source.pointer.match(/^\/data\/socialOffices\/\d+\/id$/) && this.$refs.soda) {
                                    let inputIndex = error.source.pointer.substring(20, 21);
                                    this.$refs.soda.$refs.input[inputIndex].setErrors([this.$t(error.code)]);
                                    delete data.orderSodaMandate.errors[i];
                                }
                            }
                            if (utils.count(data.orderSodaMandate.errors)) {
                                validate.notifyErrors(data.orderSodaMandate.errors);
                            }
                        }
                    } catch (err) {
                        Loader.stop();
                        this.saving = false;
                        notify.error(this.$t('err-unknown'));
                        return;
                    }
                }
            },
            async getSodaMandates (params = defaultDetSodaMandatesParams) {
                Loader.start();
                this.loading = true;
                if (params.loading) {
                    this.loading = true;
                }
                const { data } = await this.$apollo.query({
                    query: gql`query GetSodaMandates($clientId: String) {
                        sodaMandates(clientId:$clientId) {
                            id
                            clientId
                            socialOfficeId
                            socialOfficeName
                            state
                            stateDate
                            validityStartDate
                        }
                    }`,
                    variables: {
                        clientId: this.clientV2.id,
                    },
                });
                this.sodaMandates = data.sodaMandates || [];

                Loader.stop();
                this.loading = false;
            },
            async archiveSodaMandate () {
                if (!this.archival.confirm.confirmed) {
                    return;
                }

                this.archival.showWarning = false;

                try {
                    const { data } = await this.$apollo.mutate({
                        mutation: gql`mutation ArchiveSodaMandate($mandateId: String) {
                            archiveSodaMandate(sodaMandateId: $mandateId) {
                                errors { code, detail, source { pointer } }
                            }
                        }`,
                        variables: { mandateId: this.archival.mandateId },
                    });
                    if (data.archiveSodaMandate.errors) {
                        validate.notifyErrors(data.archiveSodaMandate);
                    } else {
                        notify.success(this.$t('p-archived-soda-success'));
                        this.getSodaMandates();
                    }
                    return;
                } catch (err) {
                    notify.error(this.$t('err-unknown'));
                    return;
                }
            },
            doArchiveWithWarning (id) {
                this.archival.mandateId = id;
                this.archival.confirm.confirmed = false;
                this.archival.showWarning = true;
            },
            cancelArchive () {
                this.archival.showWarning = false;
                this.archival.mandateId = null;
            },
            openNewMandateModal () {
                this.newMandateModal = true;
            },
            closeNewMandateModal () {
                this.newMandateModal = false;
            },
        },
    };
</script>
<template>
    <div class='client-subppage'>
        <content-box :title='$t("ttl-mandates")'>
            <template #actions>
                <button class='btn btn-primary'
                        @click='openNewMandateModal'
                        id='orderSodaMandateButton'
                >
                    {{ $t('btn-new-soda-mandate') }}
                </button>
            </template>
            <div v-if='sodaMandates.length <= 0'>
                <tooltip big>
                    <template #trigger>
                        <span
                            class='py-1 px-3 rounded-full inline-block bg-grey-300 bg-opacity-20'
                        >
                            {{ $t('state-no-soda') }}
                        </span>
                    </template>
                    <template #content>
                        {{ $t('p-no-soda') }}
                    </template>
                </tooltip>
            </div>
            <ArchiveModal :show='archival.showWarning'
                          :proceed='archiveSodaMandate'
                          :confirm='archival.confirm'
                          :close='cancelArchive'
                          id='archiveSodaMandateModal'
            />
            <div :class='{"table-responsive": $store.state.gui === "mobile"}' v-if='sodaMandates.length > 0'>
                <table class='table' id='sodaList'>
                    <colgroup>
                        <col class='cb-col-md'>
                        <col class='cb-col-md'>
                        <col class='cb-col-md'>
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{{ $t('th-social-office') }}</th>
                            <th>{{ $t('th-state-date') }}</th>
                            <th>{{ $t('th-state') }}</th>
                            <th>{{ $t('th-actions') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='sodaMandate in sodaMandates'>
                            <td>{{ sodaMandate.socialOfficeName }}</td>
                            <td>{{ sodaMandate.stateDate | dateFormat }}</td>
                            <td><SodaState :mandate='sodaMandate' /></td>
                            <td><ArchiveButton :mandate='sodaMandate' :archive='doArchiveWithWarning' /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </content-box>

        <SendSodaCodaByMail
            v-if='sodaMandates.length > 0'
            :client-v2='clientV2'
            @clientupdated='$emit("clientupdated")'
        />

        <modal :show='newMandateModal' large id='orderSodaMandateModal'>
            <div class='modal-header'>
                <button type='button' class='close' @click.prevent='closeNewMandateModal'>
                    <span>&times;</span>
                </button>
                <h4 class='modal-title'>
                    {{ $t('ttl-new-soda-mandate') }}
                </h4>
            </div>
            <ValidationObserver
                tag='div'
                class='modal-body'
            >
                <ClientAddSoda
                    v-model='newSocialOffices'
                    :needsone='true'
                    ref='soda'
                />
            </ValidationObserver>
            <div class='modal-footer'>
                <button type='button' class='btn btn-default' @click.prevent='closeNewMandateModal'>
                    {{ $t('btn-cancel') }}
                </button>
                <button type='button'
                        class='btn btn-primary'
                        :disabled='saving || !newSocialOffices.length'
                        @click.prevent='orderSodaMandates'
                        id='confirmOrderSodaMandateButton'
                >
                    {{ $t('btn-save-mandate') }}
                </button>
            </div>
        </modal>
    </div>
</template>

<style lang='postcss' scoped>
    .client-subppage {
        min-height: calc(100vh - 450px);
    }

    .client-subppage-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0 20px;

        h1, h2, h3, h4, h5, h6 {
            margin: 0;
        }
    }

    .client-subppage-header .btn+.btn {
        @apply ml-3;
    }

    .client-page__settings {
        margin-top: 20px;
    }

    .client-subppage-subtitle {
        margin: 40px 0 20px 0;
    }

    .client-subppage-header__actions {
        @apply flex items-center ml-auto;
    }
</style>
