import 'whatwg-fetch';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient, HttpLink, InMemoryCache, from, Observable } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import bus from '@/bus.js';

let apolloProvider;

export default {
    init: function () {
        const httpLink = new HttpLink({
            uri: '/bff/graphql',
        });

        const authLink = setContext((request, previousContext) => {
            const token = localStorage.getItem('authToken');

            return {
                headers: {
                    ...request.headers,
                    authorization: token ? `JWT ${token}` : '',
                },
            };
        });

        const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
            if (networkError && networkError.response.status === 401) {
                return new Observable(async (observer) => {
                    let res = await fetch('core/internal/token-refresh', {
                        method: 'POST',
                        headers: {
                            'Accept-Language': 'en-US',
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        },
                        body: JSON.stringify({
                            data: {
                                token: localStorage.getItem('authToken'),
                            },
                        }),
                    });
                    if (res.status !== 200) {
                        bus.$emit('session-expired', null);
                        return null;
                    }
                    const resBody = await res.json();

                    localStorage.setItem('authToken', resBody.data.token);

                    const subscriber = {
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                    };
                    forward(operation).subscribe(subscriber);
                });
            }
        });

        const cache = new InMemoryCache();

        const apolloClient = new ApolloClient({
            link: from([errorLink, authLink.concat(httpLink)]),
            cache,
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                },
                query: {
                    fetchPolicy: 'no-cache',
                },
            },
        });

        apolloProvider = new VueApollo({
            defaultClient: apolloClient,
        });

        Vue.use(VueApollo);
    },
    getProvider: function () {
        return apolloProvider;
    },
};
