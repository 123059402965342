<script>
    export default {
        name: 'archive-button',
        props: {
            'mandate': Object,
            'archive': Function,
            'label': {
                type: String,
                default: 'btn-archive',
            },
        },
        methods: {
            doArchive () {
                if (this.mandate.state !== 'archived') {
                    this.archive(this.mandate.id);
                }
            },
        },
    };
</script>
<template>
    <div class='btn btn-alt btn-xs'
         :class='{"disabled": mandate.state === "archived"}'
         @click.prevent='doArchive'
    >
        {{ $t(label) }}
    </div>
</template>
